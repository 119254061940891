import 'styles/App.scss';

import { getInboxVersion } from 'app/inboxV2/utils';
import { LogLevel } from 'app/state/log/Logger';
import { AuthenticatedUser } from 'app/state/user/actions';
import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { AuthenticationErrorModal } from 'shared-components/error-state/AuthenticationErrorModal';

import { defaultFeatureFlags, FeatureFlags } from '../hooks/useFeatureFlags';
import { Snackbar } from '../shared-components/Snackbar';
import { AppTheme } from './theme/AppTheme';
import IdlePageTimerLogger from './events/IdlePageTimerLogger';
import AppRoutes from './routes/AppRoutes';
import { history, services, ServicesProvider } from './services';
import { isLocal, stage } from './Stage';
import { store } from './state';
import { syncAuthenticatedUserToRedux } from './state/syncAuthenticatedUserToRedux';

export default () => {
  const [featureFlags, setFeatureFlags] = useState(defaultFeatureFlags);
  const onSyncComplete = (
    featureFlags: FeatureFlags,
    user: AuthenticatedUser,
  ) => {
    if (
      featureFlags.transientFeatureFlags.enable_carehub_debug_logger ||
      isLocal()
    ) {
      services.logger.setLogLevel(LogLevel.DEBUG);
    } else {
      services.logger.setLogLevel(LogLevel.INFO);
    }

    const inboxVersion = getInboxVersion({
      flagEnabled:
        featureFlags.transientFeatureFlags.carehub_enable_coach_inbox_v2,
      userRole: user.role,
    });

    services.logger.setUser({
      inboxVersion,
      listenerId: user.listenerId ?? undefined,
      role: user.role,
      userId: user.userId,
    });

    setFeatureFlags(featureFlags);
  };

  useEffect(() => {
    syncAuthenticatedUserToRedux(store, services, onSyncComplete);

    const networkStateListener = (e: Event) =>
      services.logger.info(`Network connection changed: ${e.type}`);

    window.addEventListener('online', networkStateListener);
    window.addEventListener('offline', networkStateListener);
    return () => {
      window.addEventListener('online', networkStateListener);
      window.addEventListener('offline', networkStateListener);
    };
  }, []);

  return (
    <Router history={history}>
      <ServicesProvider
        stage={stage}
        reduxStore={store}
        services={services}
        featureFlags={featureFlags}
      >
        <AppTheme>
          <IdlePageTimerLogger />
          <AppRoutes />
          <Snackbar />
          <AuthenticationErrorModal />
        </AppTheme>
      </ServicesProvider>
    </Router>
  );
};
