import Paper from '@mui/material/Paper';
import { CustomUserClaims, UserClaims } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { useAppState } from 'app/state';
import Messages from 'i18n/en/core.json';
import React, { useEffect, useState } from 'react';
import { COLUMN_WIDTH, Columns, Grid } from 'shared-components/grid';
import { HeadspaceLogo } from 'shared-components/HeadspaceLogo';

import styles from './styles/NotLoggedInScreen.module.scss';

export function NotAuthorizedScreen() {
  const { oktaAuth, authState } = useOktaAuth();
  const error = authState?.error;
  const role = useAppState((_) => _.user.role);
  const [oktaGroups, setOktaGroups] = useState<string[]>([]);

  useEffect(() => {
    oktaAuth.getUser().then((user: UserClaims<CustomUserClaims>) => {
      setOktaGroups(user.groups as string[]);
    });
  }, [authState, oktaAuth]);

  if (!authState) {
    return null;
  }

  return (
    <div data-testid="notAuthorizedScreen">
      <Grid justify="center" align="center" className={styles.container}>
        <Columns widths={[COLUMN_WIDTH.HALF]}>
          <Paper className={styles.loginBox} elevation={3} square={true}>
            <HeadspaceLogo />
            <h1>Sorry</h1>
            <h3>{Messages.unauthorisedToViewThePage}</h3>
            <p>
              Your role is:
              {role && role.toLowerCase()}
            </p>
            <p>{Messages.unauthorisedOktaGroupsTitle}</p>
            <OktaGroupDebugList oktaGroups={oktaGroups} />
            <p>{error && error.toString()}</p>
          </Paper>
        </Columns>
      </Grid>
    </div>
  );
}

function OktaGroupDebugList({ oktaGroups }: { oktaGroups: string[] }) {
  const formattedGroups = oktaGroups.map((_) => <li key={_}>{_}</li>);
  return <ul>{formattedGroups}</ul>;
}
