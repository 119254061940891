import {
  field,
  FieldDefinitions,
  stringField,
  useForm,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import {
  ChiefComplaint,
  ChiefComplaint_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/ChiefComplaint';
import {
  ChiefComplaintSection,
  PsychiatrySectionName,
} from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';

import { TextAreaField } from 'app/notes-ui/forms/fields/textAreaField';
import { Labels } from 'app/notes-ui/psychiatry/shared/constants';
import { NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD } from 'app/notes-ui/utils';
import React from 'react';
import { Width } from 'types/StyleTypes';

type Props = {
  appointmentId: string;
  onSubmit: (data: ChiefComplaintSection) => void;
  disabled?: boolean;
  initialValue?: ChiefComplaint;
};

export const fieldDefs: FieldDefinitions<ChiefComplaint> = {
  appointmentId: field(),
  chiefComplaint: stringField(),
  historyOfPresentIllness: stringField(),
  relevantPhysicalHealthUpdates: stringField(),
  version: field<ChiefComplaint_Version>({
    default: ChiefComplaint_Version.v0,
  }),
};

export function ChiefComplaintFormV2(props: Props) {
  const { disabled, initialValue, appointmentId } = props;
  const { fields, getValue } = useForm<ChiefComplaint>(
    fieldDefs,
    {
      ...initialValue,
      appointmentId,
      version: ChiefComplaint_Version.v0,
    } as ChiefComplaint,
    {
      delay: NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD,
      onStateChange: async () => {
        await onSubmit();
      },
    },
  );

  const onSubmit = async () => {
    if (disabled) return;

    props.onSubmit({
      data: getValue(),
      name: PsychiatrySectionName.CHIEF_COMPLAINT,
    });
  };

  return (
    <div>
      <TextAreaField
        testId={Labels.chiefComplaint.chiefComplaint.id}
        disabled={disabled}
        label={Labels.chiefComplaint.chiefComplaint.label}
        placeholder={Labels.chiefComplaint.chiefComplaint.placeholder}
        field={fields.chiefComplaint}
        width={Width.FULL}
        rows={1}
        autoExpand={true}
      />
      <TextAreaField
        testId={Labels.chiefComplaint.historyOfPresentIllness.id}
        disabled={disabled}
        label={Labels.chiefComplaint.historyOfPresentIllness.label}
        placeholder={Labels.chiefComplaint.historyOfPresentIllness.placeholder}
        field={fields.historyOfPresentIllness}
        width={Width.FULL}
        rows={1}
        autoExpand={true}
      />
      <TextAreaField
        testId={Labels.chiefComplaint.relevantPhysicalHealthUpdates.id}
        disabled={disabled}
        label={Labels.chiefComplaint.relevantPhysicalHealthUpdates.label}
        subtext={Labels.chiefComplaint.relevantPhysicalHealthUpdates.subtext}
        placeholder={
          Labels.chiefComplaint.relevantPhysicalHealthUpdates.placeholder
        }
        field={fields.relevantPhysicalHealthUpdates}
        width={Width.FULL}
        rows={1}
        autoExpand={true}
      />
    </div>
  );
}

export const validate = (data: ChiefComplaint | null) =>
  data !== null && validateForm(data, fieldDefs);
