export const CONVERSATIONS_BASE_URL = 'chat/v1/conversations';

export const TASKS_BASE_URL = 'care-provider-task/v1/task-summaries';

export const DEFAULT_INBOX_SECTION_ITEM_LIMIT = 20;

export const DEFAULT_COMPLETED_TASKS_SINCE_HOURS = 12;

export enum TaskApiParam {
  RISK_FILTER = 'risk_filter',
  STATUS = 'status',
  INCLUDES = 'includes',
  LIMIT = 'limit',
  CURSOR = 'cursor',
  COMPLETED_SINCE = 'completed_since',
}

export enum TaskType {
  RISK_TASKS = 'risk_tasks',
  STANDARD_TASKS = 'standard_tasks',
  COMPLETED_TASKS = 'completed_tasks',
}

export enum TaskApiSideLoadOption {
  MEMBERS = 'members',
}
