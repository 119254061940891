import { Field } from '@ginger.io/react-use-form/dist/types';
import { DateMessage as Date } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/Date';
import React from 'react';
import { DatePicker } from 'shared-components/form-inputs/DatePicker';

export type DateFieldValue = {
  day: Field<Date['day']>;
  month: Field<Date['month']>;
  year: Field<Date['year']>;
};

type Props = {
  className?: string;
  disabled?: boolean;
  field: DateFieldValue;
  label?: string;
  name: string;
  placeholder?: string;
  testId: string;
};

export function DateField(props: Readonly<Props>) {
  const { field, testId, ...rest } = props;
  return (
    <DatePicker
      {...rest}
      data-testid={testId}
      value={getDateValue(field)}
      error={field.day.error ?? field.month.error ?? field.year.error}
      onChange={(value) => {
        const [year, month, day] = (value ?? '').split('-');
        field.day.setValue(parseInt(day, 10));
        field.month.setValue(parseInt(month, 10));
        field.year.setValue(parseInt(year, 10));
      }}
    />
  );
}

function getDateValue(date: DateFieldValue) {
  const hasEmptyComponent = Object.values(date).some(
    ({ value }) => value === undefined || value === 0,
  );

  if (hasEmptyComponent) {
    return null;
  }

  const { day, month, year } = date;
  const padWithZero = (value: number) => `${value}`.padStart(2, '0');
  return `${padWithZero(year.value)}-${padWithZero(month.value)}-${padWithZero(
    day.value,
  )}`;
}
