import {
  booleanField,
  field,
  FieldDefinitions,
  nonEmptyArrayField,
  numberField,
  stringField,
  useForm,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import { AnticipatedSession_Frequency } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/AnticipatedSession';
import { Interventions } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Interventions';
import { BooleanOption } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanOption';
import { TreatmentGoal } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/TreatmentGoal';
import {
  TreatmentPlan,
  TreatmentPlan_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/progress/TreatmentPlan';
import { ApproachType } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/ApproachType';
import {
  TherapyProgressSectionName,
  TreatmentPlanSection,
} from '@ginger.io/vault-clinical-notes/dist/TherapyProgressSection';
import { TherapistRole } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';

import { EnumMultiSelectDropdownFieldV2 } from 'app/notes-ui/forms/fields/enumMultiSelectDropdownFieldV2';

import { NumberField } from 'app/notes-ui/forms/fields/numberField';
import { TextAreaField } from 'app/notes-ui/forms/fields/textAreaField';
import { YesOrNoField } from 'app/notes-ui/forms/fields/yesOrNoField';
import { BooleanRadioField } from 'app/notes-ui/forms/form-controls/BooleanRadioField';
import { EnumDropdown } from 'app/notes-ui/forms/form-controls/EnumDropdown';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import { NoteFormControlLabel } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { NumberInput } from 'app/notes-ui/forms/form-controls/NumberInputV2';
import {
  GoalContainer,
  validate as validateGoals,
} from 'app/notes-ui/shared/treatment-plan/GoalContainer';
import { TreatmentGoalFieldDefs } from 'app/notes-ui/shared/treatment-plan/schema';
import { isEmpty, optionalField } from 'app/notes-ui/shared/ValidationRules';
import Labels from 'app/notes-ui/strings/en.json';
import { SectionDivider } from 'app/notes-ui/therapy/shared/SectionDivider';
import {
  getDefaultEnumValue,
  NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD,
  showComplianceFields,
} from 'app/notes-ui/utils';
import { usePreviousApproaches } from 'app/vault/hooks/usePreviousNote';
import React, { useState } from 'react';
import { COLUMN_WIDTH, Columns, Grid } from 'shared-components/grid';
import { Width } from 'types/StyleTypes';

import { Labels as TreatmentLabels } from './constants';

export const fieldDefs: FieldDefinitions<TreatmentPlan> = {
  additionalSessionsRequired: field({ rules: [optionalField] }),
  anticipatedSession: {
    frequency: field({
      rules: [
        (value) =>
          isEmpty(value) || value === AnticipatedSession_Frequency.UNRECOGNIZED
            ? 'This field is required'
            : undefined,
      ],
    }),
    numberOfSessionsAnticipated: numberField(),
  },
  appointmentId: stringField(),
  appointmentOffered: field<BooleanOption>({
    default: BooleanOption.not_applicable,
    rules: [],
  }),
  approaches: nonEmptyArrayField(),
  approachesUpdateComments: stringField({ rules: [optionalField] }),
  changesToTreatmentPlan: stringField({ rules: [optionalField] }),
  goal: field<TreatmentGoal[]>({
    default: [TreatmentGoal.fromPartial({})],
    rules: [
      (value) => {
        const isValid =
          value !== undefined &&
          value.every((_) => validateForm(_, TreatmentGoalFieldDefs));
        if (!isValid) {
          return 'This field contains invalid inputs';
        }
        return undefined;
      },
    ],
  }),
  interventionsUsed: nonEmptyArrayField(),
  memberOutOfScope: booleanField({ default: false, rules: [optionalField] }),
  memberOutOfScopeReason: stringField({
    rules: [
      (value: string, treatmentPlan: TreatmentPlan) => {
        if (treatmentPlan?.memberOutOfScope && !value) {
          return 'This field is required';
        }
        return undefined;
      },
    ],
  }),
  messageToCareTeam: stringField(),
  offeredAppointmentAccepted: field<BooleanOption>({
    default: BooleanOption.not_applicable,
    rules: [],
  }),
  otherApproachComment: stringField({ rules: [requiredIfOtherApproach] }),
  referralsToCareOutsideGingerNecessary: stringField({ rules: [] }),
  version: field<TreatmentPlan_Version>({ default: TreatmentPlan_Version.v0 }),
  waitTimeDetrimentalEffect: booleanField(),
};

export function requiredIfOtherApproach(
  value: string,
  state: TreatmentPlan,
): string | undefined {
  if (
    state &&
    state.approaches.includes(ApproachType.other) &&
    isEmpty(value)
  ) {
    return 'This field is required';
  }
  return undefined;
}

export const validate = (data: TreatmentPlan | null) =>
  validateForm(data, fieldDefs);

export type Props = {
  appointmentId: string;
  onSubmit: (checklist: TreatmentPlanSection) => void;
  initialValue?: TreatmentPlan; // used for read-only
  disabled?: boolean;
  noteLastUpdatedAt?: string;
};

export function TreatmentPlanFormV2(props: Props) {
  const { initialValue, disabled, appointmentId, noteLastUpdatedAt } = props;
  const [showNoGoalError, setShowNoGoalError] = useState(false);
  const { fields, getValue } = useForm<TreatmentPlan>(
    fieldDefs,
    {
      ...initialValue,
      appointmentId,
      version: TreatmentPlan_Version.v0,
    } as TreatmentPlan,
    {
      delay: NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD,
      onStateChange: async () => {
        await onSubmit();
      },
    },
  );
  const previousApproaches = usePreviousApproaches(appointmentId);

  const onSubmit = async () => {
    if (disabled) return;

    const data = getValue();
    const goalsValid = validateGoals(fields.goal.value);
    if (!goalsValid) {
      setShowNoGoalError(true);
    } else {
      setShowNoGoalError(false);
    }

    props.onSubmit({
      data,
      name: TherapyProgressSectionName.TREATMENT_PLAN,
    });
  };
  return (
    <>
      <GoalContainer
        label={TreatmentLabels.goals.label}
        disabled={disabled}
        goals={fields.goal}
        clinicianType={TherapistRole.THERA}
      />

      <TextAreaField
        className={styles.textArea}
        disabled={disabled}
        testId={TreatmentLabels.messageToCareTeam.id}
        label={TreatmentLabels.messageToCareTeam.label}
        field={fields.messageToCareTeam}
        subtext={TreatmentLabels.messageToCareTeam.description}
        placeholder={TreatmentLabels.messageToCareTeam.placeholder}
        width={Width.FULL}
        rows={1}
        autoExpand={true}
      />

      <SectionDivider label="Plan" isNew={false} />

      <NoteFormControlLabel
        label={TreatmentLabels.interventionsUsed.label}
        labelDescription="Limit 6"
      >
        <EnumMultiSelectDropdownFieldV2
          disabled={disabled}
          testId={TreatmentLabels.interventionsUsed.id}
          placeholder={TreatmentLabels.interventionsUsed.placeholder}
          label={TreatmentLabels.interventionsUsed.label}
          options={Interventions}
          field={fields.interventionsUsed}
          width={Width.FULL}
        />
      </NoteFormControlLabel>

      <NoteFormControlLabel label={TreatmentLabels.approaches.label}>
        <EnumMultiSelectDropdownFieldV2
          disabled={disabled}
          testId={TreatmentLabels.approaches.id}
          placeholder={TreatmentLabels.approaches.placeholder}
          options={ApproachType}
          label={TreatmentLabels.approaches.label}
          field={fields.approaches}
          width={Width.FULL}
        />
        {
          // is signed and locked and has value
          // or is editable and previous approaches are not equal to current approaches
          fields.approachesUpdateComments.value ||
          (previousApproaches.length === fields.approaches.value?.length &&
            previousApproaches.every(
              (approach, index) => approach === fields.approaches.value[index],
            )) ? null : (
            <TextAreaField
              className={styles.textArea}
              disabled={disabled}
              testId={TreatmentLabels.approachesUpdateComments.id}
              field={fields.approachesUpdateComments}
              placeholder={TreatmentLabels.approachesUpdateComments.placeholder}
              width={Width.FULL}
              rows={1}
              autoExpand={true}
            />
          )
        }
        {fields.approaches.value.includes(ApproachType.other) && (
          <TextAreaField
            className={styles.textArea}
            disabled={disabled}
            testId={TreatmentLabels.otherApproachComment.id}
            field={fields.otherApproachComment}
            placeholder={TreatmentLabels.otherApproachComment.placeholder}
            width={Width.FULL}
            rows={1}
            autoExpand={true}
          />
        )}
      </NoteFormControlLabel>

      {disabled && fields.additionalSessionsRequired.value ? (
        <NumberField
          disabled={disabled}
          testId={TreatmentLabels.additionalSessionsRequired.id}
          label={TreatmentLabels.additionalSessionsRequired.label}
          tooltip={TreatmentLabels.additionalSessionsRequired.tooltip}
          field={fields.additionalSessionsRequired}
        />
      ) : (
        <div data-testid="sessionInput" className={styles.root}>
          <Grid spacing={0}>
            <Columns
              widths={[COLUMN_WIDTH.HALF, COLUMN_WIDTH.HALF]}
              className={styles.col}
            >
              <NoteFormControlLabel
                label={TreatmentLabels.additionalSessionsRequired.label2}
                tooltip={TreatmentLabels.additionalSessionsRequired.tooltip}
                labelDescription={
                  TreatmentLabels.additionalSessionsRequired.description
                }
              >
                <NumberInput
                  disabled={disabled}
                  value={
                    fields.anticipatedSession.numberOfSessionsAnticipated.value
                  }
                  label={TreatmentLabels.numberOfSessionsAnticipated.label}
                  placeholder={
                    TreatmentLabels.numberOfSessionsAnticipated.placeholder
                  }
                  name={TreatmentLabels.numberOfSessionsAnticipated.id}
                  onChange={(value) => {
                    fields.anticipatedSession.numberOfSessionsAnticipated.setValue(
                      value,
                    );
                  }}
                  error={
                    fields.anticipatedSession.numberOfSessionsAnticipated.error
                  }
                />
              </NoteFormControlLabel>
              <NoteFormControlLabel
                label={TreatmentLabels.frequency.label}
                labelDescription={TreatmentLabels.frequency.description}
              >
                <EnumDropdown
                  disabled={disabled}
                  data-testid={TreatmentLabels.frequency.id}
                  enum={AnticipatedSession_Frequency}
                  initialValue={fields.anticipatedSession.frequency.value}
                  onSelect={(value) => {
                    fields.anticipatedSession.frequency.setValue(value);
                  }}
                  placeholder="Select"
                  width={Width.FULL}
                  error={fields.anticipatedSession.frequency.error}
                />
              </NoteFormControlLabel>
            </Columns>
          </Grid>
        </div>
      )}

      {showComplianceFields(noteLastUpdatedAt) && (
        <div>
          <div className={styles.formGroupV2}>
            <YesOrNoField
              disabled={disabled}
              testId="waitTimeEffectProgress"
              label={`${Labels.WAIT_TIME_EFFECT}*`}
              field={fields.waitTimeDetrimentalEffect}
              fullWidth={true}
            />
          </div>
          <div className={styles.formGroupV2}>
            <BooleanRadioField
              disabled={disabled}
              name="appointmentOffered"
              label={Labels.OFFER_AN_APPOINTMENT}
              value={getDefaultEnumValue(fields.appointmentOffered)}
              onChange={fields.appointmentOffered.setValue}
            />
          </div>
          <div className={styles.formGroupV2}>
            <BooleanRadioField
              disabled={disabled}
              name="offeredAppointmentAccepted"
              label={Labels.APPOINTMENT_OFFER_ACCEPTED}
              value={getDefaultEnumValue(fields.offeredAppointmentAccepted)}
              onChange={fields.offeredAppointmentAccepted.setValue}
            />
          </div>
          <div className={styles.formGroupV2}>
            <YesOrNoField
              disabled={disabled}
              testId={TreatmentLabels.memberOutOfScope.id}
              label={TreatmentLabels.memberOutOfScope.label}
              field={fields.memberOutOfScope}
              fullWidth={true}
            />

            {fields.memberOutOfScope.value === true && (
              <TextAreaField
                disabled={disabled}
                testId={TreatmentLabels.memberOutOfScopeReason.id}
                optional={false}
                field={fields.memberOutOfScopeReason}
                width={Width.FULL}
                placeholder={TreatmentLabels.memberOutOfScopeReason.placeholder}
                rows={1}
                autoExpand={true}
              />
            )}
          </div>
          <TextAreaField
            disabled={disabled}
            testId={TreatmentLabels.referralsToCareOutsideGingerNecessary.id}
            label={Labels.REFERRALS_TO_CARE_OUTSIDE}
            tooltip={Labels.REFERRALS_TO_CARE_OUTSIDE_TOOLTIP}
            optional={true}
            field={fields.referralsToCareOutsideGingerNecessary}
            width={Width.FULL}
            placeholder={
              TreatmentLabels.referralsToCareOutsideGingerNecessary.placeholder
            }
            rows={1}
            autoExpand={true}
          />
        </div>
      )}

      <Collapse
        className={styles.formGroupV2}
        addEndListener={() => null}
        in={showNoGoalError}
      >
        <Alert severity="error" onClose={() => setShowNoGoalError(false)}>
          You haven&apos;t filled out a goal.
        </Alert>
      </Collapse>
    </>
  );
}
