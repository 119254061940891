import { FetchArgs } from '@reduxjs/toolkit/query';
import {
  TaskApiSideLoadOption,
  TASKS_BASE_URL,
  TaskType,
} from 'app/inboxV2/constants';
import {
  GetCompletedTasksParams,
  GetTasksParams,
  TransformedGetTasksResponse,
} from 'app/inboxV2/types';
import { ServerType } from 'app/services/server/types';
import { webApi } from 'app/services/server/webApi';

import { transformTaskResponseFn } from '../transformers/transformTaskResponse';
import { buildTasksUrlParameters } from '../utils';

export const getCompletedTasksQueryFn = () => {
  return ({
    cursor,
    limit,
    status,
    includes,
    completed_since,
  }: GetCompletedTasksParams): FetchArgs => {
    return {
      params: buildTasksUrlParameters(
        {
          completed_since,
          cursor,
          includes: includes ?? TaskApiSideLoadOption.MEMBERS,
          limit,
          status,
        },
        TaskType.COMPLETED_TASKS,
      ),
      responseHandler: 'json',
      url: `${TASKS_BASE_URL}`,
    };
  };
};

export const completedTasksApi = webApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompletedTasks: builder.query<
      TransformedGetTasksResponse,
      GetTasksParams
    >({
      extraOptions: { server: ServerType.Web },
      query: getCompletedTasksQueryFn(),
      transformResponse: transformTaskResponseFn(),
    }),
  }),
  overrideExisting: false,
});

export const { useGetCompletedTasksQuery } = completedTasksApi;
