import { Safety } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyProgress';
import { TextAreaField } from 'app/notes-ui/forms/fields/textAreaField';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import { NoteFormControlLabelStyles } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { DeleteRiskDialog } from 'app/notes-ui/shared/DeleteRiskDialog';
import {
  Labels,
  SafetyConcerns,
} from 'app/notes-ui/shared/safety-progress/constants';
import { SafetyFormProps } from 'app/notes-ui/shared/safety-progress/types';
import { SectionDivider } from 'app/notes-ui/therapy/shared/SectionDivider';
import React from 'react';
import { Width } from 'types/StyleTypes';

import { clearEatingDisorderFields } from '../../safety';

const formControlStyles: NoteFormControlLabelStyles = {
  checkbox: styles.checkbox,
  label: styles.label,
  root: styles.root,
  subtext: styles.subtext,
};

const RISK = SafetyConcerns.EATING_DISORDER_RISK;

export const InitialStateChecker = (note: Safety) => {
  return note?.eatingDisorderRisk?.currentDescription;
};

export const EatingDisorderRisk = (props: SafetyFormProps) => {
  const { disabled, fields, onDelete } = props;
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);

  const deleteRisk = () => {
    clearEatingDisorderFields(fields);
    onDelete(RISK);
    setIsDeleteDialogOpen(false);
  };

  return (
    <>
      {isDeleteDialogOpen && (
        <DeleteRiskDialog
          deletingRisk={RISK}
          onClose={() => {
            setIsDeleteDialogOpen(false);
          }}
          onConfirm={deleteRisk}
        />
      )}
      <SectionDivider
        label={RISK}
        isCritical={true}
        isRemovable={true}
        onDelete={() => setIsDeleteDialogOpen(true)}
      />
      <div className={styles.textAreaField}>
        <TextAreaField
          testId={Labels.eatingDisorderRisk.id}
          disabled={disabled}
          label={Labels.eatingDisorderRisk.label}
          placeholder={Labels.eatingDisorderRisk.placeholder}
          field={fields.eatingDisorderRisk.currentDescription}
          width={Width.FULL}
          className={styles.textAreaField}
          formControlStyles={formControlStyles}
          autoExpand={true}
          rows={1}
        />
      </div>
    </>
  );
};
