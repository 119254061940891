import { Field, useForm } from '@ginger.io/react-use-form';
import {
  SubstanceUse_SubstanceType,
  SubstanceUse_SubstanceUseLineItem,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SubstanceUse';
import { TableCell, TableRow } from '@mui/material';

import { TextBoxField } from 'app/notes-ui/forms/fields/textBoxField';
import { TextArea } from 'app/notes-ui/forms/form-controls/TextArea';
import React, { useEffect, useState } from 'react';
import Checkbox from 'shared-components/checkbox/Checkbox';
import { Width } from 'types/StyleTypes';

import { substanceRowFieldDefs } from './schema';
import styles from './SubstanceTableCell.module.scss';

export type OnChangeHandler = (input: {
  item: SubstanceUse_SubstanceUseLineItem;
  isValid: boolean;
  isChecked: boolean;
}) => void;

export type SubstanceTableRowProps = {
  substance: SubstanceUse_SubstanceType;
  initialValue?: SubstanceUse_SubstanceUseLineItem;
  onChange: OnChangeHandler;
  disabled?: boolean;
};

export function SubstanceTableRow({
  substance,
  initialValue,
  disabled = false,
  onChange,
}: SubstanceTableRowProps) {
  const [checked, setChecked] = useState<boolean | null>(
    initialValue !== undefined ? true : null,
  );
  const { fields, getValue, validate, reset } = useForm<
    Pick<
      SubstanceUse_SubstanceUseLineItem,
      'substanceType' | 'otherSubstanceTypeDescription' | 'description'
    >
  >(
    substanceRowFieldDefs,
    initialValue ||
      SubstanceUse_SubstanceUseLineItem.fromPartial({
        substanceType: substance,
      }),
  );

  useEffect(() => {
    if (checked === null) return;

    validate().then((isValid) => {
      const item = SubstanceUse_SubstanceUseLineItem.fromPartial({
        ...getValue(),
      }); // getValue returns a read-only object
      onChange({ isChecked: checked, isValid, item });
    });

    if (checked === false) {
      setChecked(null);
      reset();
    }

    // TODO: cleanup this effect so we don't need to disable the lint check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, fields, reset, onChange]);

  const substanceCell =
    fields.substanceType.value === SubstanceUse_SubstanceType.Other ? (
      <SubstanceDescriptionField
        disabled={disabled}
        field={fields.otherSubstanceTypeDescription}
      />
    ) : (
      getSubstanceLabel(fields.substanceType.value)
    );

  return (
    <TableRow className={styles.root} aria-label={`substanceRow-${substance}`}>
      <TableCell className={styles.cell} width="5%">
        <Checkbox
          disabled={disabled}
          inputProps={{ 'aria-label': `substanceCheckbox-${substance}` }}
          value={true}
          checked={checked || false}
          onClick={() => setChecked((checked) => !checked)}
        />
      </TableCell>

      <TableCell className={styles.cell} width="20%">
        {substanceCell}
      </TableCell>

      <TableCell className={styles.cell} width="75%">
        <TextArea
          disabled={disabled}
          rows={3}
          label=""
          name="description"
          value={fields.description.value}
          error={fields.description.error}
          onChange={fields.description.setValue}
          width={Width.FULL}
        />
      </TableCell>
    </TableRow>
  );
}

export function SubstanceDescriptionField(props: {
  field: Field<string>;
  disabled?: boolean;
}) {
  return (
    <TextBoxField
      disabled={props.disabled}
      testId="Other"
      label="Other"
      field={props.field}
    />
  );
}

export const substanceToLabel: Record<SubstanceUse_SubstanceType, string> = {
  [SubstanceUse_SubstanceType.etoh]: 'Alcohol',
  [SubstanceUse_SubstanceType.tobacco]: 'Tobacco',
  [SubstanceUse_SubstanceType.cannabis]: 'Cannabis',
  [SubstanceUse_SubstanceType.caffeine]: 'Caffeine',
  [SubstanceUse_SubstanceType.stimulants]: 'Stimulants',
  [SubstanceUse_SubstanceType.opioids]: 'Opioids',
  [SubstanceUse_SubstanceType.hallucinogens]: 'Hallucinogens',
  [SubstanceUse_SubstanceType.iv_drug_use]: 'Iv Drug Use',
  [SubstanceUse_SubstanceType.Other]: 'Other',
  [SubstanceUse_SubstanceType.UNRECOGNIZED]: 'Unrecognized',
  [SubstanceUse_SubstanceType.undefined_substance]: 'Undefined',
};

export function getSubstanceLabel(type: SubstanceUse_SubstanceType): string {
  return substanceToLabel[type];
}

export function getSubstanceLabelV2(type: SubstanceUse_SubstanceType): string {
  if (type === SubstanceUse_SubstanceType.tobacco) return 'Nicotine';
  return substanceToLabel[type];
}
