import { ProtobufEnum } from 'app/notes-ui/types';
import { AiMagicIconState } from 'app/sortable-table/note-header/AiMagicIcon';
import React, { ReactNode } from 'react';
import { Size } from 'types/StyleTypes';
import { enumToOptions } from 'utils/notes';

import { CheckboxGroup } from './CheckboxGroup';

export type Options = Record<string, boolean>;

export type LabelToValue = Record<string, number>;

export type LabelToTooltip = Record<string, string>;

export type Props<T extends number> = {
  name?: string;
  question: string;
  error?: string;
  values?: T[];
  type: ProtobufEnum;
  onChange?: (values: T[]) => void;
  disabled?: boolean;
  tooltips?: { [K in T]?: string };
  labelSize?: Size;
  keyLabels?: { [K in T]?: string };
  keysToExclude?: string[];
  showAiIcon?: boolean;
  feedbackComponent?: ReactNode;
  aiIconState?: AiMagicIconState;
};

export function EnumCheckboxGroup<T extends number>(props: Props<T>) {
  const {
    values: initialValues,
    onChange,
    type,
    tooltips = {},
    keyLabels = {},
    keysToExclude = [],
    ...restProps
  } = props;
  const { options, labelToValue, labelToTooltip } = initializeOptions(
    type,
    tooltips,
    initialValues,
    keyLabels,
    keysToExclude,
  );

  return (
    <CheckboxGroup
      value={options}
      tooltips={labelToTooltip}
      onChange={(updatedState) => {
        const ts = Object.entries(updatedState)
          .filter(([, value]) => value)
          .map(([label]) => (labelToValue[label] as unknown) as T);

        onChange && onChange(ts);
      }}
      {...restProps}
    />
  );
}

export function initializeOptions<T>(
  type: ProtobufEnum,
  tooltips: Record<number, string>,
  initialValue?: T[],
  keyLabels?: Record<string, string>,
  keysToExclude?: string[],
): {
  options: Options;
  labelToValue: LabelToValue;
  labelToTooltip: LabelToTooltip;
} {
  const values: number[] = (initialValue || []).map(
    (value) => (value as unknown) as number,
  );
  const enumOptions = enumToOptions(type, keyLabels ?? {}, keysToExclude);
  const options = {} as Options;
  const labelToValue = {} as LabelToValue;
  const labelToTooltip = {} as LabelToTooltip;
  enumOptions.forEach(({ name, value }) => {
    options[name] = values.includes(value);
    labelToValue[name] = value;
    labelToTooltip[name] = tooltips[value];
  });
  return { labelToTooltip, labelToValue, options };
}
