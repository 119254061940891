import { useApolloClient } from '@apollo/client';
import { ClinicalAppointmentType } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { ClinicalNotes } from 'app/notes-ui/pdf/ClinicalNotePDF';
import { ID, useInterval } from 'app/notes-ui/useInterval';
import { useLogger } from 'app/state/log/useLogger';
import { getAiDraftNote } from 'app/vault/api/getAiDraftNote';
import { useOnMount } from 'hooks/useOnMount';
import moment from 'moment';
import { useRef, useState } from 'react';

export const POLL_INTERVAL_MS = process.env.NODE_ENV !== 'test' ? 10_000 : 0; // 10 seconds

export enum Status {
  Loading = 'loading',
  NotReady = 'not_ready',
  Ready = 'ready',
  Failed = 'failed',
  NotAvailable = 'not_available',
}

type DraftNoteNotReady = {
  status: Status.NotReady | Status.NotAvailable;
  content: null;
  error: null;
};

type DraftNoteLoading = {
  status: Status.Loading;
  content: null;
  error: null;
};

type DraftNoteReady = {
  status: Status.Ready;
  content: ClinicalNotes;
  error: null;
};

type DraftNoteError = {
  status: Status.Failed;
  content: null;
  error: Error;
};

type DraftNote =
  | DraftNoteLoading
  | DraftNoteReady
  | DraftNoteError
  | DraftNoteNotReady;

export function usePollAiNoteDraft({
  memberId,
  appointmentId,
  endPollingAt,
  noteType,
  pollIntervalMs = POLL_INTERVAL_MS,
}: {
  appointmentId: string;
  endPollingAt: string;
  memberId: string;
  noteType: ClinicalAppointmentType;
  pollIntervalMs?: number;
}) {
  const apollo = useApolloClient();
  const idRef = useRef<ID>();
  const [draftNote, setDraftNote] = useState<DraftNote>({
    content: null,
    error: null,
    status: Status.Loading,
  });
  const logger = useLogger();
  const stopPolling = () => {
    if (idRef.current) {
      clearInterval(idRef.current);
      idRef.current = undefined;
    }
  };
  const loadAiDraft = async () => {
    const hasPollingTimeElapsed =
      endPollingAt != null && moment().isAfter(moment(endPollingAt));
    try {
      const note = await getAiDraftNote({
        apollo,
        appointmentId,
        memberId,
        noteType,
      });
      if (note != null) {
        setDraftNote({
          content: note,
          error: null,
          status: Status.Ready,
        });
        stopPolling();
      } else if (hasPollingTimeElapsed) {
        setDraftNote({
          content: null,
          error: null,
          status: Status.NotAvailable,
        });
        stopPolling();
      } else {
        setDraftNote({
          content: null,
          error: null,
          status: Status.NotReady,
        });
      }
    } catch (reason) {
      const err = new Error('Error fetching AI content', {
        cause: reason,
      });
      logger.error(err, {
        appointmentId,
        error: reason,
        memberId,
      });
      setDraftNote({
        content: null,
        error: err,
        status: Status.Failed,
      });
      stopPolling();
    }
  };
  useOnMount(() => {
    loadAiDraft();
    return stopPolling();
  });

  idRef.current = useInterval(loadAiDraft, pollIntervalMs);
  return draftNote;
}
