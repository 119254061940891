import { ClinicalAppointmentType } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { ClinicalNotes } from 'app/notes-ui/pdf/ClinicalNotePDF';
import {
  AiMagicIcon,
  AiMagicIconState,
} from 'app/sortable-table/note-header/AiMagicIcon';
import {
  Status,
  usePollAiNoteDraft,
} from 'app/sortable-table/note-header/usePollAiNoteDraft';
import { ReactComponent as CancelIcon } from 'assets/cancel.svg';
import { ReactComponent as CheckCircleIcon } from 'assets/check-circle.svg';
import {
  aiContentAdded as aiContentAddedText,
  buttonText,
  errorText,
  tooltipPending,
  tooltipReady,
  tooltipUnavailable,
} from 'i18n/en/aiNoteDraft.json';
import moment from 'moment';
import { useMemo, useState } from 'react';
import Loader from 'react-spinners/ClipLoader';
import { Button } from 'shared-components/button/Button';
import Tooltip from 'shared-components/tooltip/Tooltip';
import { classNameCombiner } from 'utils';

import styles from './AddAiContentButton.module.scss';

export const AUTO_DISMISS_TEXT_DELAY =
  process.env.NODE_ENV !== 'test' ? 5_000 : 0;
const STOP_POLLING_THRESHOLD_IN_MINUTES = 45;

type Props = {
  appointmentEndTime: string;
  appointmentId: string;
  memberId: string;
  noteType: ClinicalAppointmentType;
  onClick?: (content: ClinicalNotes) => Promise<void>;
};

export function AddAiContentButton({
  onClick,
  memberId,
  appointmentId,
  appointmentEndTime,
  noteType,
}: Readonly<Props>) {
  const endPollingAt = useMemo(
    () =>
      appointmentEndTime &&
      moment(appointmentEndTime)
        .add(STOP_POLLING_THRESHOLD_IN_MINUTES, 'minutes')
        .toISOString(),
    [appointmentEndTime],
  );
  const draftNote = usePollAiNoteDraft({
    appointmentId,
    endPollingAt,
    memberId,
    noteType,
  });
  const disabled = draftNote.status !== Status.Ready;
  const [showStatusMessageTimeout, setShowStatusMessageTimeout] = useState<
    ReturnType<typeof setTimeout>
  >();
  const [addAiContentStatus, setAddAiContentStatus] = useState<
    'idle' | 'loading' | 'success' | 'error'
  >('idle');

  if (draftNote.status === Status.Loading || addAiContentStatus === 'loading') {
    return (
      <span
        data-testid="loading"
        className={classNameCombiner([styles.root, styles.flexCenter])}
      >
        <Loader size={16} loading={true} />
      </span>
    );
  }
  const handleClick = async () => {
    if (draftNote.status !== Status.Ready) {
      return;
    }

    try {
      setAddAiContentStatus('loading');
      await onClick?.(draftNote.content);
      setAddAiContentStatus('success');
    } catch (e) {
      setAddAiContentStatus('error');
    }

    if (showStatusMessageTimeout) {
      clearTimeout(showStatusMessageTimeout);
    }

    const timeout = setTimeout(() => {
      setAddAiContentStatus('idle');
    }, AUTO_DISMISS_TEXT_DELAY);

    setShowStatusMessageTimeout(timeout);
  };

  return (
    <div
      data-testid="add-ai-note-btn"
      className={classNameCombiner([styles.root, styles.flexCenter])}
    >
      {addAiContentStatus === 'error' && (
        <span className={styles.flexCenter}>
          <CancelIcon className={styles.iconSize} />
          {errorText}
        </span>
      )}
      {addAiContentStatus === 'success' && (
        <span className={`${styles.successState} ${styles.flexCenter}`}>
          <CheckCircleIcon className={styles.iconSize} />
          {aiContentAddedText}
        </span>
      )}

      <Tooltip
        title={getTooltipTitle(draftNote.status)}
        enterDelay={0}
        enterTouchDelay={0}
        placement="top"
      >
        <span className={styles.btnContainer}>
          <Button
            testId="ai-button"
            className={styles.aiButton}
            variant="outlined"
            size="small"
            onClick={handleClick}
            disabled={disabled}
            color="inherit"
            startIcon={
              <AiMagicIcon
                state={
                  disabled ? AiMagicIconState.INACTIVE : AiMagicIconState.ACTIVE
                }
              />
            }
          >
            {buttonText}
          </Button>
        </span>
      </Tooltip>
    </div>
  );
}

function getTooltipTitle(status: Status): string | null {
  switch (status) {
    case Status.NotReady:
      return tooltipPending;
    case Status.Ready:
      return tooltipReady;
    case Status.Failed:
    case Status.NotAvailable:
      return tooltipUnavailable;
    default:
      return null;
  }
}
