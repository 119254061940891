import { BooleanOption } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanOption';
import FormLabel from '@mui/material/FormLabel';
import {
  AiMagicIcon,
  AiMagicIconState,
} from 'app/sortable-table/note-header/AiMagicIcon';
import React, { ReactNode } from 'react';
import { FormError } from 'shared-components/FormError';
import { COLUMN_WIDTH, Columns, Grid } from 'shared-components/grid';

import styles from './BooleanRadioField.module.scss';
import { NoteTooltip } from './NoteTooltip';
import RadioButton from './RadioButton';

export type Props = {
  aiIconState?: AiMagicIconState;
  disabled?: boolean;
  error?: string;
  excludeNotApplicable?: boolean;
  feedbackComponent?: ReactNode;
  horizontalOriented?: boolean;
  label?: string;
  name: string;
  onChange: (value: BooleanOption) => void;
  showAiIcon?: boolean;
  tooltip?: string;
  value: BooleanOption;
};

export function BooleanRadioField(props: Props) {
  const {
    name,
    value,
    label,
    error,
    disabled,
    tooltip,
    horizontalOriented = false,
    excludeNotApplicable = false,
    showAiIcon,
    aiIconState,
    feedbackComponent,
    onChange,
  } = props;

  const handleChange = (option: BooleanOption) => {
    onChange(option);
  };

  const width = horizontalOriented
    ? [COLUMN_WIDTH.FIVE, COLUMN_WIDTH.SEVEN]
    : [COLUMN_WIDTH.FULL];

  const displayRadioButtons = () => {
    return (
      <div data-testid={name}>
        <RadioButton
          name={name}
          label="Yes"
          value={BooleanOption.yes}
          checked={value === BooleanOption.yes}
          onChange={handleChange}
          disabled={disabled}
        />

        <RadioButton
          name={name}
          label="No"
          value={BooleanOption.no}
          checked={value === BooleanOption.no}
          onChange={handleChange}
          disabled={disabled}
        />
        {!excludeNotApplicable && (
          <RadioButton
            name={name}
            label="N/A"
            value={BooleanOption.not_applicable}
            checked={value === BooleanOption.not_applicable}
            onChange={handleChange}
            disabled={disabled}
          />
        )}
      </div>
    );
  };

  return (
    <div className={styles.root}>
      <Grid spacing={0}>
        <Columns widths={width}>
          <div className={styles.label}>
            <FormLabel aria-label={label}>
              <span data-testid="questionLabel">{label}</span>
              {tooltip && <NoteTooltip tooltip={tooltip} />}
              {showAiIcon && (
                <AiMagicIcon state={aiIconState} standalone={true} />
              )}
            </FormLabel>
          </div>
          {horizontalOriented && displayRadioButtons()}
        </Columns>
        {!horizontalOriented && (
          <Columns widths={[COLUMN_WIDTH.FULL]}>
            {displayRadioButtons()}
          </Columns>
        )}
      </Grid>
      {feedbackComponent}
      {error ? <FormError error={error} /> : null}
    </div>
  );
}
