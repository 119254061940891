import { InitialConsult as InitialConsultVaultType } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/InitialConsult';
import { CoachNotesItem } from 'app/coach/coach-notes/CoachNotesTypes';
import { CardTextAreaField } from 'app/coach/coach-notes/note-detail-view/note-fields';
import { initialConsult } from 'app/coach/coach-notes/note-detail-view/note-templates/fieldDefs';
import styles from 'app/coach/coach-notes/note-detail-view/NoteDetailView.module.scss';
import { RisksForm } from 'app/coach/coach-notes/note-detail-view/risk-templates/RisksForm';
import { InitialConsultWithoutRisks } from 'app/coach/coach-notes/types';
import { useCoachNotesForm } from 'app/coach/coach-notes/useCoachNotesForm';
import { autoIncrementSessionCount } from 'app/coach/coach-notes/utils';
import { FieldLabel } from 'app/member-chart-cards/card-field/FieldLabel';

import { NumberField } from 'app/notes-ui/forms/fields/numberField';
import { BooleanRadioField } from 'app/notes-ui/forms/form-controls/BooleanRadioField';
import { useAppState } from 'app/state';
import React from 'react';

enum FieldLabels {
  SESSION_NUMBER = 'Session number *',
  GATHER = 'Gather',
  VALUES = 'Values',
  SELF_CARE_STRATEGIES = 'Self-care strategies',
  SUPPORT_SYSTEM = 'Support system',
  ADDITIONAL_BACKGROUND_INFO = 'Additional background info',
  COLLABORATE_ON_PLAN = 'Collaborate on goal',
  NEXT_STEPS = "What's next?",
  INITIAL_CONSULT_COMPLETED = 'Did you complete the discovery session? *',
}

export enum Placeholders {
  GATHER = 'Accountability, curiosity, therapy...',
  GATHER_HELPER_TEXT = 'What brought the member to the platform? *',
  VALUES = 'Honesty, communication...',
  SELF_CARE_STRATEGIES = 'Hiking, baths...',
  SUPPORT_SYSTEM = 'Brother, best friend...',
  ADDITIONAL_BACKGROUND_INFO = 'Life story, mental health history...',
  COLLABORATE_ON_PLAN = 'Establish focus points, potential interventions / action items...',
  NEXT_STEPS = 'Homework, goal plan, future discussions, scheduling...',
  NEXT_STEPS_HELPER_TEXT = 'Remember to summarize your session to the member! :)',
}

const TOOLTIP_EVENT_LABEL = 'Coach Notes > Discovery Session';

export const InitialConsult = ({
  noteId,
  readOnly,
  latestSessionCount,
  onStateChange,
  'data-testid': testId = 'initialConsult',
}: {
  readOnly: boolean;
  latestSessionCount: number;
  noteId?: string;
  onStateChange: (noteDraft: CoachNotesItem) => void;
  'data-testid'?: string;
}): React.ReactElement | null => {
  const selectedNote = useAppState((_) => _.coachNotes.selectedNote);
  const initialValue = selectedNote?.data as InitialConsultVaultType;
  const currentSessionCount = initialValue?.sessionCount;
  const incrementedSessionCount = autoIncrementSessionCount({
    currentSessionCount,
    latestSessionCount,
    noteId,
    readOnly,
  });
  const { fields, onChangeRisksForm, risks } = useCoachNotesForm<
    InitialConsultWithoutRisks
  >({
    canContainNestedRisks: true,
    onStateChange,
    sessionCount: incrementedSessionCount,
    validationSchema: initialConsult,
  });

  return (
    <div data-testid={testId} className={styles.cardSection}>
      {!readOnly && (
        <>
          <FieldLabel
            className={styles.label}
            labelText={FieldLabels.SESSION_NUMBER}
          />
          <NumberField
            testId="sessionNumberField"
            inputClass={styles.numberField}
            field={fields.sessionCount}
            disabled={readOnly}
            inputProps={{
              min: '0',
            }}
          />
        </>
      )}

      <FieldLabel
        className={`${styles.label} ${styles.groupLabel}`}
        labelText={FieldLabels.GATHER}
      />
      <div className={`${styles.helperText} ${styles.subtle}`}>
        {Placeholders.GATHER_HELPER_TEXT}
      </div>
      <CardTextAreaField
        field={fields.whatBroughtMemberToPlatform}
        name="coachNotesInitialConsultWhatBroughtMemberToPlatform"
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${Placeholders.GATHER}`}
        tooltipText=""
        placeholder={Placeholders.GATHER}
        readOnly={readOnly}
      />

      <FieldLabel
        className={`${styles.label} ${styles.subtle}`}
        labelText={FieldLabels.VALUES}
      />
      <CardTextAreaField
        field={fields.values}
        name="coachNotesInitialConsultValues"
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.VALUES}`}
        tooltipText=""
        placeholder={Placeholders.VALUES}
        readOnly={readOnly}
      />

      <FieldLabel
        className={`${styles.label} ${styles.subtle}`}
        labelText={FieldLabels.SELF_CARE_STRATEGIES}
      />
      <CardTextAreaField
        field={fields.selfCareStrategies}
        name="coachNotesInitialConsultSelfCareStrategies"
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.SELF_CARE_STRATEGIES}`}
        tooltipText=""
        placeholder={Placeholders.SELF_CARE_STRATEGIES}
        readOnly={readOnly}
      />

      <FieldLabel
        className={`${styles.label} ${styles.subtle}`}
        labelText={FieldLabels.SUPPORT_SYSTEM}
      />
      <CardTextAreaField
        field={fields.supportSystem}
        name="coachNotesInitialConsultSupportSystem"
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.SUPPORT_SYSTEM}`}
        tooltipText=""
        placeholder={Placeholders.SUPPORT_SYSTEM}
        readOnly={readOnly}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.ADDITIONAL_BACKGROUND_INFO}
      />
      <CardTextAreaField
        field={fields.additionalBackgroundInfo}
        name="coachNotesInitialConsultAdditionalBackgroundInfo"
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.ADDITIONAL_BACKGROUND_INFO}`}
        tooltipText=""
        placeholder={Placeholders.ADDITIONAL_BACKGROUND_INFO}
        readOnly={readOnly}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.COLLABORATE_ON_PLAN}
      />
      <CardTextAreaField
        field={fields.collaborateOnPlan}
        name="coachNotesInitialConsultCollaborateOnPlan"
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.COLLABORATE_ON_PLAN}`}
        tooltipText=""
        placeholder={Placeholders.COLLABORATE_ON_PLAN}
        readOnly={readOnly}
      />

      <FieldLabel className={styles.label} labelText={FieldLabels.NEXT_STEPS} />
      {!readOnly && (
        <div className={styles.helperText}>
          {Placeholders.NEXT_STEPS_HELPER_TEXT}
        </div>
      )}
      <CardTextAreaField
        field={fields.nextSteps}
        name="coachNotesInitialConsultNextSteps"
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.NEXT_STEPS}`}
        tooltipText=""
        placeholder={Placeholders.NEXT_STEPS}
        readOnly={readOnly}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.INITIAL_CONSULT_COMPLETED}
      />
      <BooleanRadioField
        disabled={readOnly}
        name="initialConsultProgressReview"
        value={fields.initialConsultCompleted.value}
        onChange={fields.initialConsultCompleted.setValue}
      />

      <RisksForm
        risks={risks}
        onChange={onChangeRisksForm}
        readOnly={readOnly}
        inline={true}
      />
    </div>
  );
};
