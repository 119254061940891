import { Field } from '@ginger.io/react-use-form/dist/types';
import { YesOrNoQuestion } from 'app/notes-ui/forms/form-controls/YesOrNoQuestion';
import React from 'react';

type Props = {
  buttonContainerClass?: string;
  disabled?: boolean;
  field: Field<boolean>;
  fullWidth?: boolean;
  gridClass?: string;
  label: string;
  labelSize?: 'XS' | 'M' | 'L';
  noLabel?: string;
  testId?: string;
  tooltip?: string;
  yesLabel?: string;
};

export function YesOrNoField(props: Readonly<Props>) {
  const { label, field, testId, ...rest } = props;
  return (
    <YesOrNoQuestion
      {...rest}
      label={label}
      name={testId ?? label}
      value={field.value}
      error={field.error}
      onChange={field.setValue}
    />
  );
}
