import { Fields } from '@ginger.io/react-use-form/dist/types';
import {
  CollaborationPlan_Goal,
  CollaborationPlan_GoalType,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/CollaborationPlan';

import { EnumDropdownField } from 'app/notes-ui/forms/fields/enumDropdownField';
import { TextAreaField } from 'app/notes-ui/forms/fields/textAreaField';
import { NoteFormControlLabel } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import React from 'react';
import { Width } from 'types/StyleTypes';

import styles from './CollaborationPlanForm.module.scss';

type Props = {
  disabled?: boolean;
  name: string;
  label: string;
  tooltip: string;
  recommendedStepsLabel: string;
  initialValue?: CollaborationPlan_Goal;
  fields: Fields<CollaborationPlan_Goal>;
  goalLabels: Record<string, string>;
};

export function CollaborationGoal(props: Props) {
  const { name, label, tooltip, fields, disabled, goalLabels } = props;
  return (
    <div data-testid={name} className={styles.root}>
      <NoteFormControlLabel label={`${label}*`}>
        <EnumDropdownField
          disabled={disabled}
          testId="goalType"
          placeholder="Select goal"
          options={CollaborationPlan_GoalType}
          field={fields.goalType}
          optionLabels={goalLabels}
          width={Width.FULL}
        />
      </NoteFormControlLabel>

      <TextAreaField
        className={styles.textArea}
        rows={1}
        disabled={
          disabled || fields.goalType.value !== CollaborationPlan_GoalType.other
        }
        testId="otherGoalTypeDescription"
        label="Other goal type description"
        field={fields.otherGoalTypeDescription}
      />

      <TextAreaField
        className={styles.textArea}
        disabled={disabled}
        testId="recommendedSteps"
        label={`${props.recommendedStepsLabel}*`}
        field={fields.recommendedSteps}
        tooltip={tooltip}
      />
    </div>
  );
}
