import { Metadata_NoteStatus } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import {
  PsychiatryProgressSection,
  PsychiatrySectionName,
} from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import { DeleteVaultItemsMutation } from '@ginger.io/vault-ui/src/generated/graphql';
import { NoteType } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { GetAppointmentById_getAppointmentById as Appointment } from '@headspace/carehub-graphql/dist/vault/generated/GetAppointmentById';
import { ShareableClinicianNoteType } from 'app/coach/coach-notes/CoachNotesTypes';
import {
  deletedDraftClinicalNote,
  viewedClinicalNote,
  wroteClinicalNote,
} from 'app/state/amplitude/actions/notes';
import { ILogger } from 'app/state/log/Logger';
import { useLogger } from 'app/state/log/useLogger';
import {
  PsychiatryProgressNote,
  PsychiatryProgressNotesAPI,
} from 'app/vault/api/PsychiatryProgressNotesAPI';
import { useInitializePsychiatryProgressNote } from 'app/vault/hooks/useInitializePsychiatryProgressNote';
import { useLockClinicalNote } from 'app/vault/hooks/useLockClinicalNote';
import { validatePsychiatryProgressNote } from 'app/vault/validations/psychiatryProgress';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useOnMount } from 'hooks/useOnMount';
import Messages from 'i18n/en/vault.json';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CareProviderNotesLabel } from 'utils/notes';

import { usePsychiatryProgressNotesAPI } from './useClinicalNotesAPI';
import {
  isItemAlreadyExistsError,
  StateController,
  useStateSlice,
  UseTherapyNoteResult,
} from './utils';

export function usePsychiatryProgressNote(
  userId: string,
  appointmentId: string,
): UseTherapyNoteResult<PsychiatryProgressNote, PsychiatryProgressSection> {
  const logger = useLogger();
  const api = usePsychiatryProgressNotesAPI();
  const dispatch = useDispatch();
  const noteState = useStateSlice<PsychiatryProgressNote>();
  const noteDraftState = useStateSlice<PsychiatryProgressNote>();

  const { transientFeatureFlags } = useFeatureFlags();

  const allowGroupWriteAccess =
    transientFeatureFlags.enable_supervisor_sign_and_lock_notes_for_user;

  const [appointment, setAppointment] = useState<Appointment>();
  const isLockable = validatePsychiatryProgressNote(
    noteState.state.current.data,
  );
  const intializeNote = useInitializePsychiatryProgressNote(
    userId,
    appointmentId,
  );

  useOnMount(() => {
    const analyticsEventData = {
      appointmentId,
      clinicianId: userId,
      label: CareProviderNotesLabel.NOTE_VIEWED,
      noteType: ShareableClinicianNoteType.PSYCHIATRY_PROGRESS,
    };
    const callback = async () => {
      try {
        noteState.setLoading();

        const appointmentData = await api.getAppointment(appointmentId);

        const data = await intializeNote(appointmentData);
        noteState.setData(data);
        noteDraftState.setData(data);
        setAppointment(appointmentData);
        logger.info('usePsychiatryProgressNote onMount succeeded', {
          ...analyticsEventData,
        });
      } catch (e) {
        logger.error(e, {
          message: 'usePsychiatryProgressNote onMount failed',
          ...analyticsEventData,
        });
        noteState.setError(e);
      }
      dispatch(
        viewedClinicalNote({
          ...analyticsEventData,
          label: CareProviderNotesLabel.NOTE_VIEWED,
        }),
      );
    };

    callback();
  });

  const createOrUpdateSection = useCallback(
    createOrUpdateSectionHandler(
      userId,
      appointment,
      api,
      noteState,
      allowGroupWriteAccess,
      dispatch,
      logger,
    ),
    [userId, appointment, api, noteState.state.current, allowGroupWriteAccess],
  );

  const updateDraftNoteState = useCallback(updateDraftState(noteDraftState), [
    noteDraftState.state.current,
  ]);

  const lockNote = useLockClinicalNote({
    appointment,
    data: noteState.state.current.data,
    noteType: NoteType.PSYCHIATRY_PROGRESS,
    updateNoteState: noteState.setData,
  });

  const deleteDraft = useCallback(
    appointment && noteState.state.current.data
      ? deleteDraftHandler(
          userId,
          appointment,
          api,
          noteState.state.current.data,
          dispatch,
          logger,
        )
      : () => Promise.resolve({} as DeleteVaultItemsMutation),
    [appointment, api, noteState.state.current],
  );

  return {
    appointment,
    createOrUpdateSection,
    deleteDraft,
    draftNote: noteDraftState.state,
    isLockable,
    lockNote,
    note: noteState.state,
    updateDraftNoteState,
  };
}

export const createOrUpdateSectionHandler = (
  userId: string,
  appointment: Appointment | undefined,
  api: PsychiatryProgressNotesAPI,
  noteState: StateController<PsychiatryProgressNote>,
  allowGroupWriteAccess: boolean,
  dispatch: ReturnType<typeof useDispatch>,
  logger: ILogger,
  onCreateDraftNoteSectionHandler = createDraftNoteSectionHandler,
  onUpdateDraftNoteSectionHandler = updateDraftNoteSectionHandler,
): ((section: PsychiatryProgressSection) => Promise<void>) => {
  return async (section: PsychiatryProgressSection) => {
    const note = noteState.state.current.data;
    if (note === null) {
      throw new Error(
        `${Messages.failureToCreateOrUpdateNoteSection}: ${noteState.state.current.status}`,
      );
    }

    if (appointment === undefined) {
      throw new Error(`Appointment object not defined`);
    }

    if (api.amendmentSectionName === section.name) {
      return createAmendment(
        userId,
        appointment,
        api,
        noteState,
        section,
        dispatch,
        logger,
      );
    }

    if (note[section.name] === null) {
      try {
        await onCreateDraftNoteSectionHandler({
          allowGroupWriteAccess,
          api,
          appointment,
          dispatch,
          logger,
          note,
          noteState,
          section,
          userId,
        });
      } catch (e) {
        if (!isItemAlreadyExistsError(e)) {
          logger.info(
            'usePsychiatryProgressNote: onCreateDraftNoteSectionHandler skipping retry',
            { error: e },
          );
          throw e;
        }
        logger.info(
          `usePsychiatryProgressNote: onCreateDraftNoteSectionHandler failed for section ${section.name}, retrying as update...`,
          {
            appointmentId: appointment.id,
            clinicianId: userId,
            error: e,
            label: CareProviderNotesLabel.NOTE_CREATED,
            noteType: ShareableClinicianNoteType.PSYCHIATRY_PROGRESS,
            section: section.name,
          },
        );
        return onUpdateDraftNoteSectionHandler({
          allowGroupWriteAccess,
          api,
          appointment,
          dispatch,
          logger,
          note,
          noteState,
          section,
          userId,
        });
      }
    } else if (note[section.name] !== null) {
      await onUpdateDraftNoteSectionHandler({
        allowGroupWriteAccess,
        api,
        appointment,
        dispatch,
        logger,
        note,
        noteState,
        section,
        userId,
      });
    }
  };
};

export type createOrUpdateDraftNoteSectionProps = {
  note: PsychiatryProgressNote;
  section: PsychiatryProgressSection;
  api: PsychiatryProgressNotesAPI;
  userId: string;
  appointment: Appointment;
  allowGroupWriteAccess: boolean;
  noteState: StateController<PsychiatryProgressNote>;
  dispatch: ReturnType<typeof useDispatch>;
  logger: ILogger;
};

export const createDraftNoteSectionHandler = async (
  data: createOrUpdateDraftNoteSectionProps,
) => {
  const {
    note,
    section,
    api,
    userId,
    appointment,
    allowGroupWriteAccess,
    noteState,
    dispatch,
    logger,
  } = data;

  const amplitudePayload = {
    appointmentId: note.metadata.appointmentId,
    clinicianId: userId,
    label: CareProviderNotesLabel.NOTE_CREATED,
    memberId: appointment.member.id,
    noteType: ShareableClinicianNoteType.PSYCHIATRY_PROGRESS,
    section: section.name,
  };
  const needToCreateMetadata =
    note.metadata.status === Metadata_NoteStatus.undefined_note_status;

  const updatedNote: PsychiatryProgressNote = {
    ...note,
    metadata: {
      ...note.metadata,
      status: Metadata_NoteStatus.draft_note,
    },
    [section.name]: section.data,
  };

  await api.createDraftNoteSection(
    userId,
    appointment,
    section,
    needToCreateMetadata ? updatedNote.metadata : undefined,
    allowGroupWriteAccess,
  );
  logger.info(
    `usePsychiatryProgressNote: createOrUpdateSectionHandler successfully created draft section ${section.name}`,
    { ...amplitudePayload },
  );

  noteState.setData(updatedNote);
  dispatch(
    wroteClinicalNote({
      ...amplitudePayload,
      label: CareProviderNotesLabel.NOTE_CREATED,
      success: true,
    }),
  );
};

export const updateDraftNoteSectionHandler = async (
  data: createOrUpdateDraftNoteSectionProps,
) => {
  const {
    note,
    section,
    api,
    userId,
    appointment,
    allowGroupWriteAccess,
    noteState,
    dispatch,
    logger,
  } = data;

  const amplitudePayload = {
    appointmentId: note.metadata.appointmentId,
    clinicianId: userId,
    label: CareProviderNotesLabel.NOTE_UPDATED,
    memberId: appointment.member.id,
    noteType: ShareableClinicianNoteType.PSYCHIATRY_PROGRESS,
    section: section.name,
  };
  const updatedNote = { ...note, [section.name]: section.data };

  try {
    await api.updateDraftNoteSection(
      userId,
      appointment,
      section,
      undefined,
      allowGroupWriteAccess,
    );
    logger?.info(
      `usePsychiatryProgressNote: createOrUpdateSectionHandler successfully updated draft section ${section.name}`,
      { ...amplitudePayload },
    );
  } catch (e) {
    logger?.error(e, {
      message: 'usePsychiatryProgressNote: failed to updateDraftNoteSection',
      ...amplitudePayload,
    });
    dispatch(
      wroteClinicalNote({
        ...amplitudePayload,
        success: false,
      }),
    );
    throw e;
  }
  noteState.setData(updatedNote);
  dispatch(
    wroteClinicalNote({
      ...amplitudePayload,
      success: true,
    }),
  );
};

function updateDraftState(
  noteState: StateController<PsychiatryProgressNote>,
): (section: PsychiatryProgressSection) => void {
  return (section: PsychiatryProgressSection) => {
    const note = noteState.state.current.data;
    if (note === null) {
      throw new Error(
        `Illegal state when trying to update a note section: ${noteState.state.current.status}`,
      );
    }

    if (section.name === PsychiatrySectionName.AMENDMENTS) return;
    noteState.setData({ ...note, [section.name]: section.data });
  };
}

async function createAmendment(
  userId: string,
  appointment: Appointment,
  api: PsychiatryProgressNotesAPI,
  noteState: StateController<PsychiatryProgressNote>,
  section: PsychiatryProgressSection,
  dispatch: ReturnType<typeof useDispatch>,
  logger: ILogger,
) {
  const note = noteState.state.current.data;
  if (note === null) {
    throw new Error(
      `Illegal state when trying to update a note section: ${noteState.state.current.status}`,
    );
  }

  const analyticsEventData = {
    appointmentId: appointment.id,
    clinicianId: userId,
    label: CareProviderNotesLabel.NOTE_AMENDED,
    memberId: appointment.member.id,
    noteType: ShareableClinicianNoteType.PSYCHIATRY_PROGRESS,
    section: section.name,
  };

  try {
    await api.createAmendment(userId, appointment.id, section);
  } catch (e) {
    logger.error(e, {
      message: `usePsychiatryProgressNote: failed to create amendment`,
      ...analyticsEventData,
    });
    dispatch(
      wroteClinicalNote({
        ...analyticsEventData,
        success: false,
      }),
    );
    throw e;
  }

  const updatedNote: PsychiatryProgressNote = {
    ...note,
    [section.name]: section.data,
  };
  noteState.setData(updatedNote);
  dispatch(
    wroteClinicalNote({
      ...analyticsEventData,
      success: true,
    }),
  );
}

function deleteDraftHandler(
  userId: string,
  appointment: Appointment,
  api: PsychiatryProgressNotesAPI,
  note: PsychiatryProgressNote,
  dispatch: ReturnType<typeof useDispatch>,
  logger: ILogger,
): () => Promise<DeleteVaultItemsMutation> {
  return async () => {
    const analyticsEventData = {
      appointmentId: note.metadata.appointmentId,
      clinicianId: userId,
      memberId: appointment.member.id,
      noteType: ShareableClinicianNoteType.PSYCHIATRY_PROGRESS,
    };

    try {
      const deletePromise = await api.deleteDraftNote(
        userId,
        appointment,
        note,
      );
      dispatch(
        deletedDraftClinicalNote({
          ...analyticsEventData,
          label: CareProviderNotesLabel.NOTE_DELETED,
          success: true,
        }),
      );
      logger.info('usePsychiatryProgressNote: deleted draft note', {
        ...analyticsEventData,
      });
      return deletePromise;
    } catch (e) {
      logger.error(e, {
        ...analyticsEventData,
        message: 'usePsychiatryProgressNote: failed to delete draft note',
      });
      dispatch(
        deletedDraftClinicalNote({
          ...analyticsEventData,
          label: CareProviderNotesLabel.NOTE_DELETED,
          success: false,
        }),
      );
      throw e;
    }
  };
}
