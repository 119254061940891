import {
  field,
  FieldDefinitions,
  nonEmptyArrayField,
  stringField,
  useForm,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import {
  SocialAndDevelopmental,
  SocialAndDevelopmental_Employment as Employment,
  SocialAndDevelopmental_LivingArrangement as LivingArrangement,
  SocialAndDevelopmental_RelationShipStatus as RelationshipStatus,
  SocialAndDevelopmental_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/intake/SocialAndDevelopmental';
import {
  SocialAndDevelopmentalSection,
  TherapyIntakeSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';
import { EnumCheckboxesField } from 'app/notes-ui/forms/fields/enumCheckboxesField';
import { TextAreaField } from 'app/notes-ui/forms/fields/textAreaField';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import { NoteFormControlLabel } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { isEmpty } from 'app/notes-ui/shared/ValidationRules';
import { useResetFormWithValue } from 'app/notes-ui/UseResetFormWithValue';
import { NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD } from 'app/notes-ui/utils';
import { AiMagicIconState } from 'app/sortable-table/note-header/AiMagicIcon';
import React from 'react';
import { Width } from 'types/StyleTypes';

import { Labels } from './constants';

export function requiredIfOtherLivingArrangement(
  value: string,
  state: SocialAndDevelopmental,
): string | undefined {
  if (
    state.livingArrangement.includes(
      LivingArrangement.living_arrangement_other,
    ) &&
    isEmpty(value)
  ) {
    return 'This field is required';
  }
  return undefined;
}

export function requiredIfOtherRelationshipStatus(
  value: string,
  state: SocialAndDevelopmental,
): string | undefined {
  if (
    state.relationshipStatus.includes(
      RelationshipStatus.relationship_status_other,
    ) &&
    isEmpty(value)
  ) {
    return 'This field is required';
  }
  return undefined;
}

export function requiredIfOtherEmployment(
  value: string,
  state: SocialAndDevelopmental,
): string | undefined {
  if (state.employment.includes(Employment.other) && isEmpty(value)) {
    return 'This field is required';
  }
  return undefined;
}

export const fieldDefs: FieldDefinitions<SocialAndDevelopmental> = {
  appointmentId: stringField(),
  culturalConsiderations: stringField(),
  employment: nonEmptyArrayField(),

  employmentStatus: {
    status: field({ rules: [] }),
  },
  livingArrangement: nonEmptyArrayField(),
  livingSituation: {
    comment: stringField({ rules: [] }),
    status: field({ rules: [] }),
  },
  maritalStatus: {
    comment: stringField({ rules: [] }),
    status: field({ rules: [] }),
  },
  otherComments: stringField({ rules: [] }),
  otherEmploymentComments: stringField({ rules: [requiredIfOtherEmployment] }),
  otherLivingArrangementComments: stringField({
    rules: [requiredIfOtherLivingArrangement],
  }),
  otherRelationshipStatusComments: stringField({
    rules: [requiredIfOtherRelationshipStatus],
  }),
  relationshipStatus: nonEmptyArrayField(),
  relevantSocialOrFamilyHistory: stringField(),
  version: field<SocialAndDevelopmental_Version>({
    default: SocialAndDevelopmental_Version.v0,
  }),
};

type Props = {
  appointmentId: string;
  initialValue?: SocialAndDevelopmental;
  onSubmit: (data: SocialAndDevelopmentalSection) => void;
  disabled?: boolean;
  enabledAI?: boolean;
  aiIconState?: AiMagicIconState;
};

export function SocialDevelopmentFormV2(props: Props) {
  const {
    initialValue,
    appointmentId,
    disabled,
    onSubmit,
    enabledAI,
    aiIconState,
  } = props;

  const form = useForm<SocialAndDevelopmental>(
    fieldDefs,
    SocialAndDevelopmental.fromPartial({
      ...initialValue,
      appointmentId,
      version: SocialAndDevelopmental_Version.v0,
    }) as SocialAndDevelopmental,
    {
      delay: NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD,
      onStateChange: async () => onStateChange(),
    },
  );

  const { fields, getValue } = form;
  useResetFormWithValue(initialValue, form);

  const onStateChange = async () => {
    if (disabled) return;

    onSubmit({
      data: getValue(),
      name: TherapyIntakeSectionName.SOCIAL_DEVELOPMENTAL,
    });
  };

  return (
    <>
      <>
        <NoteFormControlLabel
          showAiIcon={enabledAI}
          aiIconState={aiIconState}
          label={Labels.relationShipStatus.label}
        >
          <EnumCheckboxesField
            disabled={disabled}
            testId={Labels.relationShipStatus.id}
            label=""
            options={RelationshipStatus}
            field={fields.relationshipStatus}
            keyLabels={{
              in_relationship:
                Labels.relationShipStatus.keyLabels.in_relationship,
              relationship_status_other:
                Labels.relationShipStatus.keyLabels.other,
            }}
          />
          {fields.relationshipStatus.value.includes(
            RelationshipStatus.relationship_status_other,
          ) && (
            <TextAreaField
              disabled={disabled}
              testId={Labels.relationShipStatus.id}
              label=""
              placeholder={Labels.relationShipStatus.placeholder}
              field={fields.otherRelationshipStatusComments}
              rows={1}
              className={styles.otherTextField}
              autoExpand={true}
            />
          )}
        </NoteFormControlLabel>

        <NoteFormControlLabel label={Labels.livingArrangement.label}>
          <EnumCheckboxesField
            disabled={disabled}
            testId={Labels.livingArrangement.id}
            label=""
            options={LivingArrangement}
            field={fields.livingArrangement}
            keyLabels={{
              living_arrangement_other:
                Labels.livingArrangement.keyLabels.other,
              with_friends_or_roommates:
                Labels.livingArrangement.keyLabels.friends,
            }}
          />
          {fields.livingArrangement.value.includes(
            LivingArrangement.living_arrangement_other,
          ) && (
            <TextAreaField
              disabled={disabled}
              testId={Labels.livingArrangement.id}
              label=""
              placeholder={Labels.livingArrangement.placeholder}
              field={fields.otherLivingArrangementComments}
              rows={1}
              className={styles.otherTextField}
              autoExpand={true}
            />
          )}
        </NoteFormControlLabel>

        <NoteFormControlLabel
          label={Labels.employment.label}
          showAiIcon={enabledAI}
          aiIconState={aiIconState}
        >
          <EnumCheckboxesField
            disabled={disabled}
            testId={Labels.employment.id}
            label=""
            options={Employment}
            field={fields.employment}
            keyLabels={{ disabled: Labels.employment.disabledLabel }}
          />
          {fields.employment.value.includes(Employment.other) && (
            <TextAreaField
              disabled={disabled}
              testId={Labels.employment.id}
              label=""
              placeholder={Labels.employment.placeholder}
              field={fields.otherEmploymentComments}
              rows={1}
              className={styles.otherTextField}
              autoExpand={true}
            />
          )}
        </NoteFormControlLabel>
      </>

      <NoteFormControlLabel
        showAiIcon={enabledAI}
        aiIconState={aiIconState}
        label={Labels.relevantSocialHistory.label}
      >
        <TextAreaField
          disabled={disabled}
          testId={Labels.relevantSocialHistory.id}
          field={fields.relevantSocialOrFamilyHistory}
          width={Width.FULL}
          placeholder={Labels.relevantSocialHistory.placeholder}
          rows={1}
          autoExpand={true}
        />
      </NoteFormControlLabel>
      <NoteFormControlLabel
        showAiIcon={enabledAI}
        aiIconState={aiIconState}
        label={Labels.culturalConsiderations.label}
        tooltip={Labels.culturalConsiderations.tooltip}
      >
        <TextAreaField
          disabled={disabled}
          testId={Labels.culturalConsiderations.id}
          field={fields.culturalConsiderations}
          width={Width.FULL}
          placeholder={Labels.culturalConsiderations.placeholder}
          rows={1}
          autoExpand={true}
        />
      </NoteFormControlLabel>
      <NoteFormControlLabel
        showAiIcon={enabledAI}
        aiIconState={aiIconState}
        label={Labels.otherComments.label}
      >
        <TextAreaField
          disabled={disabled}
          testId={Labels.otherComments.id}
          field={fields.otherComments}
          width={Width.FULL}
          placeholder={Labels.otherComments.placeholder}
          rows={1}
          optional={true}
          autoExpand={true}
        />
      </NoteFormControlLabel>
    </>
  );
}

export const validate = (data: SocialAndDevelopmental | null) =>
  data !== null && validateForm(data, fieldDefs);
