import { useForm } from '@ginger.io/react-use-form';
import {
  SubstanceUse_SubstanceType,
  SubstanceUse_SubstanceUseLineItem,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SubstanceUse';
import { Typography } from '@mui/material';
import { CheckboxField } from 'app/notes-ui/forms/fields/checkboxField';
import { TextAreaField } from 'app/notes-ui/forms/fields/textAreaField';
import { Checkbox } from 'app/notes-ui/forms/form-controls/Checkbox';
import { useResetFormWithValue } from 'app/notes-ui/UseResetFormWithValue';
import React, { useEffect, useRef, useState } from 'react';
import { COLUMN_WIDTH, Columns, Grid } from 'shared-components/grid';

import { substanceUseFieldDefs } from './schema';
import styles from './SubstanceField.module.scss';
import { getSubstanceLabelV2 } from './SubstanceTableRow';

export type OnChangeHandler = (input: {
  item: SubstanceUse_SubstanceUseLineItem;
  isValid: boolean;
  isChecked: boolean;
}) => void;

export type Props = {
  substance: SubstanceUse_SubstanceType;
  initialValue?: SubstanceUse_SubstanceUseLineItem;
  onChange: OnChangeHandler;
  onSubmit: () => void;
  disabled?: boolean;
  notEndorsed: boolean;
};

export function SubstanceFieldV2(props: Props) {
  const {
    substance,
    initialValue,
    onChange,
    onSubmit,
    disabled = false,
    notEndorsed,
  } = props;
  const [checked, setChecked] = useState<boolean>(initialValue !== undefined);
  const form = useForm<
    Pick<
      SubstanceUse_SubstanceUseLineItem,
      | 'substanceType'
      | 'otherSubstanceTypeDescription'
      | 'description'
      | 'currentlyUsed'
      | 'pastUsed'
    >
  >(
    substanceUseFieldDefs,
    initialValue ||
      SubstanceUse_SubstanceUseLineItem.fromPartial({
        substanceType: substance,
      }),
    {
      onStateChange: () => {
        onSubmit();
      },
    },
  );
  const checkedPastValue = useRef(checked);

  const { fields, getValue, reset } = form;
  useResetFormWithValue(initialValue, form);
  useEffect(() => {
    setChecked(initialValue !== undefined);
  }, [initialValue]);

  useEffect(() => {
    const item = SubstanceUse_SubstanceUseLineItem.fromPartial({
      ...getValue(),
    }); // getValue returns a read-only object
    onChange({ isChecked: checked, isValid: true, item });

    if (!checked && checkedPastValue.current) {
      clearFields();
    }

    checkedPastValue.current = checked;
  }, [checked, fields, reset, onChange]);

  useEffect(() => {
    if (notEndorsed) {
      clearFields();
    }
  }, [notEndorsed]);

  const clearFields = () => {
    setChecked(false);
  };

  const substanceOther =
    fields.substanceType.value === SubstanceUse_SubstanceType.Other ? (
      <TextAreaField
        disabled={disabled || notEndorsed}
        testId="Other"
        placeholder="substance name..."
        label=""
        field={fields.otherSubstanceTypeDescription}
        className={styles.textField}
        rows={1}
        autoExpand={true}
      />
    ) : null;

  return (
    <div className={styles.root}>
      <div>
        <Checkbox
          checked={checked || false}
          testId={getSubstanceLabelV2(fields.substanceType.value)}
          disabled={disabled || notEndorsed}
          onChange={() => {
            setChecked((checked) => !checked);
            onChange({
              isChecked: !checked,
              isValid: true,
              item: SubstanceUse_SubstanceUseLineItem.fromPartial({
                ...getValue(),
              }),
            });
            onSubmit();
          }}
          label={getSubstanceLabelV2(fields.substanceType.value)}
        />
      </div>
      {checked && (
        <div className={styles.substanceSection}>
          {substanceOther}
          <Typography variant="body1" className={styles.label}>
            When?
          </Typography>
          <Grid spacing={0} className={styles.durationSection}>
            <Columns widths={[COLUMN_WIDTH.QUARTER, COLUMN_WIDTH.QUARTER]}>
              <CheckboxField
                testId={`currentUseCheckbox-${substance}`}
                disabled={disabled || notEndorsed}
                label="Current"
                field={fields.currentlyUsed}
              />
              <CheckboxField
                testId={`pastUseCheckbox-${substance}`}
                disabled={disabled || notEndorsed}
                label="Past"
                field={fields.pastUsed}
              />
            </Columns>
          </Grid>
          <Grid>
            <Typography variant="body1" className={styles.label}>
              Duration, frequency, amount used, tolerance or withdrawal
            </Typography>
            <TextAreaField
              disabled={disabled || notEndorsed}
              testId={`substanceDescription-${substance}`}
              label=""
              placeholder="Type here..."
              field={fields.description}
              className={styles.textField}
              rows={1}
              autoExpand={true}
            />
          </Grid>
        </div>
      )}
    </div>
  );
}
