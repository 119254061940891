import { BooleanOption } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanOption';
import { Metadata_NoteStatus } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import { NoteType } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { GetAppointmentById_getAppointmentById as Appointment } from '@headspace/carehub-graphql/dist/vault/generated/GetAppointmentById';
import { ShareableClinicianNoteType } from 'app/coach/coach-notes/CoachNotesTypes';
import { useUploadClinicalNoteForQaChecks } from 'app/notes-ui/qa-checks/useUploadClinicalNoteForQaChecks';
import { signedAndLockedClinicalNote } from 'app/state/amplitude/actions/notes';
import { useLogger } from 'app/state/log/useLogger';
import { SubsectionType } from 'app/vault/api/ShareableSubsectionTypes';
import {
  ClinicalNoteApiByType,
  ClinicalNoteByType,
} from 'app/vault/hooks/types';
import { useClinicalNotesAPI } from 'app/vault/hooks/useClinicalNotesAPI';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import Messages from 'i18n/en/vault.json';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { CareProviderNotesLabel } from 'utils/notes';

const noteTypeToShareableNoteType: Record<
  NoteType,
  ShareableClinicianNoteType
> = {
  [NoteType.UNSPECIFIED]: ShareableClinicianNoteType.UNDEFINED,
  [NoteType.THERAPY_INTAKE]: ShareableClinicianNoteType.THERAPY_INTAKE,
  [NoteType.THERAPY_PROGRESS]: ShareableClinicianNoteType.THERAPY_PROGRESS,
  [NoteType.PSYCHIATRY_INTAKE]: ShareableClinicianNoteType.PSYCHIATRY_INTAKE,
  [NoteType.PSYCHIATRY_PROGRESS]:
    ShareableClinicianNoteType.PSYCHIATRY_PROGRESS,
};

type Params<T extends NoteType> = {
  appointment: Appointment | undefined;
  data: ClinicalNoteByType<T> | null;
  updateNoteState: (note: ClinicalNoteByType<T>) => void;
  noteType: T;
};

export function useLockClinicalNote<T extends NoteType>(params: Params<T>) {
  const { noteType, data, appointment, updateNoteState } = params;
  const api = useClinicalNotesAPIByNoteType(noteType);
  const { uploadClinicalNoteForQaChecks } = useUploadClinicalNoteForQaChecks();
  const dispatch = useDispatch();
  const logger = useLogger();
  const {
    transientFeatureFlags: {
      enable_supervisor_sign_and_lock_notes_for_user: allowGroupWriteAccess,
    },
  } = useFeatureFlags();

  return useCallback(async () => {
    if (data === null) {
      throw new Error(Messages.failureToLockNote);
    }

    if (appointment == null) {
      throw new Error(Messages.appointmentNotDefined);
    }

    const {
      id: appointmentId,
      clinician: { userId: clinicianId },
      member: { id: memberId },
    } = appointment;

    const label = CareProviderNotesLabel.NOTE_SIGNED_AND_LOCKED;
    const analyticsEventData = {
      appointmentId,
      clinicianId,
      label,
      memberId,
      noteType: noteTypeToShareableNoteType[noteType],
      safetyRiskEndorsed: data.SAFETY?.anyChangesOrRisks === BooleanOption.yes,
    };
    const updatedNote: ClinicalNoteByType<T> = {
      ...data,
      metadata: {
        appointmentId,
        status: Metadata_NoteStatus.signed_and_locked_note,
      },
    };

    try {
      await api.updateNoteMetadata(
        updatedNote.metadata,
        memberId,
        allowGroupWriteAccess,
      );
      logger.info('useLockClinicalNote: successfully updateNoteMetadata', {
        ...analyticsEventData,
      });
    } catch (e) {
      logger.error(e, {
        message: 'useLockClinicalNote: failed to updateNoteMetadata',
        ...analyticsEventData,
      });
      dispatch(
        signedAndLockedClinicalNote({
          ...analyticsEventData,
          label,
          success: false,
        }),
      );
      throw e;
    }

    try {
      await api.lockNoteSections(appointment);
      logger.info('useLockClinicalNote: successfully lockNoteSections', {
        ...analyticsEventData,
      });
    } catch (e) {
      logger.error(e, {
        message: 'useLockClinicalNote: failed to lockNoteSections',
        ...analyticsEventData,
      });
      dispatch(
        signedAndLockedClinicalNote({
          ...analyticsEventData,
          label,
          success: false,
        }),
      );
      throw e;
    }

    try {
      await api.createShareableSubsections(appointment, noteType, {
        [SubsectionType.SAFETY_PLAN]: updatedNote.SAFETY?.safetyPlan,
        [SubsectionType.TREATMENT_PLAN_GOALS]: updatedNote.TREATMENT_PLAN?.goal,
        [SubsectionType.MESSAGE_TO_CARE_TEAM]:
          updatedNote.TREATMENT_PLAN?.messageToCareTeam,
      });
      logger.info(
        'useLockClinicalNote: successfully createShareableSubsections',
        {
          ...analyticsEventData,
        },
      );
    } catch (e) {
      logger.error(e, {
        message:
          'useLockClinicalNote: failed to createShareableSubsections. Continuing anyway.',
        ...analyticsEventData,
      });
    }

    updateNoteState(updatedNote);

    dispatch(
      signedAndLockedClinicalNote({
        ...analyticsEventData,
        label,
        success: true,
      }),
    );

    // Submit the signed and locked note for Brellium QA checks
    await uploadClinicalNoteForQaChecks({
      appointmentMetadata: appointment,
      clinicalNote: updatedNote,
      noteType,
    });
  }, [
    allowGroupWriteAccess,
    api,
    appointment,
    data,
    dispatch,
    logger,
    noteType,
    updateNoteState,
    uploadClinicalNoteForQaChecks,
  ]);
}

export function useClinicalNotesAPIByNoteType<T extends NoteType>(
  noteType: T,
): ClinicalNoteApiByType<T> {
  const api = useClinicalNotesAPI();
  switch (noteType) {
    case NoteType.THERAPY_INTAKE:
      return api.therapyIntakeNotes as ClinicalNoteApiByType<T>;
    case NoteType.THERAPY_PROGRESS:
      return api.therapyProgressNotes as ClinicalNoteApiByType<T>;
    case NoteType.PSYCHIATRY_INTAKE:
      return api.psychiatryIntakeNotes as ClinicalNoteApiByType<T>;
    case NoteType.PSYCHIATRY_PROGRESS:
      return api.psychiatryProgressNotes as ClinicalNoteApiByType<T>;
    default:
      throw new Error(`Unsupported note type: ${noteType}`);
  }
}
