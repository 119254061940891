import { State } from 'app/state/schema';

export const selectFeatureFlags = (state: State) => {
  return state.featureFlags;
};

export const selectTransientFeatureFlags = (state: State) => {
  return state.featureFlags.transientFeatureFlags;
};

export const selectIsBlueskyFeatureFlagEnabled = (state: State) => {
  return state.featureFlags.transientFeatureFlags.carehub_bluesky_brand_updates;
};
