import { Field } from '@ginger.io/react-use-form/dist/types';
import { EnumCheckboxGroup } from 'app/notes-ui/forms/form-controls/EnumCheckboxGroup';
import { ProtobufEnum } from 'app/notes-ui/types';
import { AiMagicIconState } from 'app/sortable-table/note-header/AiMagicIcon';
import React, { ReactNode } from 'react';
import { Size } from 'types/StyleTypes';

type Props<T extends number> = {
  aiIconState?: AiMagicIconState;
  disabled?: boolean;
  feedbackComponent?: ReactNode;
  field: Field<T[]>;
  keyLabels?: Record<string, string>;
  keysToExclude?: string[];
  label: string;
  labelSize?: Size;
  options: ProtobufEnum;
  showAiIcon?: boolean;
  testId?: string;
  tooltips?: { [K in T]?: string };
};

export function EnumCheckboxesField<T extends number>(
  props: Readonly<Props<T>>,
) {
  const { label, field, options, testId, ...rest } = props;
  return (
    <EnumCheckboxGroup
      {...rest}
      name={testId}
      type={options}
      question={label}
      values={field.value}
      error={field.error}
      onChange={field.setValue}
    />
  );
}
