import { useForm, validate as validateForm } from '@ginger.io/react-use-form';
import { Affect_Scope } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Affect';
import { Insight } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Insight';
import { Judgment } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Judgment';
import { Mood } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Mood';
import { Speech } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Speech';
import { ThoughtContent } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/ThoughtContent';
import { ThoughtProcess } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/ThoughtProcess';
import {
  BehavioralObservations,
  BehavioralObservations_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/BehavioralObservations';
import {
  BehavioralObservationSection,
  TherapyProgressSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyProgressSection';
import { EnumCheckboxesField } from 'app/notes-ui/forms/fields/enumCheckboxesField';
import { EnumRadioField } from 'app/notes-ui/forms/fields/enumRadioField';
import { YesOrNoField } from 'app/notes-ui/forms/fields/yesOrNoField';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import { fieldDefsV2 } from 'app/notes-ui/therapy/shared/behavioral-observations/schema';
import { NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD } from 'app/notes-ui/utils';
import React from 'react';
import { COLUMN_WIDTH, Columns, Grid } from 'shared-components/grid';

export const validate = (data: BehavioralObservations | null) =>
  data !== null && validateForm(data, fieldDefsV2);

type Props = {
  appointmentId: string;
  onSubmit: (data: BehavioralObservationSection) => void;
  initialValue?: BehavioralObservations;
  disabled?: boolean;
};

export function BehavioralObservationsFormv2(props: Props) {
  const { appointmentId, initialValue, disabled } = props;

  const { fields, getValue } = useForm<BehavioralObservations>(
    fieldDefsV2,
    {
      ...initialValue,
      appointmentId,
      version: BehavioralObservations_Version.v0,
    } as BehavioralObservations,
    {
      delay: NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD,
      onStateChange: async () => {
        await onSubmit();
      },
    },
  );

  const onSubmit = async () => {
    if (disabled) return;

    props.onSubmit({
      data: getValue(),
      name: TherapyProgressSectionName.BEHAVIORAL_OBSERVATION,
    });
  };

  return (
    <>
      <Grid>
        <Columns
          className={styles.gridColumn}
          widths={[
            COLUMN_WIDTH.HALF,
            COLUMN_WIDTH.HALF,
            COLUMN_WIDTH.HALF,
            COLUMN_WIDTH.HALF,
            COLUMN_WIDTH.HALF,
            COLUMN_WIDTH.HALF,
            COLUMN_WIDTH.FULL,
            COLUMN_WIDTH.HALF,
            COLUMN_WIDTH.HALF,
          ]}
        >
          <EnumCheckboxesField
            disabled={disabled}
            testId="mood"
            label="Mood"
            options={Mood}
            field={fields.mood}
            keyLabels={{ mood_expansive: 'Expansive', normal: 'Unremarkable' }}
            keysToExclude={[Mood[Mood.unremarkable]]}
          />

          <EnumCheckboxesField
            disabled={disabled}
            testId="speech"
            label="Speech"
            options={Speech}
            field={fields.speech}
          />

          <EnumRadioField
            disabled={disabled}
            testId="affect"
            label="Affect"
            options={Affect_Scope}
            field={fields.affect.scope}
            keyLabels={{ broad: 'Appropriate' }}
          />

          <EnumRadioField
            disabled={disabled}
            testId="insight"
            label="Insight"
            options={Insight}
            field={fields.insight}
            keyLabels={{
              good_insight: 'Intact',
              limited_insight: 'Limited',
              poor_insight: 'Impaired',
            }}
          />

          <YesOrNoField
            disabled={disabled}
            testId="congruentWithMood"
            label="Affect congruent with mood?"
            field={fields.affect.congruentWithMood}
            fullWidth={true}
          />

          <EnumRadioField
            disabled={disabled}
            testId="judgment"
            label="Judgment"
            options={Judgment}
            field={fields.judgment}
            keyLabels={{ intact_judgment: 'Intact', poor_judgment: 'Impaired' }}
          />

          <YesOrNoField
            disabled={disabled}
            testId="congruentWithContent"
            label="Affect congruent with content?"
            field={fields.affect.congruentWithContent}
            fullWidth={true}
          />

          <EnumCheckboxesField
            disabled={disabled}
            testId="thoughtContent"
            label="Thought Content"
            options={ThoughtContent}
            field={fields.thoughtContent}
          />

          <EnumCheckboxesField
            disabled={disabled}
            testId="thoughtProcess"
            label="Thought Process"
            options={ThoughtProcess}
            field={fields.thoughtProcess}
            keyLabels={{ perservation: 'Perseveration' }} // this key label is required due to a typo in the protobuf schema
          />
        </Columns>
      </Grid>
    </>
  );
}
