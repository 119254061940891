import { Field } from '@ginger.io/react-use-form/dist/types';
import { NoteFormControlLabelStyles } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { TextAreaWithLabel } from 'app/notes-ui/forms/form-controls/TextArea';
import React, { ReactNode } from 'react';
import { FormError } from 'shared-components/FormError';
import { Width } from 'types/StyleTypes';

type Props = {
  autoExpand?: boolean;
  className?: string;
  disabled?: boolean;
  field: Field<string[]>;
  formControlStyles?: NoteFormControlLabelStyles;
  label?: string[];
  labelDescription?: string[];
  minItems?: number;
  placeholder?: string;
  rows?: number;
  subtext?: string[] | ReactNode[];
  testId?: string;
  tooltip?: string;
  width?: Width;
};

export function TextAreaFieldList(props: Readonly<Props>) {
  const {
    field,
    label,
    labelDescription,
    subtext,
    testId,
    minItems = 1,
    ...rest
  } = props;
  const values = [...field.value];

  if (values.length < minItems) {
    // append the missing items
    values.push(...Array(minItems - values.length).fill(''));
  }

  const textAreaList = values.map((fieldValue, i) => (
    <TextAreaWithLabel
      {...rest}
      key={`${testId}_${i}`}
      name={`${testId}_${i}`}
      label={label ? label[i] : undefined}
      labelDescription={labelDescription ? labelDescription[i] : undefined}
      subtext={subtext ? subtext[i] : undefined}
      value={fieldValue}
      onChange={(value) => {
        values[i] = value;
        field.setValue(values);
      }}
    />
  ));

  return (
    <>
      {textAreaList}
      <FormError error={field.error} />
    </>
  );
}
