import {
  booleanField,
  field,
  FieldDefinitions,
  stringField,
  useForm,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import {
  ClinicianIntakeChecklist,
  ClinicianIntakeChecklist_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/ClinicianIntakeChecklist';
import {
  ClinicianChecklist,
  Location,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/progress/ClinicianChecklist';
import {
  ClinicianChecklistSection,
  TherapyIntakeSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';
import { CheckboxField } from 'app/notes-ui/forms/fields/checkboxField';
import { YesOrNoField } from 'app/notes-ui/forms/fields/yesOrNoField';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import { SaveButton } from 'app/notes-ui/forms/form-controls/SaveButton';
import { AUTO_SAVE_DRAFT_DELAY, requiredFieldIf } from 'app/notes-ui/utils';
import React from 'react';

export type Props = {
  appointmentId: string;
  onSubmit: (checklist: ClinicianChecklistSection) => void;
  updateDraftNoteState: (data: ClinicianChecklistSection) => void;
  initialValue?: ClinicianIntakeChecklist; // used for read-only
  disabled?: boolean;
  savingNote?: boolean;
  templateType: 'Therapy' | 'Psychiatry';
};

export const fieldDefs: FieldDefinitions<ClinicianIntakeChecklist> = {
  appointmentId: stringField(),
  introducedCoachingToMember: field(),
  memberSaidYesToThoughtsOfHarm: field(),
  reviewedInformedConsentWithMember: field(),
  reviewedIntakeBeforeMeeting: field(),
  memberGaveConsentForTelehealthAppointment: booleanField({
    rules: [
      (value, _) => {
        if (value === false) {
          return 'This field must be checked';
        }
      },
    ],
  }),
  releaseOfInformationRequested: field(),
  physicallyLocatedAtApptAddress: field<Location>({
    default: Location.undefined_location,
  }),
  currentLocation: stringField({
    default: '',
    rules: [
      (value, state: ClinicianChecklist) =>
        requiredFieldIf(
          value,
          state,
          'physicallyLocatedAtApptAddress',
          Location.located_outside_appt_address,
        ),
    ],
  }),
  version: field<ClinicianIntakeChecklist_Version>({
    default: ClinicianIntakeChecklist_Version.undefined_version,
  }),
};

export const gingerIntro = `Please use the following language to help member understand coaching: "Ginger is a mental health system delivered through a collaborative care model. As we meet in therapy (psychiatry) you also have access to a text-based behavioral health coach, who can support you with goals and help you stay on track. In addition, after we finish with therapy (psychiatry), coaching is a great step-down for continued support. It looks like your lead coach is ___; you can log into the Ginger app to start chatting with your coach anytime. I'll be collaborating with your coach along the way so we can make sure you get your needs met.`;
export const consentText = `As the provider for this telehealth appointment, I attest that I introduced myself to the patient (and/or legal guardian), provided my credentials, verified the patient’s identity using at least two unique patient identifiers, and determined in consultation with the patient (and/or legal guardian) that telehealth via a real-time/synchronous, face to face, telehealth audio-visual platform is an appropriate, effective, and medically necessary means of providing care for this patient at this time to prevent the patient’s decompensation. The patient (and/or legal guardian) was given informed consent about the process of using this telehealth platform for treatment, including the ability to ask questions at any time, to opt out of participating in the telehealth appointment, and how any disruptions in technology would be handled. The patient (and/or legal guardian) consented to proceed with the telehealth appointment.`;

export function ClinicianChecklistForm(props: Props) {
  const {
    initialValue,
    templateType,
    appointmentId,
    disabled,
    savingNote,
  } = props;

  const { fields, getValue, validate } = useForm<ClinicianIntakeChecklist>(
    fieldDefs,
    { ...initialValue, appointmentId } as ClinicianIntakeChecklist,
    {
      onStateChange: (data) =>
        props.updateDraftNoteState({
          name: TherapyIntakeSectionName.CLINICIAN_CHECKLIST,
          data,
        }),
      delay: AUTO_SAVE_DRAFT_DELAY,
    },
  );

  const onSubmit = async () => {
    const isValid = await validate();
    if (isValid) {
      props.onSubmit({
        name: TherapyIntakeSectionName.CLINICIAN_CHECKLIST,
        data: getValue(),
      });
    }
  };

  const clinicalType =
    templateType === 'Psychiatry' ? 'psychiatrist' : 'therapist';

  return (
    <>
      <div className={styles.formGroup}>
        <YesOrNoField
          testId="introducedCoachingToMember"
          disabled={disabled}
          label="Clinician introduced coaching to member?*"
          field={fields.introducedCoachingToMember}
          tooltip={gingerIntro}
        />
      </div>
      <div className={styles.formGroup}>
        <YesOrNoField
          testId="memberSaidYesToThoughtsOfHarm"
          disabled={disabled}
          label={`Did member say "yes" to "thoughts that you’d better be off dead" or "thoughts of harming self/others"?*`}
          field={fields.memberSaidYesToThoughtsOfHarm}
          tooltip={`If so, complete document assessment and steps taken to ensure safety in "Safety Plan" section.`}
        />
      </div>
      <div className={styles.formGroup}>
        <YesOrNoField
          testId="reviewedInformedConsentWithMember"
          disabled={disabled}
          label={`Did ${clinicalType} review informed consent with member?*`}
          field={fields.reviewedInformedConsentWithMember}
        />
      </div>
      <div className={styles.formGroup}>
        <YesOrNoField
          testId="reviewedIntakeBeforeMeeting"
          disabled={disabled}
          label={`Did ${clinicalType} review intake before meeting?*`}
          field={fields.reviewedIntakeBeforeMeeting}
        />
      </div>

      <div className={styles.formGroup}>
        <YesOrNoField
          testId="releaseOfInformationRequested"
          disabled={disabled}
          label={`Was release of information requested?*`}
          field={fields.releaseOfInformationRequested}
        />
      </div>
      {showConsentCheckbox(disabled, initialValue) && (
        <div data-testid="consentGroup" className={styles.formGroup}>
          <CheckboxField
            testId="memberGaveConsentForTelehealthAppointment"
            disabled={disabled}
            label={consentText}
            field={fields.memberGaveConsentForTelehealthAppointment}
          />
        </div>
      )}
      <div className={styles.formGroup}>
        <SaveButton
          isLoading={savingNote}
          disabled={disabled}
          onSubmit={onSubmit}
        />
      </div>
    </>
  );
}

export function showConsentCheckbox(
  disabled: boolean | undefined,
  initialValue: ClinicianIntakeChecklist | ClinicianChecklist | undefined,
): boolean {
  // Per this ticket: https://app.asana.com/0/1199537661207373/1199998711355945
  // We need to render the consent checkbox (i.e. memberGaveConsentForTelehealthAppointment field) for notes signed & locked
  // after the release of the feature that adds the consent checkbox https://app.asana.com/0/1199537661207265/1199958037941904
  // Since the memberGaveConsentForTelehealthAppointment field is required, it means that notes with memberGaveConsentForTelehealthAppointment === false
  // were written prior to the release of the feature. Also, if disabled === true it means the note has been locked
  return (
    (disabled &&
      initialValue !== undefined &&
      initialValue.memberGaveConsentForTelehealthAppointment) ||
    !disabled
  );
}

export const validate = (data: ClinicianIntakeChecklist | null) =>
  data !== null && validateForm(data, fieldDefs);
