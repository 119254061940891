import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { summaryRoute } from 'app/coach/member-chart/Routes';
import { usePubnubListener } from 'app/coach/pubnub/usePubnubListener';
import { InboxAllTab } from 'app/inbox/InboxAllTab';
import {
  InboxItem,
  InboxSections,
  InboxSource,
  InboxTab,
} from 'app/inbox/types';
import { useCoachInbox } from 'app/inbox/UseCoachInbox';
import { useSubscribeCoachToPubnub } from 'app/inboxV2/hooks/useSubscribeCoachToPubnub';
import {
  getAllTabSectionTypes,
  getSelectedTab,
  getShowInbox,
} from 'app/inboxV2/state/sideBar/selectors';
import { setSelectedTab } from 'app/inboxV2/state/sideBar/sideBarSlice';
import styles from 'app/inboxV2/styles/InboxV2.module.scss';
import { useAppState } from 'app/state';
import { clickMemberItemAction } from 'app/state/amplitude/actions/inbox';
import { openNewTab } from 'app/state/member-tabs/actions';
import {
  selectRPCToCoachChannelId,
  selectWildCardPattern,
} from 'app/state/user/selectors';
import { Routes } from 'app/top-nav/Routes';
import React, { SyntheticEvent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Loader } from 'shared-components/loader/Loader';

import { CompletedSection } from './components/InboxSections/CompletedSection';
import { ConversationSection } from './components/InboxSections/ConversationSection';
import { RiskSection } from './components/InboxSections/RiskSection';
import { ScheduledSection } from './components/InboxSections/ScheduledSection';
import { TaskSection } from './components/InboxSections/TaskSection';
import TabPanel from './components/TabPanel';

export const InboxV2 = () => {
  // TODO: uncomment this code once the conversations endpoint in listener-server returns data in the right format.
  // https://headspace.atlassian.net/browse/CARE-5921
  // const logger = useLogger();
  //
  // const { data, isLoading, isError, error } = useGetConversationsQuery({
  //   limit: 20,
  //   status: 'open',
  // });
  //
  // logger.info('useGetConversationsQuery result', {
  //   data,
  //   error,
  //   isError,
  //   isLoading,
  // });

  // @TODO: https://headspace.atlassian.net/browse/CARE-5845
  // const tasks = useGetRiskTasksQuery({});
  // logger.info('useGetRiskTasksQuery result', tasks);
  const history = useHistory();
  const dispatch = useDispatch();
  const role = useAppState((_) => _.user.role);
  const source = InboxSource.COACH_INBOX;
  const routePrefix = Routes.COACHING_MEMBERS;

  const { loadMore, onSearch } = useCoachInbox();

  const wildCardPattern = useAppState(selectWildCardPattern);
  const rpcToCoachChannelId = useAppState(selectRPCToCoachChannelId);

  const showInbox = useAppState(getShowInbox);
  const selectedTab = useAppState(getSelectedTab);

  const inboxAllTabItems = useAppState(getAllTabSectionTypes);

  useSubscribeCoachToPubnub({
    rpcToCoachChannelId,
    wildCardPattern,
  });

  usePubnubListener();

  const handleChange = useCallback(
    (_event: SyntheticEvent, value: InboxTab) => {
      dispatch(setSelectedTab(value));
    },
    [dispatch],
  );

  const onItemClick = useCallback(
    (item: InboxItem, section: InboxSections): void => {
      dispatch(
        clickMemberItemAction({
          memberId: item.id,
          role: role!,
          section,
          source,
          tab: selectedTab,
        }),
      );
      dispatch(
        openNewTab({
          memberId: item.id,
          section,
          source,
        }),
      );
      history.replace(summaryRoute(routePrefix, item.id));
    },
    [dispatch, history, role, routePrefix, selectedTab, source],
  );

  return (
    <>
      {showInbox ? (
        <div data-testid="inboxV2" className={styles.inboxContainer}>
          <Tabs
            classes={{
              root: styles.inboxHeaderTabs,
            }}
            value={selectedTab}
            variant="fullWidth"
            onChange={handleChange}
          >
            <Tab
              className={styles.inboxChildTab}
              label="Today"
              value={InboxTab.TODAYS}
            />
            <Tab
              className={styles.inboxChildTab}
              label="All"
              value={InboxTab.ALL}
            />
          </Tabs>

          <TabPanel panelValue={InboxTab.TODAYS} value={selectedTab}>
            <RiskSection onItemClick={onItemClick} />
            <ConversationSection onItemClick={onItemClick} />
            <TaskSection onItemClick={onItemClick} />
            <ScheduledSection onItemClick={onItemClick} />
            <CompletedSection onItemClick={onItemClick} />
          </TabPanel>
          <TabPanel panelValue={InboxTab.ALL} value={selectedTab}>
            {inboxAllTabItems.length === 0 ? (
              <Loader />
            ) : (
              <InboxAllTab
                onItemClick={onItemClick}
                loadMore={loadMore}
                onSearch={onSearch}
              />
            )}
          </TabPanel>
        </div>
      ) : null}
    </>
  );
};
