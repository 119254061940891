import { useForm } from '@ginger.io/react-use-form';
import { FocusArea } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/progress/FocusArea';
import {
  FocusAreaSection,
  TherapyProgressSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyProgressSection';

import { TextAreaField } from 'app/notes-ui/forms/fields/textAreaField';
import { SaveButton } from 'app/notes-ui/forms/form-controls/SaveButton';
import { AUTO_SAVE_DRAFT_DELAY } from 'app/notes-ui/utils';
import React from 'react';

import { fieldDefs } from './schema';

type Props = {
  appointmentId: string;
  initialValue?: FocusArea;
  onSubmit: (data: FocusAreaSection) => void;
  updateDraftNoteState: (data: FocusAreaSection) => void;
  disabled?: boolean;
  savingNote?: boolean;
};

export function FocusAreaForm(props: Props) {
  const { appointmentId, initialValue, disabled, savingNote } = props;

  const { fields, getValue, validate } = useForm<FocusArea>(
    fieldDefs,
    { ...initialValue, appointmentId } as FocusArea,
    {
      delay: AUTO_SAVE_DRAFT_DELAY,
      onStateChange: (data) =>
        props.updateDraftNoteState({
          data,
          name: TherapyProgressSectionName.FOCUS_AREA,
        }),
    },
  );

  const onSubmit = async () => {
    if (await validate()) {
      props.onSubmit({
        data: getValue(),
        name: TherapyProgressSectionName.FOCUS_AREA,
      });
    }
  };

  return (
    <>
      <TextAreaField
        disabled={disabled}
        rows={15}
        testId="sessionFocusInClientWords"
        label="Focus of session in client’s own words*"
        field={fields.sessionFocusInClientWords}
      />
      <SaveButton
        isLoading={savingNote}
        disabled={disabled}
        onSubmit={onSubmit}
      />
    </>
  );
}
