export const MuiTabDefault = {
  MuiTab: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          color: '#2875cc',
        },
      },
    },
  },
};

export const MuiTabBlueSky = {
  MuiTab: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          color: '#0061ef',
        },
      },
    },
  },
};

export const MuiTabDark = {
  MuiTab: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          color: '#0061ef',
        },
      },
    },
  },
};
