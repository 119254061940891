import { Field } from '@ginger.io/react-use-form/dist/types';
import { InputBaseComponentProps } from '@mui/material';
import { NumberInput } from 'app/notes-ui/forms/form-controls/NumberInput';
import React from 'react';

type Props = {
  className?: string;
  disabled?: boolean;
  field: Field<number>;
  inputClass?: string;
  inputProps?: InputBaseComponentProps;
  label?: string;
  placeholder?: string;
  testId: string;
  tooltip?: string;
};

export function NumberField(props: Readonly<Props>) {
  const { field, testId, ...rest } = props;
  return (
    <NumberInput
      {...rest}
      value={field.value ?? 0}
      name={testId}
      error={field.error}
      onChange={(value) => {
        if (Number.isNaN(value)) {
          field.setValue((null as unknown) as number);
        } else {
          field.setValue(value);
        }
      }}
    />
  );
}
