import {
  InboxSectionHeaderColor,
  InboxSectionIcon,
} from 'app/inbox/components/InboxSection';
import { InboxSections } from 'app/inbox/types';
import { transformedGetTasksResponse } from 'app/inboxV2/__mocks__/taskApi.mocks';
import { DEFAULT_INBOX_SECTION_ITEM_LIMIT } from 'app/inboxV2/constants';
import { useGetRiskTasksQuery } from 'app/inboxV2/endpoints/riskTasksApi';
import { SectionProps, TaskSummary } from 'app/inboxV2/types';
import { useAppState } from 'app/state';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { formatDateToHumanReadable } from 'utils/dateTime';

import { InboxSectionItem } from '../InboxSectionItem';
import { InboxSectionWrapper } from '../InboxSectionWrapper';
import {
  MainContentText,
  SubContentText,
  SubContentTextColorEnum,
} from '../SectionContentItems';

export const RiskSection: FC<SectionProps> = ({ onItemClick }) => {
  const [riskTasks, setRiskTasks] = useState<TaskSummary[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingItems, setLoadingItems] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(
    DEFAULT_INBOX_SECTION_ITEM_LIMIT,
  );

  const timezone = useAppState(({ user }) => user.timezone ?? 'utc');

  const {
    data: riskTasksData,
    refetch: refetchRiskTasks,
  } = useGetRiskTasksQuery({});

  useEffect(() => {
    setLoading(true);
    setError(false);
    try {
      setRiskTasks(riskTasksData?.taskSummaries ?? []);
      setError(false);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [riskTasksData]);

  const handleRetryClick = useCallback(() => {
    refetchRiskTasks();
  }, [refetchRiskTasks]);

  const handleLoadMoreClick = useCallback(() => {
    setLoadingItems(true);

    setTimeout(() => {
      setOffset((prevOffset) => prevOffset + DEFAULT_INBOX_SECTION_ITEM_LIMIT);
      setLoadingItems(false);
    }, 500); // TODO: remove the delay
  }, []);

  const showLoadMore = useMemo(
    () => transformedGetTasksResponse.metadata.total > offset,
    [offset],
  );

  const paginatedTasks = useMemo(() => riskTasks.slice(0, offset), [
    riskTasks,
    offset,
  ]);

  return (
    <InboxSectionWrapper
      headerColor={InboxSectionHeaderColor.LIGHT_ROSE}
      sectionTitle="Risk Tasks"
      section={InboxSections.RISKS}
      sectionIcon={InboxSectionIcon.SUICIDE_RISK}
      sectionItemCount={riskTasks.length}
      error={error}
      loading={loading}
      onRetryClick={handleRetryClick}
      onLoadMoreClick={handleLoadMoreClick}
      loadingItems={loadingItems}
      showLoadMore={showLoadMore}
    >
      {paginatedTasks.map(({ firstName, lastName, topMatchingTask, id }) => (
        <InboxSectionItem
          key={`${firstName}-${lastName}-${id}`}
          topLeft={<MainContentText label={`${firstName} ${lastName}`} />}
          topRight={
            <SubContentText
              label={formatDateToHumanReadable(
                topMatchingTask.visibleAt!,
                timezone,
                true,
              )}
            />
          }
          bottomLeft={
            <SubContentText
              label="additionalContent"
              textColor={SubContentTextColorEnum.critical}
            />
          }
          onClick={() =>
            onItemClick({ id: String(id), name: '' }, InboxSections.RISKS)
          }
        />
      ))}
    </InboxSectionWrapper>
  );
};
