import {
  field,
  FieldDefinitions,
  nonEmptyArrayField,
  stringField,
  useForm,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import {
  MedicalReviewOfSystem,
  MedicalReviewOfSystem_Constitutional,
  MedicalReviewOfSystem_Endocrine,
  MedicalReviewOfSystem_Musculoskeletal,
  MedicalReviewOfSystem_Neurological,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/MedicalROS';
import {
  MedicalReviewOfSystemSection,
  PsychiatrySectionName,
} from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import { EnumCheckboxesField } from 'app/notes-ui/forms/fields/enumCheckboxesField';
import { TextAreaField } from 'app/notes-ui/forms/fields/textAreaField';
import { SaveButton } from 'app/notes-ui/forms/form-controls/SaveButton';
import { AUTO_SAVE_DRAFT_DELAY } from 'app/notes-ui/utils';
import React from 'react';

type Props = {
  appointmentId: string;
  onSubmit: (data: MedicalReviewOfSystemSection) => void;
  updateDraftNoteState: (data: MedicalReviewOfSystemSection) => void;
  disabled?: boolean;
  savingNote?: boolean;
  initialValue?: MedicalReviewOfSystem;
};

export const fieldDefs: FieldDefinitions<MedicalReviewOfSystem> = {
  appointmentId: field(),
  comments: stringField({ rules: [] }),
  constitutional: nonEmptyArrayField(),
  constitutionalComment: stringField({ rules: [] }),
  endocrine: nonEmptyArrayField(),
  endocrineComment: stringField({ rules: [] }),
  musculoskeletal: nonEmptyArrayField(),
  musculoskeletalComment: stringField({ rules: [] }),
  neurological: nonEmptyArrayField(),
  neurologicalComment: stringField({ rules: [] }),
};

export function MedicalReviewOfSystemForm(props: Props) {
  const { appointmentId, disabled, savingNote, initialValue } = props;
  const { fields, validate, getValue } = useForm<MedicalReviewOfSystem>(
    fieldDefs,
    MedicalReviewOfSystem.fromPartial({ ...initialValue, appointmentId }),
    {
      delay: AUTO_SAVE_DRAFT_DELAY,
      onStateChange: (data) =>
        props.updateDraftNoteState({
          data,
          name: PsychiatrySectionName.MEDICAL_ROS,
        }),
    },
  );

  const onSubmit = async () => {
    const isValid = await validate();
    if (isValid) {
      props.onSubmit({
        data: getValue(),
        name: PsychiatrySectionName.MEDICAL_ROS,
      });
    }
  };

  return (
    <div>
      <EnumCheckboxesField
        disabled={disabled}
        testId="constitutional"
        label="Constitutional*"
        options={MedicalReviewOfSystem_Constitutional}
        field={fields.constitutional}
      />
      <EnumCheckboxesField
        disabled={disabled}
        testId="endocrine"
        label="Endocrine*"
        options={MedicalReviewOfSystem_Endocrine}
        field={fields.endocrine}
      />
      <EnumCheckboxesField
        disabled={disabled}
        testId="musculoskeletal"
        label="Musculoskeletal*"
        options={MedicalReviewOfSystem_Musculoskeletal}
        field={fields.musculoskeletal}
      />
      <EnumCheckboxesField
        disabled={disabled}
        testId="neurological"
        label="Neurological*"
        options={MedicalReviewOfSystem_Neurological}
        field={fields.neurological}
      />
      <TextAreaField
        disabled={disabled}
        testId="comments"
        label="Comments"
        field={fields.comments}
      />
      <SaveButton
        isLoading={savingNote}
        disabled={disabled}
        onSubmit={onSubmit}
      />
    </div>
  );
}

export const validate = (data: MedicalReviewOfSystem | null) =>
  data !== null && validateForm(data, fieldDefs);
