import { selectIsBlueskyFeatureFlagEnabled } from 'app/state/featureFlags/selectors';
import { ReactComponent as GingerLogo } from 'app/top-nav/logo.svg';
import { ReactComponent as HSLogo } from 'assets/headspace-logo.svg';
import { useSelector } from 'react-redux';

import styles from './HeadspaceLogo.module.scss';

export const HeadspaceLogo = () => {
  const isBlueSkyFeatureEnabled = useSelector(
    selectIsBlueskyFeatureFlagEnabled,
  );

  return isBlueSkyFeatureEnabled ? (
    <span className={styles.logo}>
      <HSLogo />
    </span>
  ) : (
    <GingerLogo />
  );
};
