import {
  booleanField,
  field,
  FieldDefinitions,
  nonEmptyArrayField,
  numberField,
  stringField,
  useForm,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import { AnticipatedSession_Frequency } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/AnticipatedSession';
import { Interventions } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Interventions';
import { TreatmentGoal } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/TreatmentGoal';
import {
  TreatmentPlan,
  TreatmentPlan_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/intake/TreatmentPlan';
import { ApproachType } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/ApproachType';
import {
  TherapyIntakeSectionName,
  TreatmentPlanSection,
} from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';

import { EnumMultiSelectDropdownField } from 'app/notes-ui/forms/fields/enumMultiSelectDropdownField';

import { NumberField } from 'app/notes-ui/forms/fields/numberField';
import { TextAreaField } from 'app/notes-ui/forms/fields/textAreaField';
import { YesOrNoField } from 'app/notes-ui/forms/fields/yesOrNoField';
import { AnticipatedNumberOfSessionsField } from 'app/notes-ui/forms/form-controls/AnticipatedNumberOfSessionsField';
import { BooleanRadioField } from 'app/notes-ui/forms/form-controls/BooleanRadioField';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import { NoteFormControlLabel } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { SaveButton } from 'app/notes-ui/forms/form-controls/SaveButton';
import { isEmpty, optionalField } from 'app/notes-ui/shared/ValidationRules';
import Labels from 'app/notes-ui/strings/en.json';
import {
  AUTO_SAVE_DRAFT_DELAY,
  getDefaultEnumValue,
  showComplianceFields,
} from 'app/notes-ui/utils';
import React from 'react';

export type Props = {
  appointmentId: string;
  onSubmit: (checklist: TreatmentPlanSection) => void;
  updateDraftNoteState: (data: TreatmentPlanSection) => void;
  initialValue?: TreatmentPlan; // used for read-only
  disabled?: boolean;
  savingNote?: boolean;
  noteLastUpdatedAt?: string;
};

const fieldDefs: FieldDefinitions<TreatmentPlan> = {
  anticipatedSession: {
    frequency: field({
      rules: [
        (value) =>
          isEmpty(value) || value === AnticipatedSession_Frequency.UNRECOGNIZED
            ? 'These two fields are required'
            : undefined,
      ],
    }),
    numberOfSessionsAnticipated: numberField(),
  },
  appointmentId: stringField(),
  appointmentOffered: field({ rules: [] }),
  approaches: nonEmptyArrayField(),
  goal: field<TreatmentGoal[]>({ default: [], rules: [optionalField] }),
  interventionsUsed: field<Interventions[]>({
    default: [],
    rules: [optionalField],
  }),
  memberOutOfScope: booleanField({ default: false, rules: [optionalField] }),
  nonStandardUsageDescription: stringField({ rules: [] }),
  memberOutOfScopeReason: stringField({ rules: [optionalField] }),
  messageToCareTeam: stringField({ rules: [optionalField] }),
  numberOfSessionsAnticipated: field({ rules: [] }),
  offeredAppointmentAccepted: field({ rules: [] }),
  otherApproachComment: stringField({ rules: [] }),
  referralsToCareOutsideGingerNecessary: stringField({ rules: [] }),
  version: field<TreatmentPlan_Version>({
    default: TreatmentPlan_Version.undefined_version,
  }),
  waitTimeDetrimentalEffect: booleanField(),
};

export function TreatmentPlanForm(props: Props) {
  const {
    initialValue,
    disabled,
    appointmentId,
    savingNote,
    noteLastUpdatedAt,
  } = props;

  const { fields, validate, getValue } = useForm<TreatmentPlan>(
    fieldDefs,
    { ...initialValue, appointmentId } as TreatmentPlan,
    {
      delay: AUTO_SAVE_DRAFT_DELAY,
      onStateChange: (data) =>
        props.updateDraftNoteState({
          data,
          name: TherapyIntakeSectionName.TREATMENT_PLAN,
        }),
    },
  );

  const onSubmit = async () => {
    if (await validate()) {
      props.onSubmit({
        data: getValue(),
        name: TherapyIntakeSectionName.TREATMENT_PLAN,
      });
    }
  };
  return (
    <>
      <NoteFormControlLabel label="Treatment approach/modality to be used by therapist*">
        <EnumMultiSelectDropdownField
          disabled={disabled}
          testId="approach"
          label="Approach"
          options={ApproachType}
          field={fields.approaches}
        />
      </NoteFormControlLabel>

      <TextAreaField
        disabled={disabled}
        testId="nonStandardUsageDescription"
        label="If not the standard of care, please explain usage and note whether discussed with member:"
        field={fields.nonStandardUsageDescription}
      />

      <div className={styles.formGroup}>
        {disabled && fields.numberOfSessionsAnticipated.value ? (
          <NumberField
            disabled={disabled}
            testId="numberOfSessionsAnticipated"
            label="Number of sessions anticipated*"
            field={fields.numberOfSessionsAnticipated}
          />
        ) : (
          <AnticipatedNumberOfSessionsField
            value={{
              frequency: fields.anticipatedSession.frequency.value,
              numberOfSessionsAnticipated:
                fields.anticipatedSession.numberOfSessionsAnticipated.value,
            }}
            label="Number of anticipated sessions*"
            onChange={(value) => {
              fields.anticipatedSession.frequency.setValue(value.frequency);
              fields.anticipatedSession.numberOfSessionsAnticipated.setValue(
                value.numberOfSessionsAnticipated,
              );
            }}
            name="anticipatedSessions"
            error={
              fields.anticipatedSession.frequency.error ||
              fields.anticipatedSession.numberOfSessionsAnticipated.error
            }
          />
        )}
      </div>
      {showComplianceFields(noteLastUpdatedAt) && (
        <div>
          <div className={styles.formGroup}>
            <YesOrNoField
              disabled={disabled}
              testId="waitTimeEffectIntake"
              label={`${Labels.WAIT_TIME_EFFECT}*`}
              field={fields.waitTimeDetrimentalEffect}
            />
          </div>
          <div className={styles.formGroup}>
            <BooleanRadioField
              disabled={disabled}
              name="appointmentOffered"
              label={Labels.OFFER_AN_APPOINTMENT}
              value={getDefaultEnumValue(fields.appointmentOffered)}
              onChange={fields.appointmentOffered.setValue}
            />
          </div>
          <div className={styles.formGroup}>
            <BooleanRadioField
              disabled={disabled}
              name="offeredAppointmentAccepted"
              label={Labels.APPOINTMENT_OFFER_ACCEPTED}
              value={getDefaultEnumValue(fields.offeredAppointmentAccepted)}
              onChange={fields.offeredAppointmentAccepted.setValue}
            />
          </div>
        </div>
      )}
      <TextAreaField
        disabled={disabled}
        testId="referralsToCareOutsideGingerNecessary"
        label={Labels.REFERRALS_TO_CARE_OUTSIDE}
        field={fields.referralsToCareOutsideGingerNecessary}
      />
      <SaveButton
        isLoading={savingNote}
        disabled={disabled}
        onSubmit={onSubmit}
      />
    </>
  );
}

export const validate = (data: TreatmentPlan | null) =>
  data !== null && validateForm(data, fieldDefs);
