import { Field } from '@ginger.io/react-use-form/dist/types';
import React from 'react';
import { Dropdown, DropdownProps } from 'shared-components/Dropdown';

export type Props<T> = {
  dropdownProps: Omit<DropdownProps<T>, 'onSelect'>;
  field: Field<T>;
  onOpen?: () => void;
  onSelect?: (selected: T) => void;
};

export function DropDownField<T extends string | number>({
  field,
  dropdownProps,
  onSelect,
  onOpen,
}: Readonly<Props<T>>) {
  const onSelectField = (selected: T) => {
    if (onSelect) onSelect(selected);
    field.setValue(selected);
  };

  return (
    <Dropdown
      onSelect={onSelectField}
      initialValue={field.value}
      error={field.error}
      {...dropdownProps}
      onOpen={onOpen}
    />
  );
}
