export const MuiPaperDefault = {
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundColor: '#ffffff',
        boxShadow: ' 0px 4px 8px 0px rgba(20, 19, 19, 0.20)',
        marginLeft: '0.15rem',
      },
    },
  },
};

export const MuiPaperBlueSky = {
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundColor: '#ffffff',
        boxShadow: ' 0px 4px 8px 0px rgba(20, 19, 19, 0.20)',
        marginLeft: '0.15rem',
      },
    },
  },
};

export const MuiPaperDark = {
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundColor: '#101340',
        border: '1px solid #545f82',
        boxShadow: ' 0px 4px 8px 0px rgba(20, 19, 19, 0.20)',
        marginLeft: '0.15rem',
      },
    },
  },
};
