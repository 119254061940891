import { Field } from '@ginger.io/react-use-form/dist/types';
import { EnumDropdown } from 'app/notes-ui/forms/form-controls/EnumDropdown';
import { ProtobufEnum } from 'app/notes-ui/types';
import React from 'react';
import { Size, Width } from 'types/StyleTypes';

type Props<T> = {
  // testId and label will be used in the future and are kept here for API consistency
  className?: string;
  disabled?: boolean;
  field: Field<T>;
  label?: string;
  menuItemClass?: string;
  optionLabels?: Record<string, string>;
  options: ProtobufEnum;
  placeholder?: string;
  selectClass?: string;
  size?: Size;
  testId: string;
  width?: Width;
};

export function EnumDropdownField<T>(props: Readonly<Props<T>>) {
  const { testId, field, options, ...rest } = props;
  return (
    <EnumDropdown
      {...rest}
      data-testid={testId}
      initialValue={field.value}
      error={field.error}
      enum={options}
      onSelect={field.setValue}
    />
  );
}
