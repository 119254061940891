import { InboxSectionHeaderColor } from 'app/inbox/components/InboxSection';
import { InboxSections } from 'app/inbox/types';
import {
  fetchMockData,
  transformedGetTasksResponse,
} from 'app/inboxV2/__mocks__/taskApi.mocks';
import { DEFAULT_INBOX_SECTION_ITEM_LIMIT } from 'app/inboxV2/constants';
import { SectionProps, TaskSummary } from 'app/inboxV2/types';
import { SessionFormat } from 'app/member-chart-cards/scheduler/types';
import CalendarIcon from 'assets/inbox/segments/calendar-blank.svg';
import { useOnMount } from 'hooks/useOnMount';
import { FC, useCallback, useMemo, useState } from 'react';
import { SessionIcon } from 'shared-components/SessionIcon/SessionIcon';

import { InboxSectionItem } from '../InboxSectionItem';
import { InboxSectionWrapper } from '../InboxSectionWrapper';
import { MainContentText, SubContentText } from '../SectionContentItems';

export const ScheduledSection: FC<SectionProps> = ({ onItemClick }) => {
  const [scheduledTasks, setScheduledTasks] = useState<TaskSummary[]>(
    transformedGetTasksResponse.taskSummaries.filter((_, index) => index % 2), // TODO: remove once api returns data
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingItems, setLoadingItems] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(
    DEFAULT_INBOX_SECTION_ITEM_LIMIT,
  );

  useOnMount(() => {
    loadData();
  });

  const loadData = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const result = await fetchMockData();
      setScheduledTasks(result.filter((_, index) => index % 2)); // TODO: remove once api returns data
      setError(false);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleRetryClick = useCallback(() => {
    loadData();
  }, [loadData]);

  const handleLoadMoreClick = useCallback(() => {
    setLoadingItems(true);

    setTimeout(() => {
      setOffset((prevOffset) => prevOffset + DEFAULT_INBOX_SECTION_ITEM_LIMIT);
      setLoadingItems(false);
    }, 500); // TODO: remove the delay
  }, []);

  const showLoadMore = useMemo(
    () => transformedGetTasksResponse.metadata.total > offset,
    [offset],
  );

  const paginatedTasks = useMemo(() => scheduledTasks.slice(0, offset), [
    scheduledTasks,
    offset,
  ]);

  const renderAdditionalContent = () => {
    return (
      <>
        <SessionIcon
          sessionFormat={SessionFormat.CHAT}
          calendarIcon={CalendarIcon}
        />
        <span>Follow-up @ 1:00 PM PST</span>
      </>
    );
  };

  return (
    <InboxSectionWrapper
      headerColor={InboxSectionHeaderColor.LIGHT_CLOUD}
      sectionTitle="Scheduled"
      section={InboxSections.SCHEDULED}
      sectionItemCount={scheduledTasks.length}
      error={error}
      loading={loading}
      onRetryClick={handleRetryClick}
      onLoadMoreClick={handleLoadMoreClick}
      loadingItems={loadingItems}
      showLoadMore={showLoadMore}
    >
      {paginatedTasks.map(({ firstName, lastName, id }) => (
        <InboxSectionItem
          key={`${firstName}-${lastName}-${id}`}
          topLeft={<MainContentText label={`${firstName} ${lastName}`} />}
          bottomLeft={<SubContentText label={renderAdditionalContent()} />}
          onClick={() =>
            onItemClick({ id: String(id), name: '' }, InboxSections.SCHEDULED)
          }
        />
      ))}
    </InboxSectionWrapper>
  );
};
