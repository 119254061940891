import {
  field,
  FieldDefinitions,
  stringField,
  useForm,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import {
  ChiefComplaint,
  ChiefComplaint_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/ChiefComplaint';
import {
  ChiefComplaintSection,
  PsychiatrySectionName,
} from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import { TextAreaField } from 'app/notes-ui/forms/fields/textAreaField';
import { TextBoxField } from 'app/notes-ui/forms/fields/textBoxField';
import { NoteFormControlLabel } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { SaveButton } from 'app/notes-ui/forms/form-controls/SaveButton';
import { AUTO_SAVE_DRAFT_DELAY } from 'app/notes-ui/utils';
import React from 'react';
import { Width } from 'types/StyleTypes';

type Props = {
  appointmentId: string;
  onSubmit: (data: ChiefComplaintSection) => void;
  updateDraftNoteState: (data: ChiefComplaintSection) => void;
  disabled?: boolean;
  savingNote?: boolean;
  initialValue?: ChiefComplaint;
};

export const fieldDefs: FieldDefinitions<ChiefComplaint> = {
  appointmentId: field(),
  chiefComplaint: stringField(),
  historyOfPresentIllness: stringField(),
  relevantPhysicalHealthUpdates: stringField({ rules: [] }),
  version: field<ChiefComplaint_Version>({
    default: ChiefComplaint_Version.undefined_version,
  }),
};

export function ChiefComplaintForm(props: Props) {
  const { savingNote, disabled, initialValue, appointmentId } = props;
  const { fields, validate, getValue } = useForm<ChiefComplaint>(
    fieldDefs,
    { ...initialValue, appointmentId } as ChiefComplaint,
    {
      delay: AUTO_SAVE_DRAFT_DELAY,
      onStateChange: (data) =>
        props.updateDraftNoteState({
          data,
          name: PsychiatrySectionName.CHIEF_COMPLAINT,
        }),
    },
  );

  const onSubmit = async () => {
    const isValid = await validate();
    if (isValid) {
      props.onSubmit({
        data: getValue(),
        name: PsychiatrySectionName.CHIEF_COMPLAINT,
      });
    }
  };

  return (
    <div>
      <NoteFormControlLabel
        label="Chief Complaint*"
        tooltip="Reason for visit traditionally in patient’s own words."
      >
        <TextBoxField
          testId="chiefComplaint"
          field={fields.chiefComplaint}
          label=""
        />
      </NoteFormControlLabel>
      <TextAreaField
        width={Width.FULL}
        testId="historyOfPresentIllness"
        field={fields.historyOfPresentIllness}
        label="History of Present Illness (HPI)*"
        rows={15}
      />
      <SaveButton
        isLoading={savingNote}
        disabled={disabled}
        onSubmit={onSubmit}
      />
    </div>
  );
}

export const validate = (data: ChiefComplaint | null) =>
  data !== null && validateForm(data, fieldDefs);
