import { Safety } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyProgress';
import { TextAreaField } from 'app/notes-ui/forms/fields/textAreaField';
import { YesOrNoField } from 'app/notes-ui/forms/fields/yesOrNoField';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import { NoteFormControlLabelStyles } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { DeleteRiskDialog } from 'app/notes-ui/shared/DeleteRiskDialog';
import {
  Labels,
  SafetyConcerns,
} from 'app/notes-ui/shared/safety-progress/constants';
import { SafetyFormProps } from 'app/notes-ui/shared/safety-progress/types';
import { SectionDivider } from 'app/notes-ui/therapy/shared/SectionDivider';
import React from 'react';
import { Width } from 'types/StyleTypes';

import { clearSelfHarmFields } from '../../safety';

const formControlStyles: NoteFormControlLabelStyles = {
  checkbox: styles.checkbox,
  label: styles.label,
  root: styles.root,
  subtext: styles.subtext,
};

const RISK = SafetyConcerns.SELF_HARM_RISK;

export const InitialStateChecker = (note: Safety) => {
  return note?.currentSelfInjury?.isPresent || note?.currentSelfInjury?.hasPast;
};

export const SelfHarmRisk = (props: SafetyFormProps) => {
  const { disabled, fields, onDelete } = props;
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);

  const deleteRisk = () => {
    clearSelfHarmFields(fields);
    onDelete(RISK);
    setIsDeleteDialogOpen(false);
  };

  return (
    <>
      {isDeleteDialogOpen && (
        <DeleteRiskDialog
          deletingRisk={RISK}
          onClose={() => {
            setIsDeleteDialogOpen(false);
          }}
          onConfirm={deleteRisk}
        />
      )}
      <SectionDivider
        label={RISK}
        isCritical={true}
        isRemovable={true}
        onDelete={() => setIsDeleteDialogOpen(true)}
      />
      <YesOrNoField
        disabled={disabled}
        testId={Labels.currentSelfInjury.isPresent.id}
        label={Labels.currentSelfInjury.isPresent.label}
        labelSize="M"
        field={fields.currentSelfInjury?.isPresent}
        yesLabel="Endorsed"
        noLabel="Denied"
      />
      <YesOrNoField
        disabled={disabled}
        testId={Labels.currentSelfInjury.everSoughtMedicalAttentionAsAResult.id}
        label={
          Labels.currentSelfInjury.everSoughtMedicalAttentionAsAResult.label
        }
        field={fields.currentSelfInjury?.everSoughtMedicalAttentionAsAResult}
        yesLabel="Endorsed"
        noLabel="Denied"
      />
      <TextAreaField
        testId={Labels.currentSelfInjury.currentSummarization.id}
        disabled={disabled}
        label={Labels.currentSelfInjury.currentSummarization.label}
        placeholder={Labels.currentSelfInjury.currentSummarization.placeholder}
        field={fields.currentSelfInjury?.currentSummarization}
        width={Width.FULL}
        className={styles.textAreaField}
        formControlStyles={formControlStyles}
        autoExpand={true}
        rows={1}
      />

      <div className={styles.marginBooleanField}>
        <YesOrNoField
          disabled={disabled}
          testId={Labels.currentSelfInjury.hasPast.id}
          label={Labels.currentSelfInjury.hasPast.label}
          labelSize="M"
          field={fields.currentSelfInjury?.hasPast}
          yesLabel="Endorsed"
          noLabel="Denied"
        />
      </div>
      <YesOrNoField
        disabled={disabled}
        testId={
          Labels.currentSelfInjury.pastEverSoughtMedicalAttentionAsAResult.id
        }
        label={
          Labels.currentSelfInjury.pastEverSoughtMedicalAttentionAsAResult.label
        }
        field={
          fields.currentSelfInjury?.pastEverSoughtMedicalAttentionAsAResult
        }
        yesLabel="Endorsed"
        noLabel="Denied"
      />
      <TextAreaField
        testId={Labels.currentSelfInjury.pastSummarization.id}
        disabled={disabled}
        label={Labels.currentSelfInjury.pastSummarization.label}
        placeholder={Labels.currentSelfInjury.pastSummarization.placeholder}
        field={fields.currentSelfInjury?.pastSummarization}
        width={Width.FULL}
        className={styles.textAreaField}
        formControlStyles={formControlStyles}
        autoExpand={true}
        rows={1}
      />
    </>
  );
};
