import { useForm } from '@ginger.io/react-use-form';
import { Affect_Scope } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Affect';
import { Insight } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Insight';
import { Interventions } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Interventions';
import { Judgment } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Judgment';
import { Mood } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Mood';
import { Speech } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Speech';
import { ThoughtContent } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/ThoughtContent';
import { ThoughtProcess } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/ThoughtProcess';
import { BehavioralObservations } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/BehavioralObservations';
import {
  BehavioralObservationSection,
  TherapyIntakeSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';
import Divider from '@mui/material/Divider';
import { EnumCheckboxesField } from 'app/notes-ui/forms/fields/enumCheckboxesField';
import { EnumRadioField } from 'app/notes-ui/forms/fields/enumRadioField';
import { YesOrNoField } from 'app/notes-ui/forms/fields/yesOrNoField';
import { SaveButton } from 'app/notes-ui/forms/form-controls/SaveButton';
import { AUTO_SAVE_DRAFT_DELAY } from 'app/notes-ui/utils';
import React from 'react';
import { COLUMN_WIDTH, Columns, Grid } from 'shared-components/grid';

import { fieldDefs } from './schema';

type Props = {
  appointmentId: string;
  onSubmit: (data: BehavioralObservationSection) => void;
  updateDraftNoteState: (data: BehavioralObservationSection) => void;
  initialValue?: BehavioralObservations;
  disabled?: boolean;
  savingNote?: boolean;
};

export function BehavioralObservationsForm(props: Props) {
  const { appointmentId, initialValue, disabled, savingNote } = props;

  const { fields, validate, getValue } = useForm<BehavioralObservations>(
    fieldDefs,
    { ...initialValue, appointmentId } as BehavioralObservations,
    {
      delay: AUTO_SAVE_DRAFT_DELAY,
      onStateChange: (data) =>
        props.updateDraftNoteState({
          data,
          name: TherapyIntakeSectionName.BEHAVIORAL_OBSERVATION,
        }),
    },
  );

  const onSubmit = async () => {
    if (await validate()) {
      props.onSubmit({
        data: getValue(),
        name: TherapyIntakeSectionName.BEHAVIORAL_OBSERVATION,
      });
    }
  };

  return (
    <>
      <Grid>
        <Columns widths={[COLUMN_WIDTH.HALF, COLUMN_WIDTH.HALF]}>
          <EnumCheckboxesField
            disabled={disabled}
            testId="mood"
            label="Mood*"
            options={Mood}
            field={fields.mood}
            keyLabels={{ mood_expansive: 'Expansive' }}
          />
          <>
            <EnumRadioField
              disabled={disabled}
              testId="affect"
              label="Affect*"
              options={Affect_Scope}
              field={fields.affect.scope}
            />

            <YesOrNoField
              disabled={disabled}
              testId="congruentWithMood"
              label="Affect congruent with mood?"
              field={fields.affect.congruentWithMood}
            />

            <YesOrNoField
              disabled={disabled}
              testId="congruentWithContent"
              label="Affect congruent with content?"
              field={fields.affect.congruentWithContent}
            />
          </>
        </Columns>
      </Grid>

      <Divider />

      <Grid>
        <Columns widths={[COLUMN_WIDTH.HALF, COLUMN_WIDTH.HALF]}>
          <EnumCheckboxesField
            disabled={disabled}
            testId="thoughtContent"
            label="Thought Content*"
            options={ThoughtContent}
            field={fields.thoughtContent}
          />

          <EnumCheckboxesField
            disabled={disabled}
            testId="thoughtProcess"
            label="Thought Process*"
            options={ThoughtProcess}
            field={fields.thoughtProcess}
          />
        </Columns>
      </Grid>
      <Divider />
      <Grid>
        <Columns widths={[COLUMN_WIDTH.HALF, COLUMN_WIDTH.HALF]}>
          <EnumCheckboxesField
            disabled={disabled}
            testId="speech"
            label="Speech*"
            options={Speech}
            field={fields.speech}
          />

          <Grid spacing={0}>
            <Columns widths={[COLUMN_WIDTH.HALF, COLUMN_WIDTH.HALF]}>
              <EnumRadioField
                disabled={disabled}
                testId="insight"
                label="Insight*"
                options={Insight}
                field={fields.insight}
              />

              <EnumRadioField
                disabled={disabled}
                testId="judgment"
                label="Judgment*"
                options={Judgment}
                field={fields.judgment}
              />
            </Columns>

            <EnumCheckboxesField
              disabled={disabled}
              testId="interventionsUsed"
              label="Interventions Used*"
              options={Interventions}
              field={fields.interventionsUsed}
            />
          </Grid>
        </Columns>
      </Grid>
      <SaveButton
        isLoading={savingNote}
        disabled={disabled}
        onSubmit={onSubmit}
      />
    </>
  );
}
