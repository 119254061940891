import { InboxSections } from 'app/inbox/types';
import { RootState } from 'app/state/hooks/baseTypedHooks';

export const selectSideBar = (state: RootState) => state.sideBar;

export const getShowInbox = (state: RootState) => state.sideBar.showInbox;

export const getShowInboxDrawer = (state: RootState) =>
  state.sideBar.showInboxDrawer;

export const getSelectedTab = (state: RootState) => state.sideBar.selectedTab;

export const getAllTabSectionTypes = (state: RootState) => {
  const {
    inbox: { tabSections },
  } = state;

  const pastSection = tabSections[InboxSections.PAST];
  const scheduleSection = tabSections[InboxSections.SCHEDULED];
  const closedSection = tabSections[InboxSections.CLOSED];

  return [...pastSection.ids, ...scheduleSection.ids, ...closedSection.ids];
};
