import { useForm } from '@ginger.io/react-use-form';
import { BooleanOption } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanOption';
import { SafetyConcern } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyConcern';
import { SafetyIntake } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyIntake';
import { Version as SafetyVersion } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyProgress';
import {
  SafetySection as TherapyIntakeSafetySection,
  TherapyIntakeSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';
import {
  NoteType,
  TherapistRole,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';

import { EnumRadioField } from 'app/notes-ui/forms/fields/enumRadioField';
import { AutocompleteIcon } from 'app/notes-ui/forms/form-controls/AutoCompleteWithTag';
import { ButtonAdd } from 'app/notes-ui/forms/form-controls/ButtonAdd';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import { MultipleSelectEnumDropdownV2 } from 'app/notes-ui/forms/form-controls/MultipleSelectEnumDropdown';
import {
  NoteFormControlLabel,
  NoteFormControlLabelStyles,
} from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { RadioListOrientations } from 'app/notes-ui/forms/form-controls/RadioButtonGroup';
import { safetyConcernsMap } from 'app/notes-ui/psychiatry/shared/safety/utils';
import {
  clearAllSafetyFields,
  loadSafetyConcernComponents,
  SafetyConcernMap,
} from 'app/notes-ui/shared/safety';
import { therapyFieldDefs } from 'app/notes-ui/shared/safety-intake/schema';
import {
  FULL_SAFETY_PLAN_FIELDS_CONCERN,
  FULL_SAFETY_PLAN_FIELDS_CONCERNS,
  Labels,
  safetyConcernEnumOptions,
  SafetyConcerns,
} from 'app/notes-ui/shared/safety-progress/constants';
import { SafetyPlan } from 'app/notes-ui/shared/safety-progress/SafetyPlan';
import { NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD } from 'app/notes-ui/utils';
import { useLogger } from 'app/state/log/useLogger';
import { useOnMount } from 'hooks/useOnMount';
import React, { useCallback, useEffect, useState } from 'react';
import { Width } from 'types/StyleTypes';

type Props = {
  appointmentId: string;
  initialValue?: SafetyIntake;
  onSubmit: (data: TherapyIntakeSafetySection) => void;
  disabled?: boolean;
};

const formControlStyles: NoteFormControlLabelStyles = {
  checkbox: styles.checkbox,
  label: styles.label,
  root: styles.root,
  subtext: styles.subtext,
};

export function SafetyFormV2(props: Props) {
  const { appointmentId, initialValue, disabled } = props;

  const [
    collapsedSafetyConcernsDropdown,
    setCollapsedSafetyConcernsDropdown,
  ] = useState(false);
  const [showCondensedSafetyPlan, setShowCondensedSafetyPlan] = useState(false);
  const logger = useLogger();

  const { fields, getValue } = useForm<SafetyIntake>(
    therapyFieldDefs,
    {
      ...(initialValue || SafetyIntake.fromPartial({ appointmentId })),
      selectedConcerns: loadSafetyConcernComponents(
        initialValue || SafetyIntake.fromPartial({ appointmentId }),
      ),
      version: SafetyVersion.v1,
    },
    {
      delay: NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD,
      onStateChange: async () => {
        await onSubmit();
      },
    },
  );

  useOnMount(() => {
    const safetyConcerns = loadSafetyConcernComponents(
      initialValue || SafetyIntake.fromPartial({ appointmentId }),
    );
    const hasFullSafetyPlanFields = safetyConcerns.some((concern) => {
      return FULL_SAFETY_PLAN_FIELDS_CONCERN.includes(concern);
    });
    if (!hasFullSafetyPlanFields && safetyConcerns.length > 0) {
      logger.info(
        'loadSafetyConcernComponents: appointment with previously unvalidatable safety section',
        {
          appointmentId,
          noteType: NoteType.THERAPY_INTAKE,
          safetyConcerns,
        },
      );
    }
  });

  const onSafetySectionDelete = (safetyConcern: SafetyConcerns) => {
    const safetyConcerns = fields.selectedConcerns.value.filter(
      (sc) => SafetyConcernMap[sc] !== safetyConcern,
    );
    fields.selectedConcerns.setValue(safetyConcerns);
  };

  useEffect(() => {
    const hasFullSafetyPlanFields = fields.selectedConcerns.value.some(
      (concern) => {
        if (SafetyConcernMap[concern]) {
          return FULL_SAFETY_PLAN_FIELDS_CONCERNS.includes(
            SafetyConcernMap[concern]!,
          );
        }
        return false;
      },
    );

    setShowCondensedSafetyPlan(!hasFullSafetyPlanFields);
    setCollapsedSafetyConcernsDropdown(
      fields.selectedConcerns.value.length > 0,
    );
  }, [fields.selectedConcerns.value]);

  const onAnyChangesOrRisksChange = useCallback(
    (value: BooleanOption) => {
      fields.anyChangesOrRisks.setValue(value);

      // cleanup the safety section if it's denied
      if (value === BooleanOption.no) {
        clearAllSafetyFields(fields);
      }
    },
    [fields],
  );

  const onSubmit = async () => {
    if (disabled) return;

    let data = getValue();
    const frequency = data.currentSelfInjury?.frequency;

    if (
      data.currentSelfInjury &&
      frequency &&
      frequency.period === undefined &&
      frequency.timesPerPeriod === undefined
    ) {
      data = {
        ...data,
        currentSelfInjury: { ...data.currentSelfInjury, frequency: undefined },
      };
    }

    props.onSubmit({
      data,
      name: TherapyIntakeSectionName.SAFETY,
    });
  };

  return (
    <>
      <EnumRadioField
        disabled={disabled}
        testId={Labels.anyChangesOrRisks.id}
        label={Labels.anyChangesOrRisks.label2}
        subtext={Labels.anyChangesOrRisks.subtext}
        options={BooleanOption}
        field={fields.anyChangesOrRisks}
        keyLabels={{
          no: 'Denied',
          yes: 'Endorsed',
        }}
        keysToExclude={[BooleanOption[BooleanOption.not_applicable]]}
        formControlStyles={formControlStyles}
        radioListOrientation={RadioListOrientations.HORIZONTAL}
        onChange={onAnyChangesOrRisksChange}
      />

      {fields.anyChangesOrRisks.value === BooleanOption.yes && (
        <>
          {safetyConcernsMap.map(({ risk, component: Component }) => {
            const isSelected = fields.selectedConcerns.value.includes(risk);
            return isSelected ? (
              <Component
                key={risk}
                fields={fields}
                disabled={disabled}
                onDelete={onSafetySectionDelete}
              />
            ) : null;
          })}

          {collapsedSafetyConcernsDropdown &&
            fields.selectedConcerns.value.length <
              Object.keys(SafetyConcerns).length && (
              <div className={styles.formGroupV2}>
                <ButtonAdd
                  data-testid={Labels.btnAddAdditionalSafetyConcern.id}
                  disabled={disabled}
                  label={Labels.btnAddAdditionalSafetyConcern.label}
                  onClick={() => setCollapsedSafetyConcernsDropdown(false)}
                />
              </div>
            )}
          {!collapsedSafetyConcernsDropdown && (
            <div className={styles.formGroupV2}>
              <NoteFormControlLabel label="Safety concern">
                <MultipleSelectEnumDropdownV2
                  disabled={disabled}
                  name="safety_concerns"
                  placeholder="Select..."
                  type={SafetyConcern}
                  value={fields.selectedConcerns.value}
                  onChange={fields.selectedConcerns.setValue}
                  width={Width.FULL}
                  icon={AutocompleteIcon.DROPDOWN}
                  hideSelectedTags={true}
                  convertEnumToOptions={() => safetyConcernEnumOptions}
                />
              </NoteFormControlLabel>
            </div>
          )}
          <SafetyPlan
            disabled={disabled}
            fields={fields}
            clinicianType={TherapistRole.THERA}
            showCondensedFields={showCondensedSafetyPlan}
          />
        </>
      )}
    </>
  );
}
