import { Field } from '@ginger.io/react-use-form/dist/types';
import { DateTimeRangeInput } from 'app/notes-ui/forms/form-controls/DateTimeRangeInput';
import React, { ReactNode } from 'react';

type Props = {
  autoEndTime?: number;
  children?: ReactNode;
  disabled?: boolean;
  field: { start: Field<string>; end: Field<string> };
  label: string;
};

export function DateTimeRangeField(props: Readonly<Props>) {
  const {
    field: { end, start },
    children,
    ...rest
  } = props;
  return (
    <DateTimeRangeInput
      {...rest}
      start={start.value}
      end={end.value}
      error={start.error ?? end.error}
      onChange={(data) => {
        start.setValue(data.start);
        end.setValue(data.end);
      }}
    >
      {children}
    </DateTimeRangeInput>
  );
}
