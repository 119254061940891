import {
  arrayField,
  field,
  FieldDefinitions,
  nonEmptyArrayField,
  stringField,
  useForm,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import {
  PsychReviewOfSystem,
  PsychReviewOfSystem_Depression,
  PsychReviewOfSystem_EatingDisorder,
  PsychReviewOfSystem_Hypomania,
  PsychReviewOfSystem_OCD,
  PsychReviewOfSystem_Panic,
  PsychReviewOfSystem_Psychosis,
  PsychReviewOfSystem_PTSD,
  PsychReviewOfSystem_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/PsychROS';
import {
  PsychiatrySectionName,
  PsychReviewOfSystemSection,
} from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import { EnumCheckboxesField } from 'app/notes-ui/forms/fields/enumCheckboxesField';
import { SaveButton } from 'app/notes-ui/forms/form-controls/SaveButton';
import { AUTO_SAVE_DRAFT_DELAY } from 'app/notes-ui/utils';
import React from 'react';

type Props = {
  appointmentId: string;
  onSubmit: (data: PsychReviewOfSystemSection) => void;
  updateDraftNoteState: (data: PsychReviewOfSystemSection) => void;
  disabled?: boolean;
  savingNote?: boolean;
  initialValue?: PsychReviewOfSystem;
};

export const fieldDefs: FieldDefinitions<PsychReviewOfSystem> = {
  appointmentId: field(),
  comments: stringField({ rules: [] }),
  depression: nonEmptyArrayField(),
  eatingDisorder: arrayField<PsychReviewOfSystem_EatingDisorder>({
    default: [],
  }),
  hypomania: nonEmptyArrayField(),
  ocd: nonEmptyArrayField(),
  panic: nonEmptyArrayField(),
  psychosis: nonEmptyArrayField(),
  ptsd: nonEmptyArrayField(),
  version: field<PsychReviewOfSystem_Version>({
    default: PsychReviewOfSystem_Version.undefined_version,
  }),
};

export function PsychReviewOfSystemForm(props: Props) {
  const { appointmentId, disabled, savingNote, initialValue } = props;
  const { fields, validate, getValue } = useForm<PsychReviewOfSystem>(
    fieldDefs,
    PsychReviewOfSystem.fromPartial({ ...initialValue, appointmentId }),
    {
      delay: AUTO_SAVE_DRAFT_DELAY,
      onStateChange: (data) =>
        props.updateDraftNoteState({
          data,
          name: PsychiatrySectionName.PSYCH_ROS,
        }),
    },
  );

  const onSubmit = async () => {
    const isValid = await validate();
    if (isValid) {
      props.onSubmit({
        data: getValue(),
        name: PsychiatrySectionName.PSYCH_ROS,
      });
    }
  };

  return (
    <div data-testid="psychRos">
      <EnumCheckboxesField
        disabled={disabled}
        testId="depression"
        label="Depression*"
        options={PsychReviewOfSystem_Depression}
        field={fields.depression}
      />
      <EnumCheckboxesField
        disabled={disabled}
        testId="panic"
        label="Anxiety/Panic*"
        options={PsychReviewOfSystem_Panic}
        field={fields.panic}
      />
      <EnumCheckboxesField
        disabled={disabled}
        testId="hypomania"
        label="(Hypo)mania*"
        options={PsychReviewOfSystem_Hypomania}
        field={fields.hypomania}
      />
      <EnumCheckboxesField
        disabled={disabled}
        testId="psychosis"
        label="Psychosis*"
        options={PsychReviewOfSystem_Psychosis}
        field={fields.psychosis}
      />
      <EnumCheckboxesField
        disabled={disabled}
        testId="ptsd"
        label="PTSD*"
        options={PsychReviewOfSystem_PTSD}
        field={fields.ptsd}
      />
      <EnumCheckboxesField
        disabled={disabled}
        testId="ocd"
        label="OCD*"
        options={PsychReviewOfSystem_OCD}
        field={fields.ocd}
      />
      <SaveButton
        isLoading={savingNote}
        disabled={disabled}
        onSubmit={onSubmit}
      />
    </div>
  );
}

export const validate = (data: PsychReviewOfSystem | null) =>
  data !== null && validateForm(data, fieldDefs);
