import { useApolloClient } from '@apollo/client';
import { Metadata } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import { useLogger } from 'app/state/log/useLogger';
import { getAiDraftNoteMetaByMemberId } from 'app/vault/api/getAiDraftNote';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useEffect, useState } from 'react';

export function useGetAiDraftMetadataByAppointmentId(memberId: string) {
  const apollo = useApolloClient();
  const logger = useLogger();
  const [metadataByAppointment, setMetadataByAppointment] = useState<
    Record<string, Metadata>
  >({});
  const enableCareHubAiNotetaker = useFeatureFlags().transientFeatureFlags
    .enable_care_hub_ai_notetaker;
  useEffect(() => {
    const run = async () => {
      try {
        if (!enableCareHubAiNotetaker) return;
        const items = await getAiDraftNoteMetaByMemberId({ apollo, memberId });
        const newMetadata = items.reduce((acc, currentMetadata) => {
          acc[currentMetadata.appointmentId] = currentMetadata;
          return acc;
        }, {} as Record<string, Metadata>);

        setMetadataByAppointment(newMetadata);
      } catch (error) {
        logger.error(
          new Error(
            'useGetAiDraftMetadata: Failed to get AI draft metadataByAppointment',
          ),
          {
            error,
            memberId,
          },
        );
      }
    };
    run();
  }, [memberId]);
  return metadataByAppointment;
}
