import { NoteType } from '@headspace/carehub-graphql/dist/generated/globalTypes';

export enum AiNoteBinaryRating {
  NEGATIVE = 'NEGATIVE',
  POSITIVE = 'POSITIVE',
}

export interface AiNoteRatings {
  appointmentId: number;
  noteType: NoteType;
  fieldPath: string;
  binaryRating: AiNoteBinaryRating;
  comments?: string;
}
export enum AiNoteInteractionEvent {
  ACCEPTED = 'ACCEPTED',
}

export interface CreateAiNoteInteractionRequest {
  appointmentId: number;
  interaction: AiNoteInteractionEvent;
  noteType: NoteType;
  timestamp: number;
}
