import {
  GetTasksResponse,
  TaskSummary,
  TransformedGetTasksResponse,
} from '../types';

export const mockTaskResponse: GetTasksResponse = {
  members: [
    {
      age: 49,
      firstName: 'Jackie',
      id: 1,
      lastName: 'Doe',
      preferredLanguage: 'spanish',
    },
    {
      age: 55,
      firstName: 'John',
      id: 2,
      lastName: 'Doe',
      preferredLanguage: 'english',
    },
    {
      age: 2,
      firstName: 'David',
      id: 446717,
      lastName: 'B',
      preferredLanguage: 'en',
    },
    {
      age: 78,
      firstName: 'Liubov',
      id: 446112,
      lastName: 'Panchenko',
      preferredLanguage: 'en',
    },
    {
      age: 78,
      firstName: 'Drew',
      id: 432510,
      lastName: '',
      preferredLanguage: 'en',
    },
    {
      age: 78,
      firstName: 'h',
      id: 448561,
      lastName: '',
      preferredLanguage: 'en',
    },
    {
      age: 78,
      firstName: 'hoa',
      id: 446820,
      lastName: '',
      preferredLanguage: 'en',
    },
    {
      age: 78,
      firstName: 'Viet Nam',
      id: 446590,
      lastName: 'Xin Chao Toi',
      preferredLanguage: 'en',
    },
    {
      age: 78,
      firstName: 'Yemi',
      id: 446111,
      lastName: '',
      preferredLanguage: 'en',
    },
    {
      age: 78,
      firstName: 'lolka',
      id: 446511,
      lastName: '',
      preferredLanguage: 'en',
    },
    {
      age: 78,
      firstName: 'Ziad',
      id: 446052,
      lastName: '',
      preferredLanguage: 'en',
    },
    {
      age: 78,
      firstName: 'g',
      id: 446493,
      lastName: '',
      preferredLanguage: 'en',
    },
    {
      age: 78,
      firstName: 'gdh',
      id: 446375,
      lastName: 'zbsb',
      preferredLanguage: 'en',
    },
    {
      age: 78,
      firstName: 'test',
      id: 446351,
      lastName: 'haha',
      preferredLanguage: 'en',
    },
    {
      age: 78,
      firstName: 'aRay',
      id: 446350,
      lastName: '',
      preferredLanguage: 'en',
    },
    {
      age: 78,
      firstName: 'thang',
      id: 446299,
      lastName: 'vu',
      preferredLanguage: 'en',
    },
    {
      age: 78,
      firstName: 'snsj',
      id: 446134,
      lastName: 'bvh',
      preferredLanguage: 'en',
    },
  ],
  metadata: {
    cursor: '1',
    hasNext: true,
    limit: 10,
    total: 1,
  },
  taskSummaries: [
    {
      matchingTaskCount: 1,
      memberId: 1,
      topMatchingTask: {
        firstViewedAt: '2020-01-01T00:00:00.000Z',
        id: 1,
        priority: 4,
        relatedProviderName: 'Sheryl Jones',
        status: 'ACTIVE',
        type: 'RISKY_CHAT_DETECTED',
        visibleAt: '2025-01-01T00:00:00.000Z',
      },
    },
    {
      matchingTaskCount: 1,
      memberId: 2,
      topMatchingTask: {
        firstViewedAt: '2020-01-01T00:00:00.000Z',
        id: 1,
        priority: 4,
        relatedProviderName: 'Sheryl Jones',
        status: 'ACTIVE',
        type: 'RISKY_CHAT_DETECTED',
        visibleAt: '2025-01-19T00:00:00.000Z',
      },
    },
    {
      matchingTaskCount: 2,
      memberId: 446717,
      topMatchingTask: {
        firstViewedAt: '2025-01-17T15:35:00.149Z',
        id: 30991,
        priority: 12000,
        relatedProviderName: '',
        status: 'ACTIVE',
        type: 'CareProviderNotesDue',
        visibleAt: '2025-01-17T22:02:00.268Z',
      },
    },
    {
      matchingTaskCount: 1,
      memberId: 446112,
      topMatchingTask: {
        firstViewedAt: '2025-01-17T12:35:00.149Z',
        id: 30955,
        priority: 12000,
        relatedProviderName: '',
        status: 'ACTIVE',
        type: 'CareProviderNotesDue',
        visibleAt: '2025-01-17T15:35:00.149Z',
      },
    },
    {
      matchingTaskCount: 1,
      memberId: 432510,
      topMatchingTask: {
        firstViewedAt: '2025-01-28T16:33:00.249Z',
        id: 31368,
        priority: 12000,
        relatedProviderName: '',
        status: 'ACTIVE',
        type: 'CareProviderNotesDue',
        visibleAt: '2025-01-28T16:33:00.249Z',
      },
    },
    {
      matchingTaskCount: 1,
      memberId: 448561,
      topMatchingTask: {
        firstViewedAt: '2025-01-27T15:05:52.652Z',
        id: 31339,
        priority: 12000,
        relatedProviderName: '',
        status: 'ACTIVE',
        type: 'CareProviderNotesDue',
        visibleAt: '2025-01-27T15:05:52.652Z',
      },
    },
    {
      matchingTaskCount: 1,
      memberId: 446820,
      topMatchingTask: {
        firstViewedAt: '2025-01-20T17:02:00.143Z',
        id: 31020,
        priority: 12000,
        relatedProviderName: '',
        status: 'ACTIVE',
        type: 'CareProviderNotesDue',
        visibleAt: '2025-01-20T17:02:00.143Z',
      },
    },
    {
      matchingTaskCount: 2,
      memberId: 446590,
      topMatchingTask: {
        firstViewedAt: '2025-01-20T15:03:00.158Z',
        id: 31013,
        priority: 12000,
        relatedProviderName: '',
        status: 'ACTIVE',
        type: 'CareProviderNotesDue',
        visibleAt: '2025-01-20T15:03:00.158Z',
      },
    },
    {
      matchingTaskCount: 1,
      memberId: 446111,
      topMatchingTask: {
        firstViewedAt: '2025-01-18T01:33:00.270Z',
        id: 30997,
        priority: 12000,
        relatedProviderName: '',
        status: 'ACTIVE',
        type: 'CareProviderNotesDue',
        visibleAt: '2025-01-18T01:33:00.270Z',
      },
    },
    {
      matchingTaskCount: 1,
      memberId: 446511,
      topMatchingTask: {
        firstViewedAt: '2025-01-17T20:34:00.138Z',
        id: 30985,
        priority: 12000,
        relatedProviderName: '',
        status: 'ACTIVE',
        type: 'CareProviderNotesDue',
        visibleAt: '2025-01-17T20:34:00.138Z',
      },
    },
    {
      matchingTaskCount: 1,
      memberId: 446052,
      topMatchingTask: {
        firstViewedAt: '2025-01-17T20:02:00.175Z',
        id: 30983,
        priority: 12000,
        relatedProviderName: '',
        status: 'ACTIVE',
        type: 'CareProviderNotesDue',
        visibleAt: '2025-01-17T20:02:00.175Z',
      },
    },
    {
      matchingTaskCount: 1,
      memberId: 446493,
      topMatchingTask: {
        firstViewedAt: '2025-01-17T17:34:00.214Z',
        id: 30972,
        priority: 12000,
        relatedProviderName: '',
        status: 'ACTIVE',
        type: 'CareProviderNotesDue',
        visibleAt: '2025-01-17T17:34:00.214Z',
      },
    },
    {
      matchingTaskCount: 1,
      memberId: 446375,
      topMatchingTask: {
        firstViewedAt: '2025-01-17T17:02:00.268Z',
        id: 30967,
        priority: 12000,
        relatedProviderName: '',
        status: 'ACTIVE',
        type: 'CareProviderNotesDue',
        visibleAt: '2025-01-17T17:02:00.268Z',
      },
    },
    {
      matchingTaskCount: 1,
      memberId: 446351,
      topMatchingTask: {
        firstViewedAt: '2025-01-17T16:34:00.147Z',
        id: 30963,
        priority: 12000,
        relatedProviderName: '',
        status: 'ACTIVE',
        type: 'CareProviderNotesDue',
        visibleAt: '2025-01-17T16:34:00.147Z',
      },
    },
    {
      matchingTaskCount: 1,
      memberId: 446350,
      topMatchingTask: {
        firstViewedAt: '2025-01-17T16:33:00.320Z',
        id: 30962,
        priority: 12000,
        relatedProviderName: '',
        status: 'ACTIVE',
        type: 'CareProviderNotesDue',
        visibleAt: '2025-01-17T16:33:00.320Z',
      },
    },
    {
      matchingTaskCount: 1,
      memberId: 446299,
      topMatchingTask: {
        firstViewedAt: '2025-01-17T16:03:00.251Z',
        id: 30958,
        priority: 12000,
        relatedProviderName: '',
        status: 'ACTIVE',
        type: 'CareProviderNotesDue',
        visibleAt: '2025-01-17T16:03:00.251Z',
      },
    },
    {
      matchingTaskCount: 1,
      memberId: 446134,
      topMatchingTask: {
        firstViewedAt: '2025-01-17T15:34:00.252Z',
        id: 30954,
        priority: 12000,
        relatedProviderName: '',
        status: 'ACTIVE',
        type: 'CareProviderNotesDue',
        visibleAt: '2025-01-17T15:34:00.252Z',
      },
    },
  ],
};

export const transformedGetTasksResponse: TransformedGetTasksResponse = {
  metadata: {
    cursor: '1',
    hasNext: true,
    limit: 10,
    total: 1,
  },
  taskSummaries: [
    {
      age: 49,
      firstName: 'Jackie',
      id: 1,
      lastName: 'Doe',
      matchingTaskCount: 1,
      memberId: 1,
      preferredLanguage: 'spanish',
      topMatchingTask: {
        firstViewedAt: '2020-01-01T00:00:00.000Z',
        id: 1,
        priority: 4,
        relatedProviderName: 'Sheryl Jones',
        status: 'ACTIVE',
        type: 'RISKY_CHAT_DETECTED',
        visibleAt: '2025-01-01T00:00:00.000Z',
      },
    },
    {
      age: 55,
      firstName: 'John',
      id: 2,
      lastName: 'Doe',
      matchingTaskCount: 1,
      memberId: 2,
      preferredLanguage: 'english',
      topMatchingTask: {
        firstViewedAt: '2020-01-01T00:00:00.000Z',
        id: 1,
        priority: 4,
        relatedProviderName: 'Sheryl Jones',
        status: 'ACTIVE',
        type: 'RISKY_CHAT_DETECTED',
        visibleAt: '2025-01-19T00:00:00.000Z',
      },
    },
    {
      age: 2,
      firstName: 'David',
      id: 446717,
      lastName: 'B',
      matchingTaskCount: 2,
      memberId: 446717,
      preferredLanguage: 'en',
      topMatchingTask: {
        firstViewedAt: '2025-01-17T15:35:00.149Z',
        id: 30991,
        priority: 12000,
        relatedProviderName: '',
        status: 'ACTIVE',
        type: 'CareProviderNotesDue',
        visibleAt: '2025-01-17T22:02:00.268Z',
      },
    },
    {
      age: 78,
      firstName: 'Liubov',
      id: 446112,
      lastName: 'Panchenko',
      matchingTaskCount: 1,
      memberId: 446112,
      preferredLanguage: 'en',
      topMatchingTask: {
        firstViewedAt: '2025-01-17T12:35:00.149Z',
        id: 30955,
        priority: 12000,
        relatedProviderName: '',
        status: 'ACTIVE',
        type: 'CareProviderNotesDue',
        visibleAt: '2025-01-17T15:35:00.149Z',
      },
    },
    {
      age: 78,
      firstName: 'Drew',
      id: 432510,
      lastName: '',
      matchingTaskCount: 1,
      memberId: 432510,
      preferredLanguage: 'en',
      topMatchingTask: {
        firstViewedAt: '2025-01-28T16:33:00.249Z',
        id: 31368,
        priority: 12000,
        relatedProviderName: '',
        status: 'ACTIVE',
        type: 'CareProviderNotesDue',
        visibleAt: '2025-01-28T16:33:00.249Z',
      },
    },
    {
      age: 78,
      firstName: 'h',
      id: 448561,
      lastName: '',
      matchingTaskCount: 1,
      memberId: 448561,
      preferredLanguage: 'en',
      topMatchingTask: {
        firstViewedAt: '2025-01-27T15:05:52.652Z',
        id: 31339,
        priority: 12000,
        relatedProviderName: '',
        status: 'ACTIVE',
        type: 'CareProviderNotesDue',
        visibleAt: '2025-01-27T15:05:52.652Z',
      },
    },
    {
      age: 78,
      firstName: 'hoa',
      id: 446820,
      lastName: '',
      matchingTaskCount: 1,
      memberId: 446820,
      preferredLanguage: 'en',
      topMatchingTask: {
        firstViewedAt: '2025-01-20T17:02:00.143Z',
        id: 31020,
        priority: 12000,
        relatedProviderName: '',
        status: 'ACTIVE',
        type: 'CareProviderNotesDue',
        visibleAt: '2025-01-20T17:02:00.143Z',
      },
    },
    {
      age: 78,
      firstName: 'Viet Nam',
      id: 446590,
      lastName: 'Xin Chao Toi',
      matchingTaskCount: 2,
      memberId: 446590,
      preferredLanguage: 'en',
      topMatchingTask: {
        firstViewedAt: '2025-01-20T15:03:00.158Z',
        id: 31013,
        priority: 12000,
        relatedProviderName: '',
        status: 'ACTIVE',
        type: 'CareProviderNotesDue',
        visibleAt: '2025-01-20T15:03:00.158Z',
      },
    },
    {
      age: 78,
      firstName: 'Yemi',
      id: 446111,
      lastName: '',
      matchingTaskCount: 1,
      memberId: 446111,
      preferredLanguage: 'en',
      topMatchingTask: {
        firstViewedAt: '2025-01-18T01:33:00.270Z',
        id: 30997,
        priority: 12000,
        relatedProviderName: '',
        status: 'ACTIVE',
        type: 'CareProviderNotesDue',
        visibleAt: '2025-01-18T01:33:00.270Z',
      },
    },
    {
      age: 78,
      firstName: 'lolka',
      id: 446511,
      lastName: '',
      matchingTaskCount: 1,
      memberId: 446511,
      preferredLanguage: 'en',
      topMatchingTask: {
        firstViewedAt: '2025-01-17T20:34:00.138Z',
        id: 30985,
        priority: 12000,
        relatedProviderName: '',
        status: 'ACTIVE',
        type: 'CareProviderNotesDue',
        visibleAt: '2025-01-17T20:34:00.138Z',
      },
    },
    {
      age: 78,
      firstName: 'Ziad',
      id: 446052,
      lastName: '',
      matchingTaskCount: 1,
      memberId: 446052,
      preferredLanguage: 'en',
      topMatchingTask: {
        firstViewedAt: '2025-01-17T20:02:00.175Z',
        id: 30983,
        priority: 12000,
        relatedProviderName: '',
        status: 'ACTIVE',
        type: 'CareProviderNotesDue',
        visibleAt: '2025-01-17T20:02:00.175Z',
      },
    },
    {
      age: 78,
      firstName: 'g',
      id: 446493,
      lastName: '',
      matchingTaskCount: 1,
      memberId: 446493,
      preferredLanguage: 'en',
      topMatchingTask: {
        firstViewedAt: '2025-01-17T17:34:00.214Z',
        id: 30972,
        priority: 12000,
        relatedProviderName: '',
        status: 'ACTIVE',
        type: 'CareProviderNotesDue',
        visibleAt: '2025-01-17T17:34:00.214Z',
      },
    },
    {
      age: 78,
      firstName: 'gdh',
      id: 446375,
      lastName: 'zbsb',
      matchingTaskCount: 1,
      memberId: 446375,
      preferredLanguage: 'en',
      topMatchingTask: {
        firstViewedAt: '2025-01-17T17:02:00.268Z',
        id: 30967,
        priority: 12000,
        relatedProviderName: '',
        status: 'ACTIVE',
        type: 'CareProviderNotesDue',
        visibleAt: '2025-01-17T17:02:00.268Z',
      },
    },
    {
      age: 78,
      firstName: 'test',
      id: 446351,
      lastName: 'haha',
      matchingTaskCount: 1,
      memberId: 446351,
      preferredLanguage: 'en',
      topMatchingTask: {
        firstViewedAt: '2025-01-17T16:34:00.147Z',
        id: 30963,
        priority: 12000,
        relatedProviderName: '',
        status: 'ACTIVE',
        type: 'CareProviderNotesDue',
        visibleAt: '2025-01-17T16:34:00.147Z',
      },
    },
    {
      age: 78,
      firstName: 'aRay',
      id: 446350,
      lastName: '',
      matchingTaskCount: 1,
      memberId: 446350,
      preferredLanguage: 'en',
      topMatchingTask: {
        firstViewedAt: '2025-01-17T16:33:00.320Z',
        id: 30962,
        priority: 12000,
        relatedProviderName: '',
        status: 'ACTIVE',
        type: 'CareProviderNotesDue',
        visibleAt: '2025-01-17T16:33:00.320Z',
      },
    },
    {
      age: 78,
      firstName: 'thang',
      id: 446299,
      lastName: 'vu',
      matchingTaskCount: 1,
      memberId: 446299,
      preferredLanguage: 'en',
      topMatchingTask: {
        firstViewedAt: '2025-01-17T16:03:00.251Z',
        id: 30958,
        priority: 12000,
        relatedProviderName: '',
        status: 'ACTIVE',
        type: 'CareProviderNotesDue',
        visibleAt: '2025-01-17T16:03:00.251Z',
      },
    },
    {
      age: 78,
      firstName: 'snsj',
      id: 446134,
      lastName: 'bvh',
      matchingTaskCount: 1,
      memberId: 446134,
      preferredLanguage: 'en',
      topMatchingTask: {
        firstViewedAt: '2025-01-17T15:34:00.252Z',
        id: 30954,
        priority: 12000,
        relatedProviderName: '',
        status: 'ACTIVE',
        type: 'CareProviderNotesDue',
        visibleAt: '2025-01-17T15:34:00.252Z',
      },
    },
  ],
};

export const fetchMockData = async (): Promise<TaskSummary[]> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (Math.random() < 0.5) {
        reject(new Error('Failed to fetch data'));
      } else {
        resolve(transformedGetTasksResponse.taskSummaries);
      }
    }, 300);
  });
};
