import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import careHubScreenStyles from 'app/CareHubScreen/CareHubScreen.module.scss';
import styles from 'app/inbox/styles/index.module.scss';
import { useAppState } from 'app/state';
import { clickInboxTabAction } from 'app/state/amplitude/actions/inbox';
import { setSelectedTab } from 'app/state/inbox/actions';
import React, { ElementType, ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import ErrorBoundary from 'shared-components/error-state/ErrorBoundary';
import {
  classNameCombiner,
  isClinicianOrSupervisor,
  isCoachOrSupervisor,
} from 'utils';

import { useSoundOnNewMemberMessage } from './AudioNotification';
import { ClinicianInboxView } from './ClinicianInboxView';
import { CoachInboxView } from './CoachInboxView';
import { InboxErrorState } from './InboxError/InboxErrorState';
import { InboxSource, InboxTab } from './types';

const inboxTabIndex: { [k in InboxTab]: number } = {
  [InboxTab.TODAYS]: 0,
  [InboxTab.ALL]: 1,
};
export function InboxV1() {
  const role = useAppState(({ user }) => user.role!);

  const dispatch = useDispatch();
  useSoundOnNewMemberMessage();

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: number,
    memberCount: number,
  ) => {
    const inboxTab = newValue === 0 ? InboxTab.TODAYS : InboxTab.ALL;
    dispatch(
      clickInboxTabAction({
        memberCount,
        source: isCoachOrSupervisor(role)
          ? InboxSource.CLINICIAN_INBOX
          : InboxSource.COACH_INBOX,
        tab: inboxTab,
      }),
    );
    dispatch(setSelectedTab({ inboxTab }));
  };

  if (isCoachOrSupervisor(role)) {
    return <Wrapper Component={CoachInboxView} onChange={handleChange} />;
  }

  if (isClinicianOrSupervisor(role)) {
    return <Wrapper Component={ClinicianInboxView} onChange={handleChange} />;
  }

  return null;
}

type WrapperProps = {
  Component: ElementType<{ tabIndex: number }>;
  onChange: (
    event: React.SyntheticEvent,
    newValue: number,
    memberCount: number,
  ) => void;
};
const Wrapper = (props: Readonly<WrapperProps>): ReactElement => {
  const { todaysMemberCount, tabIndex, showDrawer } = useAppState(
    ({ inbox }) => {
      return {
        showDrawer: inbox.showInboxDrawer,
        tabIndex: inboxTabIndex[inbox.selectedTab],
        todaysMemberCount: inbox.todaysMemberCount,
      };
    },
  );

  const { onChange } = props;
  let countLabel = todaysMemberCount > 0 ? ` (${todaysMemberCount})` : '';
  if (todaysMemberCount > 99) countLabel = ' (99+)';
  return (
    <div
      className={classNameCombiner([
        careHubScreenStyles.inboxContainer,
        showDrawer ? careHubScreenStyles.show : '',
      ])}
      data-testId="inboxV1"
    >
      <div className={styles.inboxTabs}>
        <Tabs
          classes={{
            flexContainer: styles.inboxHeaderLayout,
            indicator: styles.indicator,
            root: styles.inboxHeaderTabs,
          }}
          value={tabIndex}
          variant="fullWidth"
          onChange={(event, value) => onChange(event, value, todaysMemberCount)}
        >
          <Tab
            className={styles.inboxChildTab}
            label={`Today's Members${countLabel}`}
          />
          <Tab className={styles.inboxChildTab} label="All" />
        </Tabs>
        <ErrorBoundary FallbackComponent={InboxErrorState}>
          <props.Component tabIndex={tabIndex} />
        </ErrorBoundary>
      </div>
    </div>
  );
};
