export const MuiTooltipDefault = {
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: '#474747',
        color: '#ffffff',
      },
    },
  },
};

export const MuiTooltipBlueSky = {
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: '#474747',
        color: '#ffffff',
      },
    },
  },
};

export const MuiTooltipDark = {
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: '#ffffff',
        color: '#474747',
      },
    },
  },
};
