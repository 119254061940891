import {
  ClinicalAppointmentNoteStatus,
  ClinicalAppointmentType,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { GetClinicalAppointmentsForMember_getClinicalAppointmentsForMember_appointments as Appointment } from '@headspace/carehub-graphql/dist/patients/generated/GetClinicalAppointmentsForMember';
import { ClinicalNotes } from 'app/notes-ui/pdf/ClinicalNotePDF';
import { AddAiContentButton } from 'app/sortable-table/note-header/AddAiContentButton';
import { NoteDetailHeader } from 'app/sortable-table/note-header/NoteDetailHeader';
import { useAppState } from 'app/state';
import { TransientFeatureFlag, useFeatureFlags } from 'hooks/useFeatureFlags';
import moment from 'moment';
import qs from 'query-string';
import { useHistory } from 'react-router-dom';
import { isClinicianOrSupervisor } from 'utils';
import { getTimezone } from 'utils/dateTime';

import {
  AppointmentsAndNotes,
  useAppointmentsAndNotesAPI,
} from './AppointmentsAndNotesAPIContext';
import { APPOINTMENT_NOTE_QUERYSTRING_ID } from './constants';
import { CLINICAL_APPOINTMENT, getAppointmentTypeDetails } from './utils';

export const filterAppointments = (
  appointmentsAndNotes: AppointmentsAndNotes[],
): Appointment[] => {
  return appointmentsAndNotes.filter(
    (a) => '__typename' in a && a.__typename === CLINICAL_APPOINTMENT,
  ) as Appointment[];
};

export const AppointmentNoteDetailHeader = ({
  memberId,
  onAddAIContent,
}: {
  memberId: string;
  onAddAIContent?: (note: ClinicalNotes) => Promise<void>;
}) => {
  const {
    noteDetails: { cursor: appointment, setCursor },
  } = useAppointmentsAndNotesAPI();
  const { timezone, role } = useAppState((_) => ({
    role: _.user.role,
    timezone: _.user.timezone,
  }));
  const localTimezone = getTimezone(timezone);
  const history = useHistory();

  const isClinician = isClinicianOrSupervisor(role);
  const enabledAddAiContentButton = useFeatureFlags().transientFeatureFlags[
    TransientFeatureFlag.ENABLE_CARE_HUB_AI_NOTETAKER
  ];

  const backToListHandler = () => {
    setCursor(null);
    history.push({
      search: qs.stringify({ [APPOINTMENT_NOTE_QUERYSTRING_ID]: undefined }),
    });
  };

  if (!appointment) {
    return null;
  }

  const clinicalAppt = appointment as Appointment;
  const appointmentTypeDetails = getAppointmentTypeDetails(clinicalAppt.type);
  const start = moment.tz(clinicalAppt.start, localTimezone);
  const startDate = start.format('MMM D, YYYY');
  const startTime = start.format('h:mm');
  const endTime = moment.tz(clinicalAppt.end, localTimezone).format('h:mma z');
  const showAddAiContentButton =
    isClinician &&
    enabledAddAiContentButton &&
    clinicalAppt.type === ClinicalAppointmentType.THERAPY_INTAKE &&
    (clinicalAppt.clinicalNote?.status == null ||
      clinicalAppt.clinicalNote.status === ClinicalAppointmentNoteStatus.DRAFT);

  const aiButton = showAddAiContentButton ? (
    <AddAiContentButton
      appointmentId={clinicalAppt.id}
      memberId={memberId}
      noteType={clinicalAppt.type!}
      onClick={onAddAIContent}
      appointmentEndTime={clinicalAppt.end}
    />
  ) : null;

  return (
    <NoteDetailHeader
      startDate={startDate}
      dateString={`${startTime}-${endTime}`}
      careTeamMember={clinicalAppt.clinician?.fullName}
      details={appointmentTypeDetails}
      backToListHandler={backToListHandler}
      actionButton={aiButton}
    />
  );
};
