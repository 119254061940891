import {
  field,
  FieldDefinitions,
  nonEmptyArrayField,
  stringField,
  useForm,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import {
  PsychReviewOfSystem,
  PsychReviewOfSystem_Depression,
  PsychReviewOfSystem_EatingDisorder,
  PsychReviewOfSystem_Hypomania,
  PsychReviewOfSystem_OCD,
  PsychReviewOfSystem_Panic,
  PsychReviewOfSystem_Psychosis,
  PsychReviewOfSystem_PTSD,
  PsychReviewOfSystem_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/PsychROS';
import {
  PsychiatrySectionName,
  PsychReviewOfSystemSection,
} from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import { EnumCheckboxesField } from 'app/notes-ui/forms/fields/enumCheckboxesField';
import { TextAreaField } from 'app/notes-ui/forms/fields/textAreaField';
import { Checkbox } from 'app/notes-ui/forms/form-controls/Checkbox';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import { NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD } from 'app/notes-ui/utils';
import React from 'react';
import { COLUMN_WIDTH, Columns, Grid } from 'shared-components/grid';
import { Width } from 'types/StyleTypes';

type Props = {
  appointmentId: string;
  onSubmit: (data: PsychReviewOfSystemSection) => void;
  disabled?: boolean;
  initialValue?: PsychReviewOfSystem;
};

export const fieldDefs: FieldDefinitions<PsychReviewOfSystem> = {
  appointmentId: field(),
  comments: stringField({ rules: [] }),
  depression: nonEmptyArrayField(),
  eatingDisorder: nonEmptyArrayField(),
  hypomania: nonEmptyArrayField(),
  ocd: nonEmptyArrayField(),
  panic: nonEmptyArrayField(),
  psychosis: nonEmptyArrayField(),
  ptsd: nonEmptyArrayField(),
  version: field<PsychReviewOfSystem_Version>({
    default: PsychReviewOfSystem_Version.v0,
  }),
};

export function PsychReviewOfSystemFormV2(props: Props) {
  const { appointmentId, disabled, initialValue } = props;
  const { fields, getValue } = useForm<PsychReviewOfSystem>(
    fieldDefs,
    PsychReviewOfSystem.fromPartial({
      ...initialValue,
      appointmentId,
      version: PsychReviewOfSystem_Version.v0,
    }),
    {
      delay: NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD,
      onStateChange: async () => {
        await onSubmit();
      },
    },
  );

  const onSubmit = async () => {
    if (disabled) return;

    props.onSubmit({
      data: getValue(),
      name: PsychiatrySectionName.PSYCH_ROS,
    });
  };

  const togglePsychROSNegated = () => {
    const isPsychROSNegated =
      fields.depression.value.includes(
        PsychReviewOfSystem_Depression.denies_depressive_symptoms,
      ) &&
      fields.panic.value.includes(
        PsychReviewOfSystem_Panic.denies_anxiety_or_panic_symptoms,
      ) &&
      fields.eatingDisorder.value.includes(
        PsychReviewOfSystem_EatingDisorder.denies_eating_disorder_symptoms,
      ) &&
      fields.hypomania.value.includes(
        PsychReviewOfSystem_Hypomania.denies_manic_symptoms,
      ) &&
      fields.psychosis.value.includes(
        PsychReviewOfSystem_Psychosis.denies_psychosis_symptoms,
      ) &&
      fields.ptsd.value.includes(
        PsychReviewOfSystem_PTSD.denies_experiencing_trauma_and_symptoms_of_ptsd,
      ) &&
      fields.ocd.value.includes(PsychReviewOfSystem_OCD.denies_ocd);

    if (isPsychROSNegated) {
      fields.depression.setValue([]);
      fields.panic.setValue([]);
      fields.eatingDisorder.setValue([]);
      fields.hypomania.setValue([]);
      fields.psychosis.setValue([]);
      fields.ptsd.setValue([]);
      fields.ocd.setValue([]);
    } else {
      fields.depression.setValue([
        PsychReviewOfSystem_Depression.denies_depressive_symptoms,
      ]);
      fields.panic.setValue([
        PsychReviewOfSystem_Panic.denies_anxiety_or_panic_symptoms,
      ]);
      fields.eatingDisorder.setValue([
        PsychReviewOfSystem_EatingDisorder.denies_eating_disorder_symptoms,
      ]);
      fields.hypomania.setValue([
        PsychReviewOfSystem_Hypomania.denies_manic_symptoms,
      ]);
      fields.psychosis.setValue([
        PsychReviewOfSystem_Psychosis.denies_psychosis_symptoms,
      ]);
      fields.ptsd.setValue([
        PsychReviewOfSystem_PTSD.denies_experiencing_trauma_and_symptoms_of_ptsd,
      ]);
      fields.ocd.setValue([PsychReviewOfSystem_OCD.denies_ocd]);
    }
  };

  return (
    <div data-testid="psychRos">
      <div className={styles.marginNotEndorsing}>
        <Checkbox
          label="Negative psych ROS"
          checked={
            fields.depression.value.includes(
              PsychReviewOfSystem_Depression.denies_depressive_symptoms,
            ) &&
            fields.panic.value.includes(
              PsychReviewOfSystem_Panic.denies_anxiety_or_panic_symptoms,
            ) &&
            fields.eatingDisorder.value.includes(
              PsychReviewOfSystem_EatingDisorder.denies_eating_disorder_symptoms,
            ) &&
            fields.hypomania.value.includes(
              PsychReviewOfSystem_Hypomania.denies_manic_symptoms,
            ) &&
            fields.psychosis.value.includes(
              PsychReviewOfSystem_Psychosis.denies_psychosis_symptoms,
            ) &&
            fields.ptsd.value.includes(
              PsychReviewOfSystem_PTSD.denies_experiencing_trauma_and_symptoms_of_ptsd,
            ) &&
            fields.ocd.value.includes(PsychReviewOfSystem_OCD.denies_ocd)
          }
          onChange={togglePsychROSNegated}
        />
      </div>
      <Grid>
        <Columns
          className={styles.gridColumn}
          widths={[COLUMN_WIDTH.HALF, COLUMN_WIDTH.HALF]}
        >
          <>
            <EnumCheckboxesField
              disabled={disabled}
              testId="depression"
              label="Depression"
              options={PsychReviewOfSystem_Depression}
              field={fields.depression}
              keyLabels={{
                denies_depressive_symptoms: 'Not Endorsing depressive symptoms',
                denies_low_mood: 'Altered mood',
                low_appetite: 'Appetite changes',
                low_energy: 'Altered level of energy',
                poor_sleep: 'Altered sleep',
              }}
            />
            <EnumCheckboxesField
              disabled={disabled}
              testId="panic"
              label="Anxiety / panic"
              options={PsychReviewOfSystem_Panic}
              field={fields.panic}
              keyLabels={{
                denies_anxiety_or_panic_symptoms:
                  'Not Endorsing anxiety/panic symptoms',
                denies_constant_worry: 'Constant or uncontrollable worry',
                spontaneous_panic_attacks:
                  'Spontaneous panic attacks or triggered anxiety attacks',
              }}
            />

            <EnumCheckboxesField
              disabled={disabled}
              testId="eatingDisorder"
              label="Eating Disorder"
              options={PsychReviewOfSystem_EatingDisorder}
              field={fields.eatingDisorder}
              keyLabels={{
                denies_eating_disorder_symptoms:
                  'Not Endorsing eating disorder symptoms',
              }}
            />
          </>
          <>
            <EnumCheckboxesField
              disabled={disabled}
              testId="hypomania"
              label="(Hypo)mania"
              options={PsychReviewOfSystem_Hypomania}
              field={fields.hypomania}
              keyLabels={{
                denies_any_decreased_need_for_sleep:
                  'Diminished need for sleep',
                denies_manic_symptoms: 'Not Endorsing mania/hypomania symptoms',
                grandiosity_lasting_one_week_or_longer: 'Grandiosity',
              }}
            />
            <EnumCheckboxesField
              disabled={disabled}
              testId="psychosis"
              label="Psychosis"
              options={PsychReviewOfSystem_Psychosis}
              field={fields.psychosis}
              keyLabels={{
                delusional_thinking_observed: 'Delusions endorsed',
                denies_avh: 'Perceptual disturbance',
                denies_psychosis_symptoms:
                  'Not Endorsing symptoms of psychosis',
                paranoid_ideation: 'Paranoid thoughts',
              }}
            />
            <EnumCheckboxesField
              disabled={disabled}
              testId="ptsd"
              label="PTSD"
              options={PsychReviewOfSystem_PTSD}
              field={fields.ptsd}
              keyLabels={{
                denies_experiencing_trauma_and_symptoms_of_ptsd:
                  'Not Endorsing symptoms of PTSD',
                history_of_trauma_associated_with_current_hypervigilance_and_pervasive_distrust:
                  'Current hypervigilance or easy startle',
              }}
            />
            <EnumCheckboxesField
              disabled={disabled}
              testId="ocd"
              label="OCD"
              options={PsychReviewOfSystem_OCD}
              field={fields.ocd}
              keyLabels={{ denies_ocd: 'Not Endorsing symptoms of OCD' }}
            />
          </>
        </Columns>
      </Grid>
      <TextAreaField
        testId="comments"
        disabled={disabled}
        label="Comments on psych ROS"
        optional={true}
        placeholder="Type here..."
        field={fields.comments}
        width={Width.FULL}
        autoExpand={true}
        rows={1}
      />
    </div>
  );
}

export const validate = (data: PsychReviewOfSystem | null) =>
  data !== null && validateForm(data, fieldDefs);
