import { Field } from '@ginger.io/react-use-form/dist/types';
import { Frequency_Period } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/Frequency';
import { FrequencyInput } from 'app/notes-ui/forms/form-controls/FrequencyInput';
import React from 'react';

type Props = {
  disabled?: boolean;
  periodField: Field<Frequency_Period>;
  testId?: string;
  timesPerPeriodField: Field<number>;
};

export function FrequencyField(props: Readonly<Props>) {
  const { timesPerPeriodField, periodField, disabled, testId } = props;
  return (
    <FrequencyInput
      disabled={disabled}
      value={{
        period: periodField.value,
        timesPerPeriod: timesPerPeriodField.value,
      }}
      name={testId}
      error={periodField.error ?? timesPerPeriodField.error}
      onChange={(value) => {
        periodField.setValue(value.period);
        timesPerPeriodField.setValue(value.timesPerPeriod);
      }}
    />
  );
}
