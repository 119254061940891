import {
  booleanField,
  field,
  FieldDefinitions,
  nonEmptyArrayField,
  stringField,
  useForm,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import {
  SocialHistory,
  SocialHistory_ChildHoodAbuse,
  SocialHistory_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/SocialHistory';
import {
  PsychiatrySectionName,
  SocialHistorySection,
} from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import { EnumCheckboxesField } from 'app/notes-ui/forms/fields/enumCheckboxesField';
import { TextAreaField } from 'app/notes-ui/forms/fields/textAreaField';
import { YesOrNoField } from 'app/notes-ui/forms/fields/yesOrNoField';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import { isEmpty } from 'app/notes-ui/shared/ValidationRules';
import { NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD } from 'app/notes-ui/utils';
import React from 'react';
import { Width } from 'types/StyleTypes';

import { Labels } from './constants';

type Props = {
  appointmentId: string;
  onSubmit: (data: SocialHistorySection) => void;
  disabled?: boolean;
  initialValue?: SocialHistory;
};

export function requiredIfMemberHasAccessToGuns(
  value: string,
  state: SocialHistory,
): string | undefined {
  if (state.accessToGuns?.isPresent && isEmpty(value)) {
    return 'This field is required';
  }
  return undefined;
}

export function requiredIfMemberHasLegalStressors(
  value: string,
  state: SocialHistory,
): string | undefined {
  if (state.memberHadLegalStressor && isEmpty(value)) {
    return 'This field is required';
  }
  return undefined;
}

export const fieldDefs: FieldDefinitions<SocialHistory> = {
  accessToGuns: {
    description: stringField({ rules: [requiredIfMemberHasAccessToGuns] }),
    isPresent: field(),
  },
  appointmentId: field(),
  childAbuseExperiences: nonEmptyArrayField(),
  childhood: {
    childAbuse: {
      comments: stringField({ rules: [] }),
      emotional: booleanField({ default: false, rules: [] }),
      physical: booleanField({ default: false, rules: [] }),
      sexual: booleanField({ default: false, rules: [] }),
    },
    describesChildhoodAs: stringField(),
    developmentalHistory: stringField(),
    grewUpIn: stringField({ rules: [] }),
  },
  culturalConsiderations: stringField(),
  education: stringField(),
  employment: stringField(),
  family: {
    additionalSocialFamilyHistoryComments: stringField({ rules: [] }),
    history: stringField(),
  },
  familySupport: stringField(),
  historyOfDomesticViolence: {
    description: stringField({ rules: [] }),
    isPresent: field(),
  },
  legalStressor: stringField({ rules: [requiredIfMemberHasLegalStressors] }),
  livingSituation: stringField(),
  memberHadLegalStressor: field(),
  version: field<SocialHistory_Version>({ default: SocialHistory_Version.v0 }),
};

export function SocialHistoryFormV2(props: Props) {
  const { appointmentId, disabled, initialValue } = props;
  const { fields, getValue } = useForm<SocialHistory>(
    fieldDefs,
    {
      ...initialValue,
      appointmentId,
      version: SocialHistory_Version.v0,
    } as SocialHistory,
    {
      delay: NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD,
      onStateChange: async () => {
        await onSubmit();
      },
    },
  );

  const onSubmit = async () => {
    if (disabled) return;

    props.onSubmit({
      data: getValue(),
      name: PsychiatrySectionName.SOCIAL_HISTORY,
    });
  };

  return (
    <div>
      <div className={styles.formGroupV2}>
        <TextAreaField
          disabled={disabled}
          testId={Labels.family.id}
          label={Labels.family.label}
          field={fields.family.history}
          subtext={Labels.family.description}
          placeholder={Labels.family.placeholder}
          width={Width.FULL}
          rows={1}
          autoExpand={true}
        />

        <TextAreaField
          disabled={disabled}
          testId={Labels.childhood.id}
          label={Labels.childhood.label}
          field={fields.childhood.describesChildhoodAs}
          subtext={Labels.childhood.description}
          placeholder={Labels.childhood.placeholder}
          width={Width.FULL}
          rows={1}
          autoExpand={true}
        />

        <TextAreaField
          disabled={disabled}
          testId={Labels.developmentHistory.id}
          label={Labels.developmentHistory.label}
          field={fields.childhood.developmentalHistory}
          placeholder={Labels.developmentHistory.placeholder}
          width={Width.FULL}
          rows={1}
          autoExpand={true}
        />

        <EnumCheckboxesField
          disabled={disabled}
          testId={Labels.childhoodAbuse.id}
          label={Labels.childhoodAbuse.label}
          options={SocialHistory_ChildHoodAbuse}
          field={fields.childAbuseExperiences}
          keyLabels={{
            denies_endorsing_childhood_abuse: Labels.childhoodAbuse.deniesLabel,
          }}
        />
        <TextAreaField
          disabled={disabled}
          testId={Labels.childhoodAbuse.commentId}
          placeholder={Labels.historyOfDomesticViolence.placeholder}
          field={fields.childhood.childAbuse.comments}
          width={Width.FULL}
          rows={1}
          className={styles.otherTextField}
          autoExpand={true}
        />

        <div className={styles.formGroupV2}>
          <YesOrNoField
            disabled={disabled}
            testId={Labels.historyOfDomesticViolence.id}
            field={fields.historyOfDomesticViolence.isPresent}
            label={Labels.historyOfDomesticViolence.label}
            fullWidth={true}
            yesLabel="Endorsed"
            noLabel="Denied"
          />
          <TextAreaField
            disabled={disabled}
            testId={Labels.historyOfDomesticViolence.id2}
            placeholder={Labels.historyOfDomesticViolence.placeholder}
            field={fields.historyOfDomesticViolence.description}
            width={Width.FULL}
            rows={1}
            autoExpand={true}
          />
        </div>

        <TextAreaField
          disabled={disabled}
          testId={Labels.culturalConsiderations.id}
          label={Labels.culturalConsiderations.label}
          field={fields.culturalConsiderations}
          placeholder={Labels.culturalConsiderations.placeholder}
          width={Width.FULL}
          rows={1}
          autoExpand={true}
        />

        <TextAreaField
          disabled={disabled}
          testId={Labels.relationships.id}
          label={Labels.relationships.label}
          field={fields.familySupport}
          placeholder={Labels.relationships.placeholder}
          width={Width.FULL}
          rows={1}
          autoExpand={true}
        />

        <TextAreaField
          disabled={disabled}
          testId={Labels.livingSituation.id}
          label={Labels.livingSituation.label}
          field={fields.livingSituation}
          placeholder={Labels.livingSituation.placeholder}
          width={Width.FULL}
          rows={1}
          autoExpand={true}
        />

        <TextAreaField
          disabled={disabled}
          testId={Labels.education.id}
          label={Labels.education.label}
          field={fields.education}
          placeholder={Labels.education.placeholder}
          width={Width.FULL}
          rows={1}
          autoExpand={true}
        />

        <TextAreaField
          disabled={disabled}
          testId={Labels.employment.id}
          label={Labels.employment.label}
          field={fields.employment}
          placeholder={Labels.employment.placeholder}
          width={Width.FULL}
          rows={1}
          autoExpand={true}
        />

        <div className={styles.formGroupV2}>
          <YesOrNoField
            disabled={disabled}
            testId={Labels.accessToGuns.id}
            field={fields.accessToGuns.isPresent}
            label={Labels.accessToGuns.label}
            fullWidth={true}
          />
        </div>
        {fields.accessToGuns.isPresent.value && (
          <div className={styles.formGroupV2}>
            <TextAreaField
              disabled={disabled}
              testId={Labels.accessToGuns.id}
              label=""
              placeholder="Type here..."
              field={fields.accessToGuns.description}
              rows={1}
              className={styles.otherTextField}
              autoExpand={true}
              width={Width.FULL}
            />
          </div>
        )}

        <div className={styles.formGroupV2}>
          <YesOrNoField
            disabled={disabled}
            testId={Labels.legalStressors.id}
            field={fields.memberHadLegalStressor}
            label={Labels.legalStressors.label}
            fullWidth={true}
          />
        </div>
        {(fields.memberHadLegalStressor.value ||
          fields.legalStressor.value) && (
          <div className={styles.formGroupV2}>
            <TextAreaField
              disabled={disabled}
              testId={Labels.legalStressors.commentId}
              label=""
              placeholder={Labels.legalStressors.placeholder}
              field={fields.legalStressor}
              rows={1}
              className={styles.otherTextField}
              autoExpand={true}
              width={Width.FULL}
            />
          </div>
        )}
        <TextAreaField
          disabled={disabled}
          testId={Labels.comments.id}
          label={Labels.comments.label}
          field={fields.family.additionalSocialFamilyHistoryComments}
          placeholder={Labels.comments.placeholder}
          width={Width.FULL}
          rows={1}
          optional={true}
          autoExpand={true}
        />
      </div>
    </div>
  );
}

export const validate = (data: SocialHistory | null) =>
  data !== null && validateForm(data, fieldDefs);
