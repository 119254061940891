import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { CheckedRadioButtonIcon } from 'shared-components/icons/CheckedRadioButtonIcon';
import { UncheckedRadioButtonIcon } from 'shared-components/icons/UncheckedRadioButtonIcon';
import React from 'react';

import styles from './RadioButton.module.scss';

type Props<T> = {
  name: string;
  label: string;
  value: T;
  checked?: boolean;
  disabled?: boolean;
  onChange: (value: T) => void;
  'data-testid'?: string;
};

export default function RadioButton<T>({
  name,
  label,
  disabled,
  value,
  checked = false,
  onChange,
  'data-testid': testId,
}: Props<T>) {
  return (
    <FormControlLabel
      classes={{
        disabled: styles.disabled,
        label: styles.label,
        root: styles.root,
      }}
      data-testid={`${label}Label`}
      label={label}
      labelPlacement="end"
      control={
        <Radio
          name={name}
          data-testid={testId ?? `${label}Radio`}
          color="default"
          value={value}
          checked={checked}
          onChange={() => onChange(value)}
          inputProps={{ 'aria-label': label }}
          classes={{
            checked: styles.checked,
            root: styles.radioInput,
          }}
          disabled={disabled}
          icon={<UncheckedRadioButtonIcon />}
          checkedIcon={<CheckedRadioButtonIcon />}
        />
      }
    />
  );
}
