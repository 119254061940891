import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import { AiMagicIconState } from 'app/sortable-table/note-header/AiMagicIcon';
import React, { ReactNode } from 'react';
import { FormError } from 'shared-components/FormError';
import { Size } from 'types/StyleTypes';

import { Checkbox } from './Checkbox';
import styles from './CheckboxGroup.module.scss';
import { NoteFormControlLabel } from './NoteFormControlLabel';

export type Options<T extends Record<string, any>> = {
  [K in keyof T]: boolean;
};

export type Props<T> = {
  aiIconState?: AiMagicIconState;
  disabled?: boolean;
  error?: string;
  feedbackComponent?: ReactNode;
  labelSize?: Size;
  name?: string;
  onChange?: (values: Options<T>) => void;
  question: string;
  showAiIcon?: boolean;
  tooltips?: Record<string, string>;
  value: Options<T>;
};

export function CheckboxGroup<T extends Record<string, any>>(props: Props<T>) {
  const {
    tooltips = {},
    error,
    disabled,
    showAiIcon,
    aiIconState,
    feedbackComponent,
    onChange,
    question,
    value,
    name,
    labelSize,
  } = props;
  const handleChange = (field: string, checked: boolean) => {
    const updatedState: Options<T> = { ...value, [field]: checked };
    onChange && onChange(updatedState);
  };

  const Label = question ? NoteFormControlLabel : ({ ...p }) => <div {...p} />;
  return (
    <FormControl
      data-testid={name || 'checkboxGroup'}
      component="div"
      classes={{ root: styles.root }}
    >
      <Label
        size={labelSize}
        data-testid="questionLabel"
        label={question}
        showAiIcon={showAiIcon}
        aiIconState={aiIconState}
      >
        <FormGroup>
          {Object.entries(value).map(([label, checked], index) => (
            <Checkbox
              key={label}
              tooltip={tooltips[label]}
              label={label}
              checked={checked}
              testId={`answerCheckbox-${index}`}
              onChange={(isChecked) => handleChange(label, isChecked)}
              disabled={disabled}
            />
          ))}
        </FormGroup>
        {feedbackComponent}
        <FormError error={error} />
      </Label>
    </FormControl>
  );
}
