import { useLazyQuery, useQuery } from '@apollo/client';
import {
  GetDiagnosisCodes,
  GetDiagnosisCodes_getDiagnosisCodes,
} from '@headspace/carehub-graphql/dist/queries/generated/GetDiagnosisCodes';
import { getDiagnosisCodes as getDiagnosisCodesGQL } from '@headspace/carehub-graphql/dist/queries/GetDiagnosisCodes';
import { EnumOption } from 'app/notes-ui/types';
import { useLogger } from 'app/state/log/useLogger';
import { useState } from 'react';
import { buildICD10CodeEnumOptionName } from 'utils/notes';

export interface DiagnosisCodesHookState {
  diagnosisCodeOptions: EnumOption[];
  loading: boolean;
}

const MAX_RETRIES = 5;

const convertDiagnosisCodesToOptions = (
  diagnosisCodes: GetDiagnosisCodes_getDiagnosisCodes[],
) => {
  return diagnosisCodes.flatMap((diagnosisCode) =>
    diagnosisCode.vaultIds.map(
      (vaultId, idx) =>
        ({
          name: buildICD10CodeEnumOptionName(
            diagnosisCode.code,
            diagnosisCode.description,
          ),
          readonly: diagnosisCode.readonly || idx > 0,
          value: vaultId,
        } as EnumOption),
    ),
  );
};

export const useDiagnosisCodes: () => DiagnosisCodesHookState = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [diagnosisCodeOptions, setDiagnosisCodeOptions] = useState<
    EnumOption[]
  >([]);

  useQuery<GetDiagnosisCodes>(getDiagnosisCodesGQL, {
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      setDiagnosisCodeOptions(
        convertDiagnosisCodesToOptions(data.getDiagnosisCodes),
      );
      setLoading(false);
    },
  });

  return {
    diagnosisCodeOptions,
    loading,
  };
};

export interface DeprecatedDiagnosisCodesHookState {
  getDeprecatedDiagnosisCodes: () => Promise<number[]>;
}

export const useDeprecatedDiagnosisCodes: () => DeprecatedDiagnosisCodesHookState = () => {
  const logger = useLogger();
  const [getDiagnosisCodesQuery] = useLazyQuery<GetDiagnosisCodes>(
    getDiagnosisCodesGQL,
  );

  const getDeprecatedDiagnosisCodes = async () => {
    async function getDeprecatedDiagnosisCodesOrRetry(
      attempt: number = 1,
    ): Promise<number[]> {
      const result = await getDiagnosisCodesQuery();
      const diagnosisCodes = result?.data?.getDiagnosisCodes ?? [];
      if (diagnosisCodes && diagnosisCodes.length > 0) {
        return diagnosisCodes
          .filter((c) => c.readonly)
          .flatMap((c) => c.vaultIds) as number[];
      }

      const message =
        'useDeprecatedDiagnosisCodes::getDeprecatedDiagnosisCodes: Failed to retrieve diagnosis codes from API';
      if (attempt >= MAX_RETRIES) {
        logger.error(new Error(`${message} after max retries`), {
          error: result.error,
        });
        return [];
      }

      logger.warning(`${message}, retry attempt ${attempt}`, {
        error: result.error,
      });
      return getDeprecatedDiagnosisCodesOrRetry(attempt + 1);
    }

    return getDeprecatedDiagnosisCodesOrRetry();
  };

  return {
    getDeprecatedDiagnosisCodes,
  };
};
