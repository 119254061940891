import { PHQ9MAX } from 'app/patients/tabs/surveys/SurveysV3/constants';
import { useAppState } from 'app/state';
import {
  selectPHQ9ChartDropdown,
  selectPHQ9ChartHover,
} from 'app/state/amplitude/actions/surveys';
import { SkyColor, StatusCriticalColor } from 'app/theme/AppTheme';
import React, { Fragment, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'shared-components/Dropdown';
import { Size } from 'types/StyleTypes';
import { formatDateFromUTC } from 'utils/dateTime';

import { PHQ9SurveyResponse } from '../patients/tabs/surveys/SurveysV3/types';
import { LineChart, RenderTooltip, Series, YAxisOptions } from './LineChart';
import styles from './LineChart.module.scss';
import { SurveyTooltip } from './SurveyTooltip';
import { PHQ9 } from './types';

type Props = {
  disableTooltip?: boolean;
  disableDataLabel?: boolean;
  responses: PHQ9[] | PHQ9SurveyResponse[];
  onClick?: (index: number, data: PHQ9SurveyResponse) => void;
};

// We don't want timestamp to be a selectable dimension
type Dimension =
  | keyof Omit<PHQ9, 'timestamp'>
  | keyof Omit<
      PHQ9SurveyResponse,
      'timestamp' | 'insights' | 'severity' | 'summaries' | 'suicidalIdeation'
    >;

export function Phq9LineChart(props: Props) {
  const { responses, onClick } = props;
  const [dimension, setDimension] = useState<Dimension>('total');
  const defaultYaxisOption = { max: PHQ9MAX, min: 0, title: 'Score' };
  const [yaxis, setYaxis] = useState<YAxisOptions>(defaultYaxisOption);
  const timezone = useAppState((_) => _.user.timezone!);

  const dispatch = useDispatch();

  const labels = useMemo(
    () => responses.map((_) => formatDateFromUTC(_.timestamp, timezone)),
    [responses],
  );
  const data = useMemo(() => responses.map((r) => r[dimension]), [
    responses,
    dimension,
  ]);
  const distributedLabelColors: string[] = [];
  responses.forEach((r, index) => {
    if ((r as PHQ9SurveyResponse).suicidalIdeation) {
      distributedLabelColors.push(StatusCriticalColor);
    } else {
      distributedLabelColors.push(SkyColor);
    }
  });
  const series: Series[] = [{ data, name: dimension }];

  const createPhq9Tooltip = (phq9s: PHQ9[]): RenderTooltip => {
    return (props: { dataPointIndex: number }) => {
      const { dataPointIndex } = props;
      const phq9 = phq9s[dataPointIndex];
      const data: [string, number][] = [
        ['Enjoyment', phq9.enjoyment],
        ['Mood', phq9.mood],
        ['Sleep', phq9.sleep],
        ['Energy', phq9.energy],
        ['Eating', phq9.eating],
        ['Failure', phq9.failure],
        ['Concentration', phq9.concentration],
        ['Restlessness', phq9.restlessness],
        ['Self Harm', phq9.selfHarm],
      ];

      return <SurveyTooltip data={data} total={phq9.total} />;
    };
  };

  const handleClick = (dataPointIndex: number) => {
    if (onClick)
      onClick(dataPointIndex, responses[dataPointIndex] as PHQ9SurveyResponse);
  };

  return (
    <Fragment>
      <div className={styles.chartTitle}>
        <span className={styles.titleLabel}>
          <b>PHQ-9 (Depression Scale)</b> • Progress Check-in
        </span>
        <Dropdown
          size={Size.SM}
          variant="standard"
          dataTestId="PHQ-9-select"
          initialValue="total"
          options={[
            { name: 'Total Scores', value: 'total' },
            { name: 'Enjoyment', value: 'enjoyment' },
            { name: 'Mood', value: 'mood' },
            { name: 'Sleep', value: 'sleep' },
            { name: 'Energy', value: 'energy' },
            { name: 'Eating', value: 'eating' },
            { name: 'Failure', value: 'failure' },
            { name: 'Concentration', value: 'concentration' },
            { name: 'Restlessness', value: 'restlessness' },
            { name: 'Self Harm', value: 'selfHarm' },
          ]}
          onSelect={(selected) => {
            dispatch(selectPHQ9ChartDropdown({ selectedItem: selected }));
            if (selected === 'total') {
              setYaxis(defaultYaxisOption);
            } else {
              setYaxis({ max: 3, min: 0, tickAmount: 3 });
            }
            setDimension(selected);
          }}
        />
      </div>

      <LineChart
        data-testid="lineChart"
        labels={labels}
        data={series}
        tooltip={createPhq9Tooltip(responses)}
        xaxisOptions={{ title: 'Date Submitted' }}
        yaxisOptions={yaxis}
        onHover={() => {
          dispatch(selectPHQ9ChartHover({ chartName: 'Phq9LineChart' }));
        }}
        disableDataLabel={props.disableDataLabel}
        disableTooltip={props.disableTooltip}
        dataLabelColors={distributedLabelColors}
        onClick={handleClick}
      />
    </Fragment>
  );
}
