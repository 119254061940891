import React from 'react';

export interface TabPanelProps {
  children?: React.ReactNode;
  panelValue: string;
  value: string;
}

export default function TabPanel({
  children,
  panelValue,
  value,
}: TabPanelProps) {
  return (
    <div
      role="tabpanel"
      data-testid="inbox-v2-tab-panel"
      hidden={value !== panelValue}
    >
      {value === panelValue && (
        <div>
          <>{children}</>
        </div>
      )}
    </div>
  );
}
