import {
  AiNoteRatings,
  CreateAiNoteInteractionRequest,
} from 'app/notes-ui/endpoints/types';
import { healthRecordApi } from 'app/services/server/healthRecordApi';
import { ServerType } from 'app/services/server/types';

const AI_NOTES_V1 = 'v1/ai-notes';

export const aiNotesApi = healthRecordApi.injectEndpoints({
  endpoints: (builder) => ({
    createAiNoteInteraction: builder.mutation<
      void,
      CreateAiNoteInteractionRequest
    >({
      extraOptions: { server: ServerType.HealthRecord },
      query: (data) => ({
        body: data,
        method: 'POST',
        url: `${AI_NOTES_V1}/interactions`,
      }),
    }),
    submitAiNoteFeedback: builder.mutation<void, AiNoteRatings>({
      extraOptions: { server: ServerType.HealthRecord },
      query: (data) => ({
        body: data,
        method: 'POST',
        url: `${AI_NOTES_V1}/ratings`,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useSubmitAiNoteFeedbackMutation,
  useCreateAiNoteInteractionMutation,
} = aiNotesApi;
