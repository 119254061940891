import {
  arrayField,
  field,
  FieldDefinitions,
  stringField,
  useForm,
} from '@ginger.io/react-use-form';
import { BooleanOption } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanOption';
import {
  SubstanceUse,
  SubstanceUse_CurrentSubstanceUse,
  SubstanceUse_PastSubstanceUse,
  SubstanceUse_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SubstanceUse';
import {
  SubstanceAbuseSection,
  TherapyProgressSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyProgressSection';
import { Typography } from '@mui/material';
import { Checkbox } from 'app/notes-ui/forms/form-controls/Checkbox';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import { SubstanceUseContainerV2 } from 'app/notes-ui/shared/substance-abuse/SubstanceUseContainerV2';
import { useSubstanceItems } from 'app/notes-ui/shared/substance-abuse/UseSubstanceItems';
import { NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD } from 'app/notes-ui/utils';
import React, { useState } from 'react';

type Props = {
  appointmentId: string;
  initialValue?: SubstanceUse;
  readOnly?: boolean;
  onSubmit: (data: SubstanceAbuseSection) => void;
  disabled?: boolean;
};

export const formFieldDefs: FieldDefinitions<SubstanceUse> = {
  anySubstanceUsed: field<BooleanOption>({
    default: BooleanOption.yes,
    rules: [],
  }),
  appointmentId: field(),
  pastEtohOrBenzoWithdrawal: {
    description: stringField({ default: '', rules: [] }),
    isPresent: field({ rules: [] }),
  },
  pastSubstanceUseTreatment: {
    description: stringField({ default: '', rules: [] }),
    isPresent: field({ rules: [] }),
  },
  substancesCurrentlyUsed: arrayField<SubstanceUse_CurrentSubstanceUse>({
    default: [],
  }),
  substancesPreviouslyUsed: arrayField<SubstanceUse_PastSubstanceUse>({
    default: [],
  }),
  version: field<SubstanceUse_Version>({ default: SubstanceUse_Version.v0 }),
};

function getNotEndorsedStatus(value: BooleanOption | undefined) {
  return value === BooleanOption.no;
}

function getBooleanOption(value: boolean) {
  return value ? BooleanOption.no : BooleanOption.yes;
}

export function SubstanceAbuseFormV2(props: Props) {
  const { initialValue, disabled, appointmentId, onSubmit } = props;
  const currentUseRows = useSubstanceItems();

  const [notEndorsedChecked, setNotEndorsedChecked] = useState<boolean>(
    getNotEndorsedStatus(initialValue?.anySubstanceUsed),
  );

  const {
    fields: anySubstanceUseField,
    getValue: anySubstanceUseFieldValue,
  } = useForm<SubstanceUse>(
    formFieldDefs,
    { ...initialValue, version: SubstanceUse_Version.v0 } as SubstanceUse,
    {
      delay: NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD,
      onStateChange: async () => onStateChange(),
    },
  );

  const getSectionData = () => {
    const anySubstanceUsedValue = anySubstanceUseFieldValue();
    const substancesUsed = currentUseRows
      .getRows()
      .map((row) => ({ substance: row.item }));
    const section: SubstanceUse = {
      anySubstanceUsed: anySubstanceUsedValue.anySubstanceUsed,
      appointmentId,
      pastEtohOrBenzoWithdrawal: {
        description: '',
        isPresent: false,
      },
      pastSubstanceUseTreatment: {
        description: '',
        isPresent: false,
      },
      substancesCurrentlyUsed: substancesUsed,
      substancesPreviouslyUsed: [],
      version: initialValue?.version || SubstanceUse_Version.v0,
    };
    return section;
  };

  const onStateChange = async () => {
    if (disabled) return;

    const data = {
      ...getSectionData(),
      anySubstanceUsed: getBooleanOption(notEndorsedChecked),
    };
    onSubmit({
      data,
      name: TherapyProgressSectionName.SUBSTANCE_ABUSE,
    });
  };

  return (
    <>
      <Typography variant="body1" className={styles.label}>
        Does the member currently or have they in the past used any substances?
      </Typography>
      <div className={styles.formGroupV2} data-testid="currentlyUsed">
        <Checkbox
          testId="endorsedState"
          checked={notEndorsedChecked}
          disabled={disabled}
          onChange={() => {
            anySubstanceUseField.anySubstanceUsed.setValue(
              getBooleanOption(!notEndorsedChecked),
            );
            setNotEndorsedChecked((checked) => !checked);
          }}
          label="Not Endorsing any current or past substance use"
        />
        <SubstanceUseContainerV2
          includeStoppedUse={false}
          onChange={currentUseRows.onChange}
          onSubmit={onStateChange}
          initialValue={initialValue?.substancesCurrentlyUsed}
          disabled={disabled}
          notEndorsed={notEndorsedChecked}
        />
      </div>
    </>
  );
}
