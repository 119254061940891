import { ApolloClient, ApolloError } from '@apollo/client';
import {
  Metadata,
  Metadata_NoteStatus,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import { Base64 } from '@ginger.io/vault-core/dist/crypto/Base64';
import {
  VaultItem,
  VaultItem_SchemaType,
} from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/VaultItem';
import { getClinicalCareTeamGroupId } from '@ginger.io/vault-core/dist/IdHelpers';
import { VaultItemPermissions } from '@ginger.io/vault-ui';
import { VaultItemWithId } from '@ginger.io/vault-ui/src/api/VaultAPI';
import { ClinicalAppointmentType } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import {
  GetAiNoteDraft,
  GetAiNoteDraft_getPaginatedVaultItemsByTag_items,
  GetAiNoteDraftVariables,
} from '@headspace/carehub-graphql/dist/queries/generated/GetAiNoteDraft';
import { getAiNoteDraft as getAiNoteDraftQuery } from '@headspace/carehub-graphql/dist/queries/GetAIDraftNote';
import { ClinicalNotes } from 'app/notes-ui/pdf/ClinicalNotePDF';
import { AmendmentWithAuditLog } from 'app/notes-ui/shared/amendments/types';
import { decodePsychiatryIntakeNote } from 'app/vault/data/decodePsychiatryIntakeNote';
import { decodePsychiatryProgressNote } from 'app/vault/data/decodePsychiatryProgressNote';
import { decodeTherapyIntakeNote } from 'app/vault/data/decodeTherapyIntakeNote';
import { decodeTherapyProgressNote } from 'app/vault/data/decodeTherapyProgressNote';
import { toSentenceCase } from 'utils';

export async function getAiDraftNote({
  appointmentId,
  memberId,
  noteType,
  apollo,
}: {
  appointmentId: string;
  memberId: string;
  noteType: ClinicalAppointmentType;
  apollo: ApolloClient<object>;
}): Promise<ClinicalNotes | null> {
  const { data, error, errors } = await apollo.query<
    GetAiNoteDraft,
    GetAiNoteDraftVariables
  >({
    fetchPolicy: 'network-only',
    query: getAiNoteDraftQuery,
    variables: {
      groupId: await Base64.hash(getClinicalCareTeamGroupId(memberId)),
      tag: await Base64.hash(
        `appointment-${appointmentId}-${convertNoteTypeToCamelCase(
          noteType,
        )}-automatedDraft`,
      ),
    },
  });

  if (error || errors) {
    throw error ?? new ApolloError({ graphQLErrors: errors });
  }

  const items = await decodeAiNoteDraftVaultItems(
    data.getPaginatedVaultItemsByTag.items,
  );
  if (items.length === 0) return null;
  const decodedByNoteType: Record<
    ClinicalAppointmentType,
    (
      appointmentId: string,
      items: VaultItemWithId[],
      amendments: AmendmentWithAuditLog[],
    ) => ClinicalNotes
  > = {
    [ClinicalAppointmentType.THERAPY_INTAKE]: decodeTherapyIntakeNote,
    [ClinicalAppointmentType.THERAPY_PROGRESS]: decodeTherapyProgressNote,
    [ClinicalAppointmentType.PSYCHIATRY_INTAKE]: decodePsychiatryIntakeNote,
    [ClinicalAppointmentType.PSYCHIATRY_PROGRESS]: decodePsychiatryProgressNote,
  };
  return decodedByNoteType[noteType](appointmentId, items, []);
}

async function decodeAiNoteDraftVaultItems(
  data: GetAiNoteDraft_getPaginatedVaultItemsByTag_items[],
): Promise<VaultItemWithId[]> {
  const promises = data.map(async ({ encryptedItem }) => {
    const { creator, id, encryptedData } = encryptedItem;
    const item = VaultItem.decode(
      await Base64.decode(encryptedData.cipherText),
    );
    // Since we are decoding vault item generated by AI vault user.
    // We only interested in the item & id fields. The rest of the fields don't matter.
    return {
      createdAt: encryptedItem.createdAt,
      creator: {
        firstName: creator.firstName,
        id: creator.id,
        lastName: creator.lastName,
      },
      firstVersionCreator: {
        firstName: creator.firstName,
        id: creator.id,
        lastName: creator.lastName,
      },
      id,
      item,
      permissions: VaultItemPermissions.ReadOnly,
      sourceVersion: '',
      updatedAt: undefined,
    };
  });

  return Promise.all(promises);
}
function convertNoteTypeToCamelCase(noteType: ClinicalAppointmentType) {
  const [first, ...rest] = noteType.toLowerCase().split('_');
  return [first, ...rest.map(toSentenceCase)].join('');
}

export async function getAiDraftNoteMetaByMemberId({
  memberId,
  apollo,
}: {
  memberId: string;
  apollo: ApolloClient<object>;
}): Promise<Array<{ appointmentId: string; status: Metadata_NoteStatus }>> {
  const { data, error, errors } = await apollo.query<
    GetAiNoteDraft,
    GetAiNoteDraftVariables
  >({
    fetchPolicy: 'network-only',
    query: getAiNoteDraftQuery,
    variables: {
      groupId: await Base64.hash(getClinicalCareTeamGroupId(memberId)),
      tag: await Base64.hash(`member-${memberId}-automatedDraft-metadata`),
    },
  });

  if (error || errors) {
    throw error ?? new ApolloError({ graphQLErrors: errors });
  }

  const items = await decodeAiNoteDraftVaultItems(
    data.getPaginatedVaultItemsByTag.items,
  );
  return items
    .filter(({ item }) =>
      [
        VaultItem_SchemaType.vault_clinical_notes_therapy_intake_metadata,
        VaultItem_SchemaType.vault_clinical_notes_therapy_progress_metadata,
        VaultItem_SchemaType.vault_clinical_notes_psychiatry_progress_metadata,
        VaultItem_SchemaType.vault_clinical_notes_psychiatry_intake_metadata,
      ].includes(item.schemaType),
    )
    .map(({ item }) => Metadata.decode(item.data));
}
