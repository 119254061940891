import { Field } from '@ginger.io/react-use-form/dist/types';
import { TextFieldProps } from '@mui/material/TextField';
import { TextInput } from 'app/notes-ui/forms/form-controls/TextInput';
import React from 'react';
import { InputProps } from 'shared-components/form-inputs/Input';

type Props = {
  className?: string;
  disabled?: boolean;
  field: Field<string>;
  label: string;
  placeholder?: string;
  size?: TextFieldProps['size'];
  testId?: string;
  variant?: InputProps['variant'];
};

export function TextBoxField(props: Readonly<Props>) {
  const {
    label,
    placeholder,
    field,
    testId = '',
    variant = 'outlined',
    ...rest
  } = props;
  return (
    <TextInput
      {...rest}
      name={testId}
      placeholder={placeholder ?? label}
      value={field.value}
      error={field.error}
      onChange={field.setValue}
      variant={variant}
    />
  );
}
