import {
  SubstanceUse_SubstanceType,
  SubstanceUse_SubstanceUseLineItem,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SubstanceUse';
import { OnChangeHandler } from 'app/notes-ui/shared/substance-abuse/SubstanceTableRow';
import { useRef, useState } from 'react';

type SubstanceUseItems = Record<
  SubstanceUse_SubstanceType,
  SubstanceUseItem | undefined
>;
type SubstanceUseItem = {
  item: SubstanceUse_SubstanceUseLineItem;
  isChecked: boolean;
  isValid: boolean;
};

/** Custom hook that keeps the state of SubstanceRows  */
export function useSubstanceItems(): {
  isValid: boolean;
  onChange: OnChangeHandler;
  getRows: () => SubstanceUseItem[];
} {
  const items = useRef<SubstanceUseItems>({} as SubstanceUseItems);
  const [isValid, setIsValid] = useState(true);

  const onChange: OnChangeHandler = ({ item, isChecked, isValid: valid }) => {
    if (isChecked) {
      items.current[item.substanceType] = { isChecked, isValid: valid, item };
    } else {
      delete items.current[item.substanceType];
    }

    const currentItem = Object.values(items.current);

    setIsValid(
      currentItem.length === 0 ||
        currentItem.every((_) => _ !== undefined && _.isValid),
    );
  };

  return {
    getRows: () =>
      Object.values(items.current).filter(
        (_) => _ !== undefined,
      ) as SubstanceUseItem[],
    isValid,
    onChange,
  };
}
