import { Field } from '@ginger.io/react-use-form/dist/types';
import { Checkbox } from 'app/notes-ui/forms/form-controls/Checkbox';
import React from 'react';

type Props = {
  checkBoxClassName?: string;
  disabled?: boolean;
  field: Field<boolean>;
  label: string;
  rootClassName?: string;
  testId?: string;
  tooltip?: string;
};

export function CheckboxField(props: Readonly<Props>) {
  const { label, field, ...rest } = props;
  return (
    <Checkbox
      {...rest}
      key={label}
      label={label}
      checked={field.value}
      error={field.error}
      onChange={field.setValue}
    />
  );
}
