import { Field } from '@ginger.io/react-use-form/dist/types';
import { Checkbox } from 'app/notes-ui/forms/form-controls/Checkbox';
import React from 'react';

type Props<T extends number | boolean> = {
  checkedValue: T;
  disabled?: boolean;
  field: Field<T>;
  label: string;
  testId?: string;
  tooltip?: string;
  uncheckedValue: T;
};

export function EnumCheckboxField<T extends number | boolean>(
  props: Readonly<Props<T>>,
) {
  const { field, uncheckedValue, checkedValue, ...rest } = props;
  return (
    <Checkbox
      {...rest}
      checked={field.value === checkedValue}
      onChange={(checked) => {
        field.setValue(checked ? checkedValue : uncheckedValue);
      }}
    />
  );
}
