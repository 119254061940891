import {
  field,
  FieldDefinitions,
  nonEmptyArrayField,
  stringField,
  useForm,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import { BooleanOption } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanOption';
import {
  PresentingProblem,
  PresentingProblem_SymptomImpact,
  PresentingProblem_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/intake/PresentingProblem';
import {
  PresentingProblemSection,
  TherapyIntakeSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';
import { EnumCheckboxesField } from 'app/notes-ui/forms/fields/enumCheckboxesField';
import { TextAreaField } from 'app/notes-ui/forms/fields/textAreaField';
import { BooleanRadioField } from 'app/notes-ui/forms/form-controls/BooleanRadioField';
import PopulateAppointmentDurationContainer from 'app/notes-ui/populate-appointment-duration/PopulateAppointmentDurationContainer';
import { useResetFormWithValue } from 'app/notes-ui/UseResetFormWithValue';
import {
  getDefaultEnumValue,
  NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD,
} from 'app/notes-ui/utils';
import { AiMagicIconState } from 'app/sortable-table/note-header/AiMagicIcon';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import React from 'react';
import { Width } from 'types/StyleTypes';

import { Labels } from './constants';

type Props = {
  appointmentId: string;
  initialValue?: PresentingProblem;
  onSubmit: (presentingProblem: PresentingProblemSection) => void;
  disabled?: boolean;
  enabledAI?: boolean;
  aiIconState?: AiMagicIconState;
};

export const fieldDefs: FieldDefinitions<PresentingProblem> = {
  appointmentId: stringField(),
  currentTreatmentDescription: stringField({ rules: [] }),
  historyOfPresentingProblem: stringField(),
  impactOfSymptoms: nonEmptyArrayField(),
  pastMentalHealthTreatment: stringField({ rules: [] }),
  presentingProblem: stringField(),
  receivingTreatmentBeyondClinician: field<BooleanOption>({
    default: BooleanOption.not_applicable,
  }),
  symptomsReported: stringField({ rules: [] }),
  version: field<PresentingProblem_Version>({
    default: PresentingProblem_Version.v0,
  }),
};

export function PresentingProblemFormV2(props: Props) {
  const {
    appointmentId,
    initialValue,
    disabled,
    onSubmit,
    enabledAI,
    aiIconState,
  } = props;

  const form = useForm<PresentingProblem>(
    fieldDefs,
    {
      ...initialValue,
      appointmentId,
      version: PresentingProblem_Version.v0,
    } as PresentingProblem,
    {
      delay: NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD,
      onStateChange: async () => onStateChange(),
    },
  );

  const { fields, getValue } = form;
  useResetFormWithValue(initialValue, form);

  const {
    enable_zoom_duration_button: enableZoomDurationButton,
  } = useFeatureFlags().transientFeatureFlags;

  const onStateChange = async () => {
    if (disabled) return;

    onSubmit({
      data: getValue(),
      name: TherapyIntakeSectionName.PRESENTING_PROBLEM,
    });
  };

  const zoomDurationButton =
    enableZoomDurationButton && !disabled ? (
      <PopulateAppointmentDurationContainer
        field={fields.presentingProblem}
        appointmentId={appointmentId}
      />
    ) : null;

  return (
    <>
      <TextAreaField
        disabled={disabled}
        testId={Labels.presentingProblem.id}
        label={Labels.presentingProblem.label}
        field={fields.presentingProblem}
        placeholder={Labels.presentingProblem.placeholder}
        subtext={Labels.presentingProblem.description}
        width={Width.FULL}
        rows={1}
        autoExpand={true}
        button={zoomDurationButton}
        showAiIcon={enabledAI}
        aiIconState={aiIconState}
      />
      <EnumCheckboxesField
        disabled={disabled}
        testId={Labels.impactOfSymptoms.id}
        label={Labels.impactOfSymptoms.label}
        options={PresentingProblem_SymptomImpact}
        field={fields.impactOfSymptoms}
        showAiIcon={enabledAI}
        aiIconState={aiIconState}
      />
      <>
        <BooleanRadioField
          disabled={disabled}
          name={Labels.currentTreatment.id}
          label={Labels.currentTreatment.label}
          value={getDefaultEnumValue(fields.receivingTreatmentBeyondClinician)}
          onChange={fields.receivingTreatmentBeyondClinician.setValue}
          tooltip={Labels.currentTreatment.tooltip}
          excludeNotApplicable={true}
          showAiIcon={enabledAI}
          aiIconState={aiIconState}
        />

        <TextAreaField
          disabled={disabled}
          testId={Labels.additionalComments.id}
          label={Labels.additionalComments.label}
          field={fields.currentTreatmentDescription}
          width={Width.FULL}
          placeholder={Labels.additionalComments.placeholder}
          rows={1}
          optional={true}
          autoExpand={true}
        />

        <TextAreaField
          disabled={disabled}
          testId={Labels.pastTreatment.id}
          label={Labels.pastTreatment.label}
          field={fields.pastMentalHealthTreatment}
          width={Width.FULL}
          optional={true}
          placeholder={Labels.pastTreatment.placeholder}
          rows={1}
          autoExpand={true}
        />

        <TextAreaField
          disabled={disabled}
          testId={Labels.medicalConcerns.id}
          label={Labels.medicalConcerns.label}
          field={fields.historyOfPresentingProblem}
          width={Width.FULL}
          placeholder={Labels.medicalConcerns.placeholder}
          rows={1}
          autoExpand={true}
          showAiIcon={enabledAI}
          aiIconState={aiIconState}
        />
      </>
    </>
  );
}

export const validate = (data: PresentingProblem | null) =>
  data !== null && validateForm(data, fieldDefs);
