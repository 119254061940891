import { Loader } from 'shared-components/loader/Loader';

import styles from './styles/CareProviderInboxLoader.module.scss';

export function CareProviderInboxLoader() {
  return (
    <div data-testid="loader" className={styles.center}>
      <Loader size={10} />
    </div>
  );
}
