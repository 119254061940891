import { useLazyQuery } from '@apollo/client';
import { field, stringField, useForm } from '@ginger.io/react-use-form';
import {
  getClinicianTimeAvailableQuery,
  getMemberTherapySessionsQuery,
} from '@headspace/carehub-graphql/dist/appointments/appointmentQueries';
import {
  GetClinicianTimeAvailable,
  GetClinicianTimeAvailableVariables,
} from '@headspace/carehub-graphql/dist/appointments/generated/GetClinicianTimeAvailable';
import {
  GetMemberTherapySessions,
  GetMemberTherapySessionsVariables,
} from '@headspace/carehub-graphql/dist/appointments/generated/GetMemberTherapySessions';
import {
  GetMemberTimezoneById,
  GetMemberTimezoneById_getMember,
} from '@headspace/carehub-graphql/dist/appointments/generated/GetMemberTimezoneById';
import {
  ClinicalAppointmentRecurrenceInput,
  ClinicalAppointmentStatus,
  ClinicalAppointmentType,
  CoverageType,
  CreditCardStatus,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { Divider, Typography } from '@mui/material';
import { ClinicianSearchFieldContainer } from 'app/clinician-search/ClinicianSearchFieldContainer';
import { DateTimeRangeField } from 'app/notes-ui/forms/fields/dateTimeRangeField';
import { DropDownField } from 'app/notes-ui/forms/fields/dropDownField';
import { NumberField } from 'app/notes-ui/forms/fields/numberField';
import { TextAreaField } from 'app/notes-ui/forms/fields/textAreaField';
import { DATE_TIME_FORMAT } from 'app/notes-ui/forms/form-controls/DateTimeRangeInput';
import { NoteFormControlLabel } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { patientProfileRoute } from 'app/top-nav/Routes';
import moment from 'moment-timezone';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Size, Width } from 'types/StyleTypes';
import { formatAppointmentStatus } from 'utils';

import { ClinicianAutoCompleteType } from '../constants';
import {
  AppointmentControls,
  AvailableModalsEnum,
} from './AppointmentControls';
import styles from './AppointmentScreen.module.scss';
import { useAppointmentWarnings } from './hooks';
import { MemberSessionStats } from './MemberSessionStats';
import { ClinicalAppointmentRecurrenceIntervalOptions } from './NewAppointmentForMeForm';
import { CreateClinicalAppointmentMS } from './NewAppointmentMSContainer';
import { useMemberSessionStats } from './UseMemberSessionStats';
import { getAppointmentTypeDuration } from './utils';

type NewAppointmentMSFormType = {
  memberId: string;
  clinicianId: string;
  clinicianGroup?: string;
  type: ClinicalAppointmentType;
  appointmentStatus: ClinicalAppointmentStatus;
  start: string;
  end: string;
  details: string;
  recurrence: {
    interval: ClinicalAppointmentRecurrenceIntervalOptions;
    occurrenceCount: PositiveInt;
  };
};
export type NewAppointmentMSFormProps = {
  member: GetMemberTimezoneById_getMember;
  data: GetMemberTimezoneById;
  onClose: () => void;
  onCreate: (input: CreateClinicalAppointmentMS) => Promise<void>;
  timezone: string;
  onRecurrenceClick?: () => void;
  onRecurrenceSelect?: (
    selected: ClinicalAppointmentRecurrenceIntervalOptions,
  ) => void;
};

/**
 * Form for new appointments for clinicians
 */
export function NewAppointmentMSForm({
  member,
  timezone,
  onClose,
  onCreate,
  onRecurrenceClick,
  onRecurrenceSelect,
  data,
}: NewAppointmentMSFormProps) {
  const { modalStatus, modalActions } = useAppointmentWarnings();
  const [getClinicianTimeAvailable] = useLazyQuery<
    GetClinicianTimeAvailable,
    GetClinicianTimeAvailableVariables
  >(getClinicianTimeAvailableQuery);
  const [getMemberSessions] = useLazyQuery<
    GetMemberTherapySessions,
    GetMemberTherapySessionsVariables
  >(getMemberTherapySessionsQuery);
  const { fields, validate, getValue } = useForm<NewAppointmentMSFormType>({
    appointmentStatus: field<ClinicalAppointmentStatus>({
      default: ClinicalAppointmentStatus.Confirmed,
    }),
    clinicianGroup: stringField({ rules: [] }),
    clinicianId: stringField(),
    details: stringField({ rules: [] }),
    end: stringField(),
    memberId: stringField({ default: member?.id }),
    recurrence: {
      interval: field<ClinicalAppointmentRecurrenceIntervalOptions>({
        default: ClinicalAppointmentRecurrenceIntervalOptions.NONE,
      }),
      occurrenceCount: field({ rules: [] }),
    },
    start: stringField(),
    type: field(),
  });
  const { memberSessionStats } = useMemberSessionStats({ data, timezone });

  const buildRequestData = () => {
    const appointmentData = getValue();
    const { type, appointmentStatus, details, clinicianId } = appointmentData;
    const recurrence = (appointmentData.recurrence.interval ===
      ClinicalAppointmentRecurrenceIntervalOptions.NONE ||
    !appointmentData.recurrence.interval
      ? null
      : appointmentData.recurrence) as ClinicalAppointmentRecurrenceInput | null;
    const start = moment
      .tz(appointmentData.start, DATE_TIME_FORMAT, timezone)
      .toISOString();
    const end = moment
      .tz(appointmentData.end, DATE_TIME_FORMAT, timezone)
      .toISOString();
    const memberId = Number.parseInt(appointmentData.memberId);
    return {
      appointmentData: {
        appointmentStatus,
        details,
        end,
        memberId,
        recurrence,
        start,
        type,
      },
      clinicianId,
    };
  };

  const hasEAPSessionsLeft = (stats?: MemberSessionStats) => {
    if (
      stats?.sessionsCoveredByEmployer === undefined ||
      stats.sessionsCoveredUsed === undefined
    ) {
      return false;
    }
    if (stats.sessionsCoveredByEmployer === null) {
      return true; // unlimited sessions covered
    }
    const coveredSessions = stats.sessionsCoveredByEmployer;
    const usedSessions = stats.sessionsCoveredUsed ?? 0;
    return coveredSessions - usedSessions > 0;
  };
  const otherClinicianSelected = () => {
    const { clinicianGroup } = getValue();
    return clinicianGroup === ClinicianAutoCompleteType.OTHER_CLINICIAN;
  };

  const onSave = async () => {
    const isValid = await validate();
    if (isValid) {
      const params = buildRequestData();
      const { data } = await getClinicianTimeAvailable({
        variables: {
          input: {
            clinicianId: params.clinicianId,
            end: params.appointmentData.end,
            start: params.appointmentData.start,
          },
        },
      });
      const getMemberTherapySessions = await getMemberSessions({
        variables: { id: member?.id },
      });
      const getMemberData = getMemberTherapySessions.data?.getMember;

      if (getMemberData?.coverageDetails?.coverageType === CoverageType.EAP) {
        const hasEAPSessions = hasEAPSessionsLeft(memberSessionStats);
        modalActions.setNoEAPSessionsWarning(!hasEAPSessions);
      }

      const clinicianTimeAvailable =
        data?.getClinicianTimeAvailable?.isTimeAvailable;
      const missingCreditCard =
        getMemberData?.billing?.creditCardStatus === CreditCardStatus.MISSING;
      const isOtherClinician = otherClinicianSelected();

      modalActions.setPreviousClinicianWarning(isOtherClinician);
      modalActions.setMissingCreditCardWarning(missingCreditCard);
      modalActions.setAvailabilityWarning(!clinicianTimeAvailable);

      if (
        clinicianTimeAvailable &&
        !missingCreditCard &&
        !modalStatus.showNoEAPSessionsWarning
      ) {
        return onCreate(params);
      }
    }
  };

  const onModalConfirm = async () => {
    const params = buildRequestData();
    return onCreate(params);
  };

  const onModalClose = () => {
    modalActions.closeAll();
  };

  const activeModals = [
    {
      isOpen: modalStatus.showAvailabilityWarning,
      modal: AvailableModalsEnum.CLINICIAN_AVAILABILITY,
      onConfirm: modalActions.setAvailabilityWarning,
    },
    {
      isOpen: modalStatus.showMissingCredictCardWarning,
      modal: AvailableModalsEnum.MISSING_CREDIT_CARD,
      onConfirm: modalActions.setMissingCreditCardWarning,
    },
    {
      isOpen: modalStatus.showPreviousClinicianWarning,
      modal: AvailableModalsEnum.PREVIOUS_CLINICIAN,
      onConfirm: modalActions.setPreviousClinicianWarning,
    },
    {
      isOpen: modalStatus.showNoEAPSessionsWarning,
      modal: AvailableModalsEnum.NO_EAP_SESSIONS,
      onConfirm: modalActions.setNoEAPSessionsWarning,
    },
  ];

  return (
    <AppointmentControls
      onSave={onSave}
      onClose={onClose}
      onModalClose={onModalClose}
      onModalConfirm={onModalConfirm}
      activeModals={activeModals}
    >
      <NoteFormControlLabel label="Clinician">
        <ClinicianSearchFieldContainer
          memberId={member.id}
          clinicianIdField={fields.clinicianId}
          clinicianGroupField={fields.clinicianGroup}
        />
      </NoteFormControlLabel>
      <Divider />

      <div className={styles.controlGroup}>
        <div className={styles.member}>
          <NoteFormControlLabel label="Member">
            <NavLink
              data-testid="member-link"
              to={patientProfileRoute(member.id)}
              className={styles.memberLink}
            >
              {member.firstName} {member.lastName} ({member.id})
            </NavLink>
            <MemberSessionStats memberSessionStats={memberSessionStats} />
          </NoteFormControlLabel>
        </div>
      </div>
      <Divider />

      <div className={styles.flex}>
        <div className={styles.controlGroup}>
          <NoteFormControlLabel label="Appt Type">
            <DropDownField
              field={fields.type}
              dropdownProps={{
                className: styles.input,
                dataTestId: 'appointmentType',
                options: [
                  {
                    name: 'Therapy Intake',
                    value: ClinicalAppointmentType.THERAPY_INTAKE,
                  },
                  {
                    name: 'Therapy Follow-up',
                    value: ClinicalAppointmentType.THERAPY_PROGRESS,
                  },
                  {
                    name: 'Psychiatry Intake',
                    value: ClinicalAppointmentType.PSYCHIATRY_INTAKE,
                  },
                  {
                    name: 'Psychiatry Follow-up',
                    value: ClinicalAppointmentType.PSYCHIATRY_PROGRESS,
                  },
                ],
                placeholder: 'Appt Type',
                size: Size.MD,
              }}
            />
          </NoteFormControlLabel>
        </div>

        <div className={styles.controlGroup}>
          <NoteFormControlLabel label="Appt Status">
            <DropDownField
              field={fields.appointmentStatus}
              dropdownProps={{
                dataTestId: 'appointmentStatus',
                options: [
                  {
                    name: formatAppointmentStatus(
                      ClinicalAppointmentStatus.Confirmed,
                    ),
                    value: ClinicalAppointmentStatus.Confirmed,
                  },
                  {
                    name: formatAppointmentStatus(
                      ClinicalAppointmentStatus.Tentative,
                    ),
                    value: ClinicalAppointmentStatus.Tentative,
                  },
                ],
                placeholder: 'Appt Status',
                size: Size.MD,
              }}
            />
          </NoteFormControlLabel>
        </div>
      </div>

      <div className={styles.flex}>
        <DateTimeRangeField
          label="Date and Time"
          field={{ end: fields.end, start: fields.start }}
          autoEndTime={getAppointmentTypeDuration(fields.type.value)}
        >
          <Typography className={styles.timezone} variant="body1">
            {timezone}
          </Typography>
        </DateTimeRangeField>
      </div>

      <div className={styles.flex}>
        <div className={styles.marginAfter}>
          <DropDownField
            field={fields.recurrence.interval}
            onSelect={onRecurrenceSelect}
            onOpen={onRecurrenceClick}
            dropdownProps={{
              dataTestId: 'interval',
              options: [
                {
                  name: 'Does not repeat',
                  value: ClinicalAppointmentRecurrenceIntervalOptions.NONE,
                },
                {
                  name: 'Once a week',
                  value: ClinicalAppointmentRecurrenceIntervalOptions.ONE_WEEK,
                },
                {
                  name: 'Every two weeks',
                  value: ClinicalAppointmentRecurrenceIntervalOptions.TWO_WEEKS,
                },
              ],
              size: Size.MD,
            }}
          />
        </div>
        <span className={styles.formText}>ends after</span>
        <div className={styles.marginBeforeAndAfter}>
          <NumberField
            testId="occurrenceCount"
            label=""
            field={fields.recurrence.occurrenceCount}
            inputProps={{ max: 6, min: 1 }}
          />
        </div>
        <span className={styles.formText}>occurrences</span>
      </div>

      <Divider />

      <div className={styles.controlGroup}>
        <NoteFormControlLabel label="Zoom Link">
          <Typography variant="body1">
            Link will be added once the appointment is saved.
          </Typography>
        </NoteFormControlLabel>
        <TextAreaField
          label="Notes"
          field={fields.details}
          placeholder="Add note"
          testId="details"
          width={Width.FULL}
          className={styles.textArea}
        />
      </div>
    </AppointmentControls>
  );
}
