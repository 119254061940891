import {
  booleanField,
  field,
  FieldDefinitions,
  stringField,
  useForm,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import {
  SocialHistory,
  SocialHistory_ChildHoodAbuse,
  SocialHistory_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/SocialHistory';
import {
  PsychiatrySectionName,
  SocialHistorySection,
} from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import { Divider } from '@mui/material';
import { TextAreaField } from 'app/notes-ui/forms/fields/textAreaField';
import { TextBoxField } from 'app/notes-ui/forms/fields/textBoxField';
import { YesOrNoField } from 'app/notes-ui/forms/fields/yesOrNoField';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import { NoteFormControlLabel } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { SaveButton } from 'app/notes-ui/forms/form-controls/SaveButton';
import { isEmpty } from 'app/notes-ui/shared/ValidationRules';
import { AUTO_SAVE_DRAFT_DELAY } from 'app/notes-ui/utils';
import React from 'react';

type Props = {
  appointmentId: string;
  onSubmit: (data: SocialHistorySection) => void;
  updateDraftNoteState: (data: SocialHistorySection) => void;
  disabled?: boolean;
  savingNote?: boolean;
  initialValue?: SocialHistory;
};

export const fieldDefs: FieldDefinitions<SocialHistory> = {
  accessToGuns: {
    description: stringField({ rules: [optionalAccessToGuns] }),
    isPresent: booleanField(),
  },
  appointmentId: field(),
  childAbuseExperiences: field<SocialHistory_ChildHoodAbuse[]>({
    default: [],
    rules: [],
  }),
  childhood: {
    childAbuse: {
      comments: stringField({ rules: [] }),
      emotional: booleanField(),
      physical: booleanField(),
      sexual: booleanField(),
    },
    describesChildhoodAs: stringField(),
    developmentalHistory: stringField(),
    grewUpIn: stringField(),
  },
  culturalConsiderations: stringField(),
  education: stringField(),
  employment: stringField(),
  family: {
    additionalSocialFamilyHistoryComments: stringField({ rules: [] }),
    history: stringField({ rules: [] }),
  },
  familySupport: stringField(),
  historyOfDomesticViolence: {
    description: stringField({ rules: [] }),
    isPresent: booleanField(),
  },
  legalStressor: stringField(),
  livingSituation: stringField(),
  memberHadLegalStressor: booleanField({ default: false, rules: [] }),
  version: field<SocialHistory_Version>({
    default: SocialHistory_Version.undefined_version,
  }),
};

export function SocialHistoryForm(props: Props) {
  const { appointmentId, disabled, savingNote, initialValue } = props;
  const { fields, validate, getValue } = useForm<SocialHistory>(
    fieldDefs,
    { ...initialValue, appointmentId } as SocialHistory,
    {
      delay: AUTO_SAVE_DRAFT_DELAY,
      onStateChange: (data) =>
        props.updateDraftNoteState({
          data,
          name: PsychiatrySectionName.SOCIAL_HISTORY,
        }),
    },
  );

  const onSubmit = async () => {
    const isValid = await validate();
    if (isValid) {
      props.onSubmit({
        data: getValue(),
        name: PsychiatrySectionName.SOCIAL_HISTORY,
      });
    }
  };

  return (
    <div>
      <div className={styles.formGroup}>
        <h3>Childhood</h3>
        <NoteFormControlLabel label="Grew up in*">
          <TextBoxField
            disabled={disabled}
            testId="grewUpIn"
            label=""
            field={fields.childhood.grewUpIn}
          />
        </NoteFormControlLabel>
        <NoteFormControlLabel label="Describes childhood as*">
          <TextAreaField
            disabled={disabled}
            testId="describesChildhoodAs"
            label=""
            field={fields.childhood.describesChildhoodAs}
          />
        </NoteFormControlLabel>
        <NoteFormControlLabel label="Childhood abuse*">
          <YesOrNoField
            disabled={disabled}
            testId="childAbusePhysical"
            label="Physical"
            field={fields.childhood.childAbuse.physical}
          />
          <YesOrNoField
            disabled={disabled}
            testId="childAbuseEmotional"
            label="Emotional"
            field={fields.childhood.childAbuse.emotional}
          />
          <YesOrNoField
            disabled={disabled}
            testId="childAbuseSexual"
            label="Sexual"
            field={fields.childhood.childAbuse.sexual}
          />
          <TextAreaField
            testId="childAbuseComment"
            disabled={disabled}
            label=""
            placeholder="Comment (optional)"
            field={fields.childhood.childAbuse.comments}
          />
        </NoteFormControlLabel>
        <NoteFormControlLabel label="Developmental history*">
          <TextAreaField
            disabled={disabled}
            testId="developmentalHistory"
            label=""
            field={fields.childhood.developmentalHistory}
          />
        </NoteFormControlLabel>
      </div>
      <Divider />
      <div className={styles.formGroup}>
        <h3>Current</h3>
        <NoteFormControlLabel label="Relationships/family support*">
          <TextAreaField
            disabled={disabled}
            testId="familySupport"
            label=""
            field={fields.familySupport}
          />
        </NoteFormControlLabel>

        <div className={styles.formGroup}>
          <YesOrNoField
            disabled={disabled}
            testId="historyOfDomesticViolence"
            field={fields.historyOfDomesticViolence.isPresent}
            label="History of domestic violence/abuse*"
          />
          <TextAreaField
            disabled={disabled}
            testId="historyOfDomesticViolenceDescription"
            label=""
            placeholder="Comment"
            field={fields.historyOfDomesticViolence.description}
          />
        </div>

        <NoteFormControlLabel label="Current living situation*">
          <TextAreaField
            disabled={disabled}
            testId="livingSituation"
            label=""
            field={fields.livingSituation}
          />
        </NoteFormControlLabel>

        <NoteFormControlLabel label="Education*">
          <TextAreaField
            disabled={disabled}
            testId="education"
            label=""
            field={fields.education}
          />
        </NoteFormControlLabel>

        <NoteFormControlLabel label="Employment*">
          <TextAreaField
            disabled={disabled}
            testId="employment"
            label=""
            field={fields.employment}
          />
        </NoteFormControlLabel>

        <TextAreaField
          disabled={disabled}
          testId="culturalConsiderations"
          label="Cultural Considerations*"
          field={fields.culturalConsiderations}
          tooltip="Are there cultural considerations you would like to share with me that we have not yet discussed today?"
        />

        <NoteFormControlLabel label="Legal stressors*">
          <TextBoxField
            disabled={disabled}
            testId="legalStressor"
            label=""
            field={fields.legalStressor}
          />
        </NoteFormControlLabel>

        <div className={styles.formGroup}>
          <YesOrNoField
            disabled={disabled}
            testId="accessToGuns"
            field={fields.accessToGuns.isPresent}
            label="Access to guns?*"
          />
          <TextAreaField
            disabled={disabled}
            testId="accessToGunsDescription"
            label=""
            placeholder="Comments (required only for “yes” answer)"
            field={fields.accessToGuns.description}
          />
        </div>
      </div>
      <SaveButton
        isLoading={savingNote}
        disabled={disabled}
        onSubmit={onSubmit}
      />
    </div>
  );
}

function optionalAccessToGuns(
  value: string,
  safety: SocialHistory,
): string | undefined {
  if (safety.accessToGuns?.isPresent && isEmpty(value)) {
    return 'This field is required';
  }
  return undefined;
}

export const validate = (data: SocialHistory | null) =>
  data !== null && validateForm(data, fieldDefs);
