import { field, stringField, useForm } from '@ginger.io/react-use-form';
import { ClinicalAppointmentById_getClinicalAppointment } from '@headspace/carehub-graphql/dist/appointments/generated/ClinicalAppointmentById';
import {
  ClinicalAppointmentStatus,
  ClinicalAppointmentType,
  UpdateClinicalAppointmentInput,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { Divider, Link } from '@mui/material';
import {
  CANCELLED_APPOINTMENT_STATUSES,
  ClinicalCancellationReason,
} from 'app/constants';
import { DateTimeRangeField } from 'app/notes-ui/forms/fields/dateTimeRangeField';
import { DropDownField } from 'app/notes-ui/forms/fields/dropDownField';
import { TextAreaField } from 'app/notes-ui/forms/fields/textAreaField';
import { DATE_TIME_FORMAT } from 'app/notes-ui/forms/form-controls/DateTimeRangeInput';
import { NoteFormControlLabel } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { patientProfileRoute, TopLevelRoutes } from 'app/top-nav/Routes';
import moment from 'moment-timezone';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Size, Width } from 'types/StyleTypes';
import { formatCancellationReasons, formatZoomMeetingUrl } from 'utils';

import {
  AppointmentControls,
  AvailableModalsEnum,
} from './AppointmentControls';
import styles from './AppointmentScreen.module.scss';
import { useAppointmentWarnings } from './hooks';
import { MemberSessionStatsContainer } from './MemberSessionStatsContainer';
import { EmptyFormValue } from './NewEventForm';
import { UpdateAppointmentForm } from './types';
import {
  getAppointmentStatusOptions,
  isUpdatingPastAppointment,
  notCanceledRule,
  notEmptyRule,
  reformatUpdateAppointmentFormFields,
} from './utils';

export type UpdateAppointmentClinicianFormProps = {
  data: ClinicalAppointmentById_getClinicalAppointment;
  onClose: () => void;
  onUpdate: (input: UpdateClinicalAppointmentInput) => Promise<void>;
  timezone: string;
};

/**
 * Form for new appointments for clinicians
 */
export function UpdateAppointmentClinicianForm({
  data,
  timezone,
  onClose,
  onUpdate,
}: UpdateAppointmentClinicianFormProps) {
  const {
    start,
    end,
    member,
    appointmentStatus,
    type,
    zoomMeetingId,
    details,
    cancelledReason,
  } = data;
  const zoomLink = formatZoomMeetingUrl(zoomMeetingId);
  const { modalStatus, modalActions } = useAppointmentWarnings();
  const { fields, validate, getValue } = useForm<UpdateAppointmentForm>(
    {
      appointmentStatus: field({
        rules: [
          (value) => notEmptyRule(value),
          (value) => notCanceledRule(value, data),
        ],
      }),
      cancelledReason: field({
        rules: [
          (value, formData) => {
            if (
              CANCELLED_APPOINTMENT_STATUSES.includes(
                formData.appointmentStatus,
              ) &&
              value === EmptyFormValue.NONE
            ) {
              return "This field can't be empty";
            }
            return undefined;
          },
        ],
      }),
      details: stringField({ rules: [] }),
      end: stringField(),
      start: stringField(),
      type: field({
        rules: [
          (value) => {
            if (value === EmptyFormValue.NONE) {
              return "This field can't be empty";
            }
            return undefined;
          },
        ],
      }),
    },
    {
      appointmentStatus: appointmentStatus ?? EmptyFormValue.NONE,
      cancelledReason: cancelledReason ?? EmptyFormValue.NONE,
      details: details ?? '',
      end: moment.tz(end, timezone).format(DATE_TIME_FORMAT),
      start: moment.tz(start, timezone).format(DATE_TIME_FORMAT),
      type: type ?? EmptyFormValue.NONE,
    },
  );

  const onSave = async () => {
    const isValid = await validate();
    if (!isValid) return;
    const updateData = reformatUpdateAppointmentFormFields(
      getValue(),
      timezone,
    );
    const shouldShowWarning = isUpdatingPastAppointment(updateData, data);
    if (shouldShowWarning) {
      modalActions.setAppointmentInPastWarning(true);
    } else {
      await onUpdate(updateData);
    }
  };

  const onModalConfirm = async () => {
    modalActions.setAppointmentInPastWarning(false);
    await onUpdate(reformatUpdateAppointmentFormFields(getValue(), timezone));
  };

  const activeModals = [
    {
      isOpen: modalStatus.showAppointmentInPastWarning,
      modal: AvailableModalsEnum.APPOINTMENT_IN_PAST,
      onConfirm: modalActions.setAppointmentInPastWarning,
    },
  ];
  const show_cancellation_reason_field = CANCELLED_APPOINTMENT_STATUSES.includes(
    fields.appointmentStatus.value as ClinicalAppointmentStatus,
  );

  return (
    <AppointmentControls
      onSave={onSave}
      onClose={onClose}
      onModalClose={() => modalActions.setAppointmentInPastWarning(false)}
      activeModals={activeModals}
      onModalConfirm={onModalConfirm}
    >
      {member && fields.type.value && (
        <>
          <div className={styles.controlGroup}>
            <div
              className={styles.member}
              data-testid="update-appointment-clinician-form"
            >
              <NoteFormControlLabel label="Member">
                <NavLink
                  data-testid="member-link"
                  to={patientProfileRoute(member.id)}
                  className={styles.memberLink}
                >
                  {member.firstName} {member.lastName} ({member.id})
                </NavLink>
                <MemberSessionStatsContainer
                  memberId={member.id}
                  timezone={timezone}
                />
              </NoteFormControlLabel>
            </div>
          </div>

          <Divider />
        </>
      )}

      <div className={styles.flex}>
        <div className={styles.controlGroup}>
          <NoteFormControlLabel label="Appt Type">
            <DropDownField
              field={fields.type}
              dropdownProps={{
                className: styles.input,
                dataTestId: 'appointmentType',
                options: [
                  {
                    name: 'Therapy Intake',
                    value: ClinicalAppointmentType.THERAPY_INTAKE,
                  },
                  {
                    name: 'Therapy Follow-up',
                    value: ClinicalAppointmentType.THERAPY_PROGRESS,
                  },
                  {
                    name: 'Psychiatry Intake',
                    value: ClinicalAppointmentType.PSYCHIATRY_INTAKE,
                  },
                  {
                    name: 'Psychiatry Follow-up',
                    value: ClinicalAppointmentType.PSYCHIATRY_PROGRESS,
                  },
                ],
                placeholder: 'Appt Type',
                size: Size.MD,
              }}
            />
          </NoteFormControlLabel>
        </div>

        <div className={styles.controlGroup}>
          <NoteFormControlLabel label="Appt Status">
            <DropDownField
              field={fields.appointmentStatus}
              dropdownProps={{
                className: styles.input,
                dataTestId: 'appointmentStatus',
                options: getAppointmentStatusOptions(
                  show_cancellation_reason_field,
                ),
                placeholder: 'Appt Status',
                size: Size.MD,
              }}
            />
          </NoteFormControlLabel>
        </div>

        {show_cancellation_reason_field && (
          <div className={styles.controlGroup}>
            <NoteFormControlLabel label="Cancellation Reason">
              <DropDownField
                field={fields.cancelledReason}
                dropdownProps={{
                  className: styles.input,
                  dataTestId: 'cancelledReason',
                  options: [
                    {
                      name: formatCancellationReasons(
                        ClinicalCancellationReason.NOT_FEELING_WELL,
                      ),
                      value: ClinicalCancellationReason.NOT_FEELING_WELL,
                    },
                    {
                      name: formatCancellationReasons(
                        ClinicalCancellationReason.NOT_FEELING_READY_TO_START,
                      ),
                      value:
                        ClinicalCancellationReason.NOT_FEELING_READY_TO_START,
                    },
                    {
                      name: formatCancellationReasons(
                        ClinicalCancellationReason.PERSONAL_EMERGENCY,
                      ),
                      value: ClinicalCancellationReason.PERSONAL_EMERGENCY,
                    },
                    {
                      name: formatCancellationReasons(
                        ClinicalCancellationReason.SCHEDULING_CONFLICT,
                      ),
                      value: ClinicalCancellationReason.SCHEDULING_CONFLICT,
                    },
                    {
                      name: formatCancellationReasons(
                        ClinicalCancellationReason.NOT_SURE_WHAT_IT_WILL_COST,
                      ),
                      value:
                        ClinicalCancellationReason.NOT_SURE_WHAT_IT_WILL_COST,
                    },
                    {
                      name: formatCancellationReasons(
                        ClinicalCancellationReason.CAN_NOT_AFFORD_IT,
                      ),
                      value: ClinicalCancellationReason.CAN_NOT_AFFORD_IT,
                    },
                    {
                      name: formatCancellationReasons(
                        ClinicalCancellationReason.WOULD_LIKE_TO_EXPLORE_ANOTHER_OPTION,
                      ),
                      value:
                        ClinicalCancellationReason.WOULD_LIKE_TO_EXPLORE_ANOTHER_OPTION,
                    },
                    {
                      name: formatCancellationReasons(
                        ClinicalCancellationReason.FEELS_BETTER,
                      ),
                      value: ClinicalCancellationReason.FEELS_BETTER,
                    },
                    {
                      name: formatCancellationReasons(
                        ClinicalCancellationReason.FOUND_HELP_OUTSIDE_GINGER,
                      ),
                      value:
                        ClinicalCancellationReason.FOUND_HELP_OUTSIDE_GINGER,
                    },
                    {
                      name: formatCancellationReasons(
                        ClinicalCancellationReason.SOMETHING_ELSE,
                      ),
                      value: ClinicalCancellationReason.SOMETHING_ELSE,
                    },
                  ],
                  placeholder: 'Cancellation Reason',
                  size: Size.MD,
                }}
              />
            </NoteFormControlLabel>
          </div>
        )}
      </div>

      <div className={styles.flex}>
        <DateTimeRangeField
          label="Date and Time"
          field={{ end: fields.end, start: fields.start }}
        >
          <div className={styles.timezone}>
            <NavLink to={TopLevelRoutes.SETTINGS}>{timezone}</NavLink>
          </div>
        </DateTimeRangeField>
      </div>

      <Divider />

      <div className={styles.controlGroup}>
        <NoteFormControlLabel label="Zoom Link">
          <Link href={zoomLink}>{zoomLink}</Link>
        </NoteFormControlLabel>
        <TextAreaField
          label="Notes"
          field={fields.details}
          placeholder="Add note"
          testId="details"
          width={Width.FULL}
          className={styles.textArea}
        />
      </div>
    </AppointmentControls>
  );
}
