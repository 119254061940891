import { Field } from '@ginger.io/react-use-form/dist/types';
import { NoteFormControlLabelStyles } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import {
  Option,
  RadioButtonGroup,
  RadioListOrientations,
} from 'app/notes-ui/forms/form-controls/RadioButtonGroup';
import { ProtobufEnum } from 'app/notes-ui/types';
import React, { useMemo } from 'react';
import { enumToOptions } from 'utils/notes';

type Props<T> = {
  disabled?: boolean;
  field: Field<T>;
  formControlStyles?: NoteFormControlLabelStyles;
  formatSelectedValue?: (value: T) => string;
  keyLabels?: Record<string, string>;
  keysToExclude?: string[];
  label: string;
  onChange?: (value: T) => void;
  options: ProtobufEnum;
  radioListOrientation?: RadioListOrientations;
  showSelectedOnly?: boolean;
  subtext?: string;
  testId?: string;
  tooltip?: string;
};

export function EnumRadioField<T>(props: Readonly<Props<T>>) {
  const {
    label,
    field,
    options,
    testId,
    keyLabels,
    keysToExclude = [],
    onChange = field.setValue,
    disabled,
    ...rest
  } = props;

  const enumOptions = useMemo(() => {
    return enumToOptions(options, keyLabels, keysToExclude).map(
      ({ name, value }) => ({
        label: name,
        value,
      }),
    );
  }, [options]);

  return (
    <RadioButtonGroup
      {...rest}
      readOnly={disabled}
      label={label}
      name={testId ?? label}
      error={field.error}
      value={field.value}
      options={(enumOptions as unknown) as Option<T>[]}
      onChange={onChange}
    />
  );
}
