import {
  booleanField,
  FieldDefinitions,
  stringField,
  useForm,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import { MedicalHealth } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/intake/MedicalHealth';
import {
  MedicalHealthSection,
  TherapyIntakeSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';

import { TextAreaField } from 'app/notes-ui/forms/fields/textAreaField';
import { YesOrNoField } from 'app/notes-ui/forms/fields/yesOrNoField';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import { SaveButton } from 'app/notes-ui/forms/form-controls/SaveButton';
import { AUTO_SAVE_DRAFT_DELAY } from 'app/notes-ui/utils';
import React from 'react';

export type Props = {
  appointmentId: string;
  onSubmit: (data: MedicalHealthSection) => void;
  updateDraftNoteState: (data: MedicalHealthSection) => void;
  initialValue?: MedicalHealth;
  disabled?: boolean;
  savingNote?: boolean;
};

export const fieldDefs: FieldDefinitions<MedicalHealth> = {
  appointmentId: stringField(),

  currentMedicalIssues: {
    description: stringField(),
  },

  currentMedication: {
    description: stringField(),
  },

  currentTreatment: {
    description: stringField({ rules: [isOptional] }),
    receivingTreatmentBeyondClinician: booleanField(),
  },

  pastTreatment: {
    description: stringField(),
  },
};

export function MedicalHealthForm(props: Props) {
  const { initialValue, disabled, appointmentId, savingNote } = props;

  const { fields, getValue, validate } = useForm<MedicalHealth>(
    fieldDefs,
    { ...initialValue, appointmentId } as MedicalHealth,
    {
      delay: AUTO_SAVE_DRAFT_DELAY,
      onStateChange: (data) =>
        props.updateDraftNoteState({
          data,
          name: TherapyIntakeSectionName.MEDICAL_HEALTH,
        }),
    },
  );

  const onSubmit = async () => {
    if (await validate()) {
      props.onSubmit({
        data: getValue(),
        name: TherapyIntakeSectionName.MEDICAL_HEALTH,
      });
    }
  };

  return (
    <>
      <div className={styles.formGroup}>
        <YesOrNoField
          disabled={disabled}
          testId="currentTreatment"
          label="Current mental health treatment in addition to what clinician is providing?*"
          field={fields.currentTreatment.receivingTreatmentBeyondClinician}
        />
      </div>
      <TextAreaField
        disabled={
          disabled ||
          !fields.currentTreatment.receivingTreatmentBeyondClinician.value
        }
        testId="additionalComments"
        label="Additional comments on current treatment*"
        field={fields.currentTreatment.description}
      />

      <TextAreaField
        disabled={disabled}
        testId="pastTreatment"
        label="Past mental health treatment (including hospitalizations)*"
        field={fields.pastTreatment.description}
      />

      <TextAreaField
        disabled={disabled}
        testId="currentMedicalIssues"
        label="Current medical issues, per client report*"
        field={fields.currentMedicalIssues.description}
      />

      <TextAreaField
        disabled={disabled}
        testId="currentMedication"
        label="Current medication and dosage, per client report*"
        field={fields.currentMedication.description}
      />
      <SaveButton
        isLoading={savingNote}
        disabled={disabled}
        onSubmit={onSubmit}
      />
    </>
  );
}

function isOptional(value: string, data: MedicalHealth): string | undefined {
  if (data.currentTreatment?.receivingTreatmentBeyondClinician && !value) {
    return 'Please add a description of treatment received beyond clinical';
  }
}

export const validate = (data: MedicalHealth | null) =>
  data !== null && validateForm(data, fieldDefs);
