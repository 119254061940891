import { FetchArgs } from '@reduxjs/toolkit/query';
import {
  TaskApiSideLoadOption,
  TASKS_BASE_URL,
  TaskType,
} from 'app/inboxV2/constants';
import { GetTasksParams, TransformedGetTasksResponse } from 'app/inboxV2/types';
import { ServerType } from 'app/services/server/types';
import { webApi } from 'app/services/server/webApi';

import { transformTaskResponseFn } from '../transformers/transformTaskResponse';
import { buildTasksUrlParameters } from '../utils';

export const getRiskTasksQueryFn = () => {
  return ({ cursor, limit, status, includes }: GetTasksParams): FetchArgs => {
    return {
      params: buildTasksUrlParameters(
        {
          cursor,
          includes: includes ?? TaskApiSideLoadOption.MEMBERS,
          limit,
          status,
        },
        TaskType.RISK_TASKS,
      ),
      responseHandler: 'json',
      url: `${TASKS_BASE_URL}`,
    };
  };
};

export const riskTasksApi = webApi.injectEndpoints({
  endpoints: (builder) => ({
    getRiskTasks: builder.query<TransformedGetTasksResponse, GetTasksParams>({
      extraOptions: { server: ServerType.Web },
      query: getRiskTasksQueryFn(),
      transformResponse: transformTaskResponseFn(),
    }),
  }),
  overrideExisting: false,
});

export const { useGetRiskTasksQuery } = riskTasksApi;
