import { InboxSectionHeaderColor } from 'app/inbox/components/InboxSection';
import { InboxSections } from 'app/inbox/types';
import { transformedGetTasksResponse } from 'app/inboxV2/__mocks__/taskApi.mocks';
import { DEFAULT_INBOX_SECTION_ITEM_LIMIT } from 'app/inboxV2/constants';
import { useGetCompletedTasksQuery } from 'app/inboxV2/endpoints/completedTasksApi';
import { SectionProps, TaskSummary } from 'app/inboxV2/types';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { InboxSectionItem } from '../InboxSectionItem';
import { InboxSectionIcon, InboxSectionWrapper } from '../InboxSectionWrapper';
import { MainContentText } from '../SectionContentItems';

export const CompletedSection: FC<SectionProps> = ({ onItemClick }) => {
  const [completedTasks, setCompletedTasks] = useState<TaskSummary[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingItems, setLoadingItems] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(
    DEFAULT_INBOX_SECTION_ITEM_LIMIT,
  );

  const {
    data: completedTasksData,
    refetch: refetchCompletedTasks,
  } = useGetCompletedTasksQuery({});

  useEffect(() => {
    setLoading(true);
    setError(false);
    try {
      setCompletedTasks(completedTasksData?.taskSummaries ?? []);
      setError(false);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [completedTasksData]);

  const handleRetryClick = useCallback(() => {
    refetchCompletedTasks();
  }, [refetchCompletedTasks]);

  const handleLoadMoreClick = useCallback(() => {
    setLoadingItems(true);

    setTimeout(() => {
      setOffset((prevOffset) => prevOffset + DEFAULT_INBOX_SECTION_ITEM_LIMIT);
      setLoadingItems(false);
    }, 500); // TODO: remove the delay
  }, []);

  const showLoadMore = useMemo(
    () => transformedGetTasksResponse.metadata.total > offset,
    [offset],
  );

  const paginatedTasks = useMemo(() => completedTasks.slice(0, offset), [
    completedTasks,
    offset,
  ]);

  return (
    <InboxSectionWrapper
      headerColor={InboxSectionHeaderColor.LIGHT_MINT}
      sectionTitle="Completed"
      section={InboxSections.COMPLETED}
      sectionIcon={InboxSectionIcon.CHECKMARK}
      sectionItemCount={completedTasks.length}
      error={error}
      loading={loading}
      onRetryClick={handleRetryClick}
      onLoadMoreClick={handleLoadMoreClick}
      loadingItems={loadingItems}
      showLoadMore={showLoadMore}
    >
      {paginatedTasks.map(({ firstName, lastName, id }) => (
        <InboxSectionItem
          key={`${firstName}-${lastName}-${id}`}
          topLeft={<MainContentText label={`${firstName} ${lastName}`} />}
          onClick={() =>
            onItemClick({ id: String(id), name: '' }, InboxSections.COMPLETED)
          }
        />
      ))}
    </InboxSectionWrapper>
  );
};
