import { booleanField, stringField, useForm } from '@ginger.io/react-use-form';
import {
  CoachClinicianCollaborationChatMessage,
  CoachClinicianCollaborationChatMessage_Author,
  CoachClinicianCollaborationChatMessage_GeneratedFrom,
  CoachClinicianCollaborationChatMessage_Version,
} from '@ginger.io/vault-care-collaboration/dist/generated/protobuf-schemas/vault-care-collaboration/CoachClinicianCollaborationChatMessage';
import { InputBase } from '@mui/material';
import { CheckboxField } from 'app/notes-ui/forms/fields/checkboxField';
import moment from 'moment';
import React, {
  ChangeEventHandler,
  KeyboardEventHandler,
  useState,
} from 'react';

import styles from './ChatTextAreaGroup.module.scss';
import { Labels } from './constants';

export interface Props {
  onSubmit: (
    chatMessage: CoachClinicianCollaborationChatMessage,
  ) => Promise<void>;
  memberId: string;
  author: CoachClinicianCollaborationChatMessage_Author;
}

export type Message = {
  body: string;
  requiresTimelyReview: boolean;
};

export const ChatTextAreaGroup = (props: Props) => {
  const { memberId, author, onSubmit } = props;
  const [input, setInput] = useState('');

  const { fields, getValue, reset } = useForm<Message>({
    body: stringField(),
    requiresTimelyReview: booleanField(),
  });

  const onInputChange: ChangeEventHandler = async (e) => {
    const { value } = e.target as HTMLInputElement;
    fields.body.setValue(value);
    setInput(value);
  };

  const onSubmitEnter: KeyboardEventHandler = async (e) => {
    e.preventDefault();
    const { body, requiresTimelyReview } = getValue();
    if (body) {
      const protobufMessage: CoachClinicianCollaborationChatMessage = {
        author,
        body,
        createdAt: moment.utc().toISOString(),
        generatedFrom:
          CoachClinicianCollaborationChatMessage_GeneratedFrom.ui_textbox,
        generatedFromVaultItemId: '',
        memberId,
        requireTimelyReview: requiresTimelyReview,
        subject: '',
        version: CoachClinicianCollaborationChatMessage_Version.v2,
      };
      await onSubmit(protobufMessage);
    }
    setInput('');
    reset();
  };

  const onEnter: KeyboardEventHandler = async (e) => {
    if (e.key === 'Enter') {
      onSubmitEnter(e);
    }
  };

  return (
    <div className={styles.chatTextareaGroup}>
      <div className={styles.textareaContainer}>
        <InputBase
          className={styles.textArea}
          multiline={true}
          value={input}
          placeholder={Labels.chatTextAreaGroup.placeholder}
          onChange={onInputChange}
          onKeyDown={onEnter}
        />
        <div className={styles.infoSection}>
          <div className={styles.label}>{Labels.chatTextAreaGroup.note}</div>
          <div className={styles.checkbox}>
            <CheckboxField
              label={Labels.chatTextAreaGroup.label}
              field={fields.requiresTimelyReview}
              disabled={false}
              tooltip={Labels.chatTextAreaGroup.tooltip}
              testId={Labels.chatTextAreaGroup.id}
              rootClassName={styles.rootCheckbox}
              checkBoxClassName={styles.checkBoxClassName}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
