export const MuiLinkDefault = {
  MuiLink: {
    styleOverrides: {
      root: {
        color: '#2875cc !important',
      },
    },
  },
};

export const MuiLinkBlueSky = {
  MuiLink: {
    styleOverrides: {
      root: {
        color: '#0061ef !important',
      },
    },
  },
};

export const MuiLinkDark = {
  MuiLink: {
    styleOverrides: {
      root: {
        color: '#00a4ff !important',
      },
    },
  },
};
