import { Field } from '@ginger.io/react-use-form/dist/types';
import { Checkbox } from 'app/notes-ui/forms/form-controls/Checkbox';
import {
  NoteFormControlLabel,
  NoteFormControlLabelStyles,
} from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { AiMagicIconState } from 'app/sortable-table/note-header/AiMagicIcon';
import React, { ReactNode } from 'react';

type Props = {
  aiIconState?: AiMagicIconState;
  description?: string;
  disabled?: boolean;
  field: Field<boolean>;
  label: string;
  showAiIcon?: boolean;
  styles?: NoteFormControlLabelStyles;
  testId?: string;
  title?: string;
  tooltip?: string;
};

export function CheckboxWithDescriptionField(props: Readonly<Props>) {
  const {
    label,
    description,
    tooltip,
    title,
    disabled,
    field,
    testId,
    styles,
    showAiIcon,
    aiIconState,
  } = props;
  const Label = title
    ? NoteFormControlLabel
    : (p: { children: ReactNode }) => (
        <div>
          {description && <p className={styles?.subtext}>{description}</p>}
          {p.children}
        </div>
      );
  return (
    <Label
      label={title}
      tooltip={tooltip}
      subtext={description}
      showAiIcon={showAiIcon}
      aiIconState={aiIconState}
    >
      <Checkbox
        key={label}
        label={label}
        checked={field.value}
        error={field.error}
        testId={testId}
        onChange={field.setValue}
        checkBoxClassName={styles?.checkbox}
        disabled={disabled}
      />
    </Label>
  );
}
