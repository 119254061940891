import { Field } from '@ginger.io/react-use-form/dist/types';
import { AutocompleteIcon } from 'app/notes-ui/forms/form-controls/AutoCompleteWithTag';
import { MultipleSelectEnumDropdown } from 'app/notes-ui/forms/form-controls/MultipleSelectEnumDropdown';
import { ProtobufEnum } from 'app/notes-ui/types';
import React from 'react';
import { Width } from 'types/StyleTypes';

type Props<T> = {
  className?: string;
  disabled?: boolean;
  field: Field<T[]>;
  icon?: AutocompleteIcon;
  label: string;
  options: ProtobufEnum;
  placeholder?: string;
  testId: string;
  width?: Width;
};

export function EnumMultiSelectDropdownField<T>(props: Readonly<Props<T>>) {
  const { testId, field, options, ...rest } = props;
  return (
    <MultipleSelectEnumDropdown
      {...rest}
      value={field.value}
      name={testId}
      type={options}
      error={field.error}
      onChange={field.setValue}
    />
  );
}
