import {
  TaskStatus,
  UserRole,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import {
  GetCompletedTasksParams,
  GetTasksParams,
  InboxVersion,
} from 'app/inboxV2/types';
import { subtractHoursFromDate } from 'utils/dateTime';

import {
  DEFAULT_COMPLETED_TASKS_SINCE_HOURS,
  DEFAULT_INBOX_SECTION_ITEM_LIMIT,
  TaskApiParam,
  TaskType,
} from './constants';
import { RiskFilter } from './enums';

export const isInboxV2Enabled = ({
  userRole,
  flagEnabled,
}: {
  userRole: UserRole | null;
  flagEnabled: boolean;
}): boolean => {
  const isCoach =
    userRole === UserRole.COACH || userRole === UserRole.COACH_SUPERVISOR;
  return flagEnabled && isCoach;
};

export const getInboxVersion = ({
  userRole,
  flagEnabled,
}: {
  userRole: UserRole | null;
  flagEnabled: boolean;
}): InboxVersion => {
  return isInboxV2Enabled({ flagEnabled, userRole }) ? 'v2' : 'v1';
};

export const buildTasksUrlParameters = (
  taskParams: GetTasksParams & GetCompletedTasksParams,
  taskType: TaskType,
) => {
  const { status, includes, limit, cursor, completed_since } = taskParams;
  const params = new URLSearchParams();

  switch (taskType) {
    case TaskType.RISK_TASKS:
      params.append(TaskApiParam.RISK_FILTER, RiskFilter.RISK_TASKS_ONLY);
      params.append(TaskApiParam.STATUS, status ?? TaskStatus.ACTIVE);
      break;
    case TaskType.STANDARD_TASKS:
      params.append(TaskApiParam.RISK_FILTER, RiskFilter.NON_RISK_TASKS_ONLY);
      params.append(TaskApiParam.STATUS, status ?? TaskStatus.ACTIVE);
      break;
    case TaskType.COMPLETED_TASKS:
      params.append(TaskApiParam.STATUS, TaskStatus.COMPLETED);
      params.append(
        TaskApiParam.COMPLETED_SINCE,
        completed_since?.toString() ??
          subtractHoursFromDate(
            new Date(),
            DEFAULT_COMPLETED_TASKS_SINCE_HOURS,
          ).toISOString(),
      );
      break;
    default:
  }

  if (includes) params.append(TaskApiParam.INCLUDES, includes);
  if (limit)
    params.append(
      TaskApiParam.LIMIT,
      limit.toString() ?? DEFAULT_INBOX_SECTION_ITEM_LIMIT,
    );
  if (cursor) params.append(TaskApiParam.CURSOR, cursor);

  return Object.fromEntries(params);
};
