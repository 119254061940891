import { useLogger } from 'app/state/log/useLogger';
import { useDiagnosisCodes } from 'hooks/useDiagnosisCodes';
import moment from 'moment';
import { useCallback } from 'react';

import { useUploadClinicalNoteMutation } from './clinicalNoteQaEndpoints';
import { generateClinicalNotePdf } from './generateClinicalNotePdf';
import { ClinicalNoteUploadData } from './types';

export const useUploadClinicalNoteForQaChecks = () => {
  const [uploadClinicalNote] = useUploadClinicalNoteMutation();
  const { diagnosisCodeOptions } = useDiagnosisCodes();
  const logger = useLogger();

  const uploadClinicalNoteForQaChecks = useCallback(
    async (props: ClinicalNoteUploadData) => {
      const { clinicalNote, appointmentMetadata, noteType } = props;

      try {
        const clinicalNotePdf = await generateClinicalNotePdf(
          clinicalNote,
          diagnosisCodeOptions,
          logger,
          appointmentMetadata,
          noteType,
        );

        const formData = new FormData();
        formData.append(
          'file',
          clinicalNotePdf,
          `clinical-note-${appointmentMetadata.id}.pdf`,
        );
        formData.append('signedAndLockedDate', moment.utc().toISOString());
        formData.append('appointmentId', appointmentMetadata.id);

        await uploadClinicalNote(formData).unwrap();
        logger.info(
          'useUploadClinicalNoteForQaChecks, Successfully sent clinical note for QA review',
          {
            appointmentId: appointmentMetadata.id,
            noteType,
          },
        );
      } catch (error) {
        logger.error(error, {
          appointmentId: appointmentMetadata.id,
          message:
            'useUploadClinicalNoteForQaChecks, Failed to send clinical note for QA check',
        });
      }
    },
    [uploadClinicalNote, diagnosisCodeOptions, logger],
  );

  return { uploadClinicalNoteForQaChecks };
};
