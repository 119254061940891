import { KeyboardArrowRight } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import { Collapse } from '@mui/material';
import { InboxSections } from 'app/inbox/types';
import { MouseEvent, ReactNode, useCallback, useMemo, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { classNameCombiner, toKebabCase } from 'utils';

import { EmptyInboxSection } from '../EmptyInboxSection';
import { ErrorInboxSection } from '../ErrorInboxSection';
import styles from './InboxSectionWrapper.module.scss';

export enum InboxSectionHeaderColor {
  LIGHT_ROSE = 'light-rose',
  LIGHT_CLOUD = 'light-cloud',
  LIGHT_MINT = 'light-mint',
  LIGHT_GREY = 'light-grey',
}
export enum InboxSectionIcon {
  SUICIDE_RISK = 'suicide-risk',
  NO_SUICIDE_RISK = 'no-suicide-risk',
  CHECKMARK = 'checkmark',
}

const testIdPrefix = 'inbox-v2-section';

const TEXTS = {
  loadMore: 'Load More',
  loading: 'loading...',
};

export type InboxSectionWrapperProps = {
  children: ReactNode;
  headerColor: InboxSectionHeaderColor;
  sectionIcon?: InboxSectionIcon;
  sectionTitle: string;
  section: InboxSections;
  isExpandedByDefault?: boolean;
  isCollapsible?: boolean;
  sectionItemCount?: number;
  error?: boolean;
  loading?: boolean;
  onRetryClick: () => void;
  onLoadMoreClick?: () => void;
  showLoadMore?: boolean;
  loadingItems?: boolean;
};

export const InboxSectionWrapper = ({
  children,
  sectionIcon,
  sectionTitle,
  section,
  headerColor,
  isExpandedByDefault = true,
  isCollapsible = true,
  sectionItemCount = 0,
  error = false,
  loading = false,
  onLoadMoreClick,
  showLoadMore,
  loadingItems,
  onRetryClick,
}: InboxSectionWrapperProps) => {
  const [isSectionExpanded, setIsSectionExpanded] = useState(
    isExpandedByDefault,
  );

  const dataTestId = useMemo(
    () => `${testIdPrefix}-${section?.toLowerCase()}`,
    [section],
  );

  const handleSectionHeaderClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>): void => {
      e.preventDefault();
      setIsSectionExpanded(!isSectionExpanded);
    },
    [isSectionExpanded],
  );

  const renderSectionContent = () => {
    if (error) {
      return (
        <ErrorInboxSection section={section} onButtonClick={onRetryClick} />
      );
    }

    if (loading) {
      return (
        <div className={classNameCombiner([styles.sectionLoading])}>
          <ClipLoader color="#2D2C2B" size={20} />
        </div>
      );
    }

    if (sectionItemCount > 0) {
      return children;
    }

    return <EmptyInboxSection section={section} />;
  };

  const totalCount = useMemo(
    () =>
      error
        ? 0
        : sectionItemCount > 99
        ? `${sectionItemCount}+`
        : sectionItemCount,
    [error, sectionItemCount],
  );

  return (
    <span data-testid={dataTestId}>
      <div
        data-testid={`${dataTestId}-header`}
        className={classNameCombiner([
          styles.sectionHeaderWrapper,
          section ? styles[toKebabCase(section.toLowerCase())] : '',
        ])}
      >
        <button
          data-testid={`${dataTestId}-header-button`}
          className={classNameCombiner([
            styles.sectionHeader,
            styles[headerColor],
          ])}
          onClick={handleSectionHeaderClick}
          disabled={!isCollapsible}
          type="button"
        >
          {sectionIcon && (
            <div
              className={classNameCombiner([
                styles.sectionIcon,
                styles[sectionIcon],
              ])}
            />
          )}
          <div className={styles.sectionTitle}>
            {sectionTitle}
            &nbsp;
            <PersonIcon className={styles.personIcon} />
            <span>&nbsp;•&nbsp;</span>
            {totalCount}
          </div>
          {isCollapsible && (
            <div
              className={
                isSectionExpanded
                  ? styles.expandIcon
                  : classNameCombiner([styles.expandIcon, styles.rotate90deg])
              }
            >
              <KeyboardArrowRight />
            </div>
          )}
        </button>
      </div>

      <div
        className={styles.sectionContainer}
        data-testid={`${dataTestId}-body`}
      >
        <Collapse
          in={isSectionExpanded}
          timeout={400}
          className={
            sectionTitle
              ? styles.sectionContent
              : classNameCombiner([styles.sectionContent, styles.paddingTop])
          }
        >
          {renderSectionContent()}
          {showLoadMore && (
            <button
              data-testid="load-more-btn"
              disabled={loadingItems}
              className={styles.loadMoreBtn}
              onClick={onLoadMoreClick}
              type="button"
            >
              {loadingItems ? TEXTS.loading : TEXTS.loadMore}
            </button>
          )}
        </Collapse>
      </div>
    </span>
  );
};
