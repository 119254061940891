import { BooleanOption } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/BooleanOption';
import { EatingDisorderRisk } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/EatingDisorderRisk';
import { RiskLevel } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/RiskLevel';
import DeleteIcon from '@mui/icons-material/HighlightOffRounded';
import { Typography } from '@mui/material';
import {
  RiskFormComponent,
  RiskFormProps,
  RiskType,
} from 'app/coach/coach-notes/CoachNotesTypes';
import {
  FIDO_FIELDS,
  FIDO_PLACEHOLDERS,
} from 'app/coach/coach-notes/constants';
import { CardTextAreaField } from 'app/coach/coach-notes/note-detail-view/note-fields';
import { eatingDisorderRisk } from 'app/coach/coach-notes/note-detail-view/note-templates/fieldDefs';
import styles from 'app/coach/coach-notes/note-detail-view/NoteDetailView.module.scss';
import { useRiskForm } from 'app/coach/coach-notes/useRiskForm';
import { FieldLabel } from 'app/member-chart-cards/card-field/FieldLabel';

import { EnumRadioField } from 'app/notes-ui/forms/fields/enumRadioField';
import { BooleanRadioField } from 'app/notes-ui/forms/form-controls/BooleanRadioField';
import React from 'react';
import { classNameCombiner } from 'utils';

const FieldLabels = {
  COPING_SKILLS: 'Healthy coping skills and urge control',
  DISCLOSED_TO: 'Who? *',
  DISCLOSURE:
    'Disclosure to anyone, such as a friend, family member, PCP, or therapist?',
  MOTIVATION_TO_RECOVER: 'Motivation to recover',
  OTHER_RELEVANT_INFO: 'Other relevant information',
  PURGING_BEHAVIOR: 'Purging behavior',
  RISK_LEVEL: 'Risk level outcome &',
  SOUGHT_MEDICAL_ATTENTION: 'Ever sought medical attention as a result? *',
  SUBSTANCES_USED: 'Substances used...',
  SUMMARY: 'Summarization of past substance use risk  ',
  ...FIDO_FIELDS,
};

const Placeholders = {
  COPING_SKILLS: 'Related to bingeing, restriction, other ED behaviors...',
  DISCLOSED_TO: 'Type here...',
  MOTIVATION_TO_RECOVER:
    'Cooperation, insight, and ability to control obsessive thoughts...',
  OTHER_RELEVANT_INFO: 'Location, if imminent...',
  PURGING_BEHAVIOR: 'Vomiting, laxatives, diuretics, compulsive exercise...',
  SUBSTANCES_USED: 'Type here...',
  SUMMARY: 'History, dates...',
  ...FIDO_PLACEHOLDERS,
};

const TOOLTIP_EVENT_LABEL = 'Coach Notes > Eating Disorder Risk';

export const EatingDisorderRiskForm: RiskFormComponent<EatingDisorderRisk> = (
  props: RiskFormProps<EatingDisorderRisk>,
) => {
  const {
    readOnly,
    inline,
    isRemovable,
    initialValue,
    onChange,
    removeRisk,
  } = props;
  const { fields, onRemoveRisk } = useRiskForm<EatingDisorderRisk>({
    initialValue,
    onChange,
    removeRisk,
    riskType: RiskType.EATING_DISORDER_RISK,
    validationSchema: eatingDisorderRisk,
  });

  return (
    <div className={inline ? styles.inlineRiskSection : styles.cardSection}>
      <div
        className={classNameCombiner([
          styles.riskTitleField,
          isRemovable ? styles.withButton : '',
        ])}
      >
        <Typography className={styles.riskAssessmentTitle}>
          Eating Disorder Risk
        </Typography>
        <span className={styles.inlineBorder} />
        {isRemovable && (
          <DeleteIcon
            data-testid="removeBtn"
            className={styles.removeRiskBtn}
            onClick={onRemoveRisk}
          />
        )}
      </div>

      <EnumRadioField
        label={FieldLabels.RISK_LEVEL}
        disabled={readOnly}
        options={RiskLevel}
        keysToExclude={['imminent']}
        field={fields.riskLevel}
        formControlStyles={{ label: styles.label }}
      />

      <FieldLabel
        className={`${styles.label} ${styles.groupLabel}`}
        labelText={FieldLabels.FIDO}
      />
      <div className={`${styles.helperText} ${styles.subtle}`}>
        {Placeholders.FIDO_HELPER_TEXT}
      </div>

      <CardTextAreaField
        name="eatingDisorderFrequency"
        field={fields.frequency}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.FREQUENCY}`}
        tooltipText=""
        placeholder={Placeholders.FREQUENCY}
        readOnly={readOnly}
      />

      <FieldLabel
        className={`${styles.label} ${styles.subtle}`}
        labelText={FieldLabels.INTENSITY}
      />
      <CardTextAreaField
        name="eatingDisorderIntensity"
        field={fields.intensity}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.INTENSITY}`}
        tooltipText=""
        placeholder={Placeholders.INTENSITY_EATING_DISORDER}
        readOnly={readOnly}
      />

      <FieldLabel
        className={`${styles.label} ${styles.subtle}`}
        labelText={FieldLabels.DURATION}
      />
      <CardTextAreaField
        name="eatingDisorderDuration"
        field={fields.duration}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.DURATION}`}
        tooltipText=""
        placeholder={Placeholders.DURATION}
        readOnly={readOnly}
      />

      <FieldLabel
        className={`${styles.label} ${styles.subtle}`}
        labelText={FieldLabels.ONSET}
      />
      <CardTextAreaField
        name="eatingDisorderOnSet"
        field={fields.onset}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.ONSET}`}
        tooltipText=""
        placeholder={Placeholders.ONSET}
        readOnly={readOnly}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.PURGING_BEHAVIOR}
      />
      <CardTextAreaField
        name="eatingDisorderPurgingBehavior"
        field={fields.purgingBehavior}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.PURGING_BEHAVIOR}`}
        tooltipText=""
        placeholder={Placeholders.PURGING_BEHAVIOR}
        readOnly={readOnly}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.MOTIVATION_TO_RECOVER}
      />
      <CardTextAreaField
        name="eatingDisorderMotivationToRecover"
        field={fields.motivationToRecover}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.MOTIVATION_TO_RECOVER}`}
        tooltipText=""
        placeholder={Placeholders.MOTIVATION_TO_RECOVER}
        readOnly={readOnly}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.COPING_SKILLS}
      />
      <CardTextAreaField
        name="eatingDisorderCopingSkills"
        field={fields.copingSkills}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.COPING_SKILLS}`}
        tooltipText=""
        placeholder={Placeholders.COPING_SKILLS}
        readOnly={readOnly}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.SOUGHT_MEDICAL_ATTENTION}
      />
      <BooleanRadioField
        disabled={readOnly}
        name="EatingDisorderRiskSoughtMedicalAttention"
        value={fields.soughtMedicalAttention.value}
        onChange={fields.soughtMedicalAttention.setValue}
      />

      <FieldLabel className={styles.label} labelText={FieldLabels.DISCLOSURE} />
      <BooleanRadioField
        disabled={readOnly}
        name="eatingDisorderRiskDisclosure"
        value={fields.disclosure.value}
        onChange={fields.disclosure.setValue}
      />

      {fields.disclosure.value === BooleanOption.yes && (
        <>
          <FieldLabel
            className={styles.label}
            labelText={FieldLabels.DISCLOSED_TO}
          />
          <CardTextAreaField
            name="eatingDisorderDisclosedTo"
            field={fields.disclosedTo}
            tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.DISCLOSED_TO}`}
            tooltipText=""
            placeholder={Placeholders.DISCLOSED_TO}
            readOnly={readOnly}
          />
        </>
      )}

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.OTHER_RELEVANT_INFO}
      />
      <CardTextAreaField
        name="eatingDisorderOtherRelevantInfo"
        field={fields.otherRelevantInfo}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.OTHER_RELEVANT_INFO}`}
        tooltipText=""
        placeholder={Placeholders.OTHER_RELEVANT_INFO}
        readOnly={readOnly}
      />
    </div>
  );
};
