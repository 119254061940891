import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import MuiDialog from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { TransientFeatureFlag, useFeatureFlags } from 'hooks/useFeatureFlags';
import { NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD } from 'app/notes-ui/utils';
import { useAppState } from 'app/state';
import { State } from 'app/state/request/actions';
import React, { useEffect, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

import styles from './LockNoteModal.module.scss';

interface DialogTitleProps {
  children: React.ReactNode;
  onClose: () => void;
}

export interface Props {
  memberId: string;
  memberName: string;
  appointmentDate?: string;
  open: boolean;
  onClose: () => void;
  onNoteLocked: () => void;
}

export function DialogTitle(props: DialogTitleProps) {
  const { children, onClose } = props;
  return (
    <MuiDialogTitle className={styles.dialogTitle}>
      <span data-testid="dialogTitle" className={styles.title}>
        {children}
      </span>
      <IconButton
        aria-label="close"
        data-testid="titleCloseBtn"
        className={styles.closeButton}
        onClick={onClose}
        size="large"
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
}

export function LockNoteDialogContent(props: Omit<Props, 'open'>) {
  const { memberId, memberName, appointmentDate } = props;
  const contentText = `Once a note is signed & locked, you will not be able to make any changes. Please ensure you have the correct member${
    appointmentDate ? ' and appointment date' : ''
  } before locking.`;

  const featureFlags = useFeatureFlags();
  const enableNotesEfficiency =
    featureFlags.transientFeatureFlags[
      TransientFeatureFlag.ENABLE_CARE_HUB_NOTES_EFFICIENCY
    ];
  const enableDelaySignAndLock = enableNotesEfficiency;
  const [delaySignAndLock, setDelaySignAndLock] = useState(
    enableDelaySignAndLock,
  );
  const AUTO_SAVE_REQUEST_TIME_OF_FLIGHT = 3000;

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (enableDelaySignAndLock) {
      timeout = setTimeout(() => {
        setDelaySignAndLock(false);
      }, NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD + AUTO_SAVE_REQUEST_TIME_OF_FLIGHT);
    }

    return () => {
      if (timeout !== null) {
        clearTimeout(timeout);
      }
    };
  }, []);

  const { states } = useAppState(({ request: states }) => ({ states }));
  const requestStates = Object.values(states).filter((_) => _);
  const errors = requestStates.filter(
    (_) =>
      _?.state === State.ERROR &&
      _?.queryName.includes('createOrUpdateSection'),
  );

  // There is no way to tell if there is a pending save, so we'll interrupt if there is an error during the timeout
  //   To add that `useForm` must be updated here:
  //   https://github.com/HeadspaceMeditation/react-use-form/blob/a2f5eb4/src/useForm.tsx#L54
  if (errors.length > 0) {
    // Error
    if (delaySignAndLock) {
      props.onClose();
    }
  }

  return (
    <>
      <DialogTitle data-testid="lockNoteDialogTitle" onClose={props.onClose}>
        Are you sure you want to sign & lock note?
      </DialogTitle>
      <MuiDialogContent className={styles.dialogContent}>
        <p data-testid="contentBody" className={styles.body}>
          {contentText}
        </p>

        <p>
          Member:{' '}
          <strong>
            {memberName} ({memberId})
          </strong>
        </p>

        {appointmentDate && (
          <p>
            Appointment Date: <strong>{appointmentDate}</strong>
          </p>
        )}
      </MuiDialogContent>
      <MuiDialogActions className={styles.dialogAction}>
        <Button
          data-testid="cancelBtn"
          className={`${styles.btn} ${styles.cancelBtn}`}
          onClick={props.onClose}
          color="secondary"
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          data-testid="signLockBtn"
          onClick={props.onNoteLocked}
          className={`${styles.btn} ${styles.lockBtn}`}
          disabled={delaySignAndLock}
          variant="contained"
        >
          {delaySignAndLock ? (
            <ClipLoader color="#fff" size={12} />
          ) : (
            'Sign & Lock'
          )}
        </Button>
      </MuiDialogActions>
    </>
  );
}

export function LockNoteDialog(props: Props) {
  const { open, ...rest } = props;
  const onClose = (event: any, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (!(reason === 'backdropClick' || reason === 'escapeKeyDown')) {
      props.onClose();
    }
  };
  return (
    <MuiDialog
      data-testid="dialog"
      disableEscapeKeyDown={true}
      onClose={onClose}
      maxWidth="sm"
      aria-labelledby="Lock Note Dialog"
      open={open}
      classes={{
        container: styles.container,
        paper: styles.paper,
        root: styles.root,
      }}
    >
      <LockNoteDialogContent {...rest} />
    </MuiDialog>
  );
}
