import { InboxSections } from 'app/inbox/types';
import { useMemo } from 'react';

import styles from './ErrorInboxSection.module.scss';

interface ErrorInboxSectionProps {
  section: InboxSections;
  onButtonClick: () => void;
}

export function ErrorInboxSection({
  section,
  onButtonClick,
}: ErrorInboxSectionProps) {
  const dataTestId = useMemo(
    () => `error-inbox-section-${section.toLowerCase()}`,
    [section],
  );

  return (
    <div data-testid={dataTestId} className={styles.container}>
      <h4 className={styles.title}>Problem loading members</h4>
      <div className={styles.buttonWrapper}>
        <button
          data-testid="reload-section-btn"
          className={styles.reloadBtn}
          onClick={onButtonClick}
          type="button"
        >
          Reload
        </button>
      </div>
    </div>
  );
}
