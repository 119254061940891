import { BooleanOption } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/BooleanOption';
import { RiskLevel } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/RiskLevel';
import { SubstanceUseRisk } from '@ginger.io/vault-coach-notes/src/generated/protobuf-schemas/vault-coach-notes/SubstanceUseRisk';
import DeleteIcon from '@mui/icons-material/HighlightOffRounded';
import { Typography } from '@mui/material';
import {
  RiskFormComponent,
  RiskFormProps,
  RiskType,
} from 'app/coach/coach-notes/CoachNotesTypes';
import {
  FIDO_FIELDS,
  FIDO_PLACEHOLDERS,
} from 'app/coach/coach-notes/constants';
import { CardTextAreaField } from 'app/coach/coach-notes/note-detail-view/note-fields';
import { substanceUseRisk } from 'app/coach/coach-notes/note-detail-view/note-templates/fieldDefs';
import styles from 'app/coach/coach-notes/note-detail-view/NoteDetailView.module.scss';
import { useRiskForm } from 'app/coach/coach-notes/useRiskForm';
import { FieldLabel } from 'app/member-chart-cards/card-field/FieldLabel';

import { EnumRadioField } from 'app/notes-ui/forms/fields/enumRadioField';
import { BooleanRadioField } from 'app/notes-ui/forms/form-controls/BooleanRadioField';
import React from 'react';
import { classNameCombiner } from 'utils';

const FieldLabels = {
  DISCLOSED_TO: 'Who? *',
  DISCLOSURE:
    'Disclosure to anyone, such as a friend, family member, PCP, or therapist?',
  IMPACT: 'Impact *',
  OTHER_RELEVANT_INFO: 'Other relevant information',
  RISK_LEVEL: 'Risk level outcome *',
  SUBSTANCES_USED: 'Substances used...',
  SUMMARY: 'Summarization of past substance use risk  ',
  ...FIDO_FIELDS,
};

const Placeholders = {
  DISCLOSED_TO: 'Type here...',
  IMPACT: 'Career, relationships, health, finances, self-esteem...',
  IMPACT_HELPER_TEXT:
    'Degree of impact (positive/negative) of substance use on different areas of their life',
  IS_CONCERNED_HELPER_TEXT:
    'Are they or others in their life concerned by their use?',
  OTHER_RELEVANT_INFO: 'Location, if imminent...',
  SUBSTANCES_USED: 'Type here...',
  SUMMARY: 'History, dates...',
  ...FIDO_PLACEHOLDERS,
};

const TOOLTIP_EVENT_LABEL = 'Coach Notes > Substance Use Risk';

export const SubstanceUseRiskForm: RiskFormComponent<SubstanceUseRisk> = (
  props: RiskFormProps<SubstanceUseRisk>,
) => {
  const {
    readOnly,
    inline,
    isRemovable,
    initialValue,
    onChange,
    removeRisk,
  } = props;
  const { fields, onRemoveRisk } = useRiskForm<SubstanceUseRisk>({
    initialValue,
    onChange,
    removeRisk,
    riskType: RiskType.SUBSTANCE_USE_RISK,
    validationSchema: substanceUseRisk,
  });

  return (
    <div className={inline ? styles.inlineRiskSection : styles.cardSection}>
      <div
        className={classNameCombiner([
          styles.riskTitleField,
          isRemovable ? styles.withButton : '',
        ])}
      >
        <Typography className={styles.riskAssessmentTitle}>
          Substance Use Risk
        </Typography>
        <span className={styles.inlineBorder} />
        <DeleteIcon
          data-testid="removeBtn"
          className={styles.removeRiskBtn}
          onClick={onRemoveRisk}
        />
      </div>

      <EnumRadioField
        label={FieldLabels.RISK_LEVEL}
        disabled={readOnly}
        options={RiskLevel}
        keysToExclude={['imminent']}
        field={fields.riskLevel}
        formControlStyles={{ label: styles.label }}
      />

      <FieldLabel className={styles.label} labelText={FieldLabels.SUMMARY} />
      <CardTextAreaField
        name="substanceUseSummary"
        field={fields.summary}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.SUMMARY}`}
        tooltipText=""
        placeholder={Placeholders.SUMMARY}
        readOnly={readOnly}
      />

      <FieldLabel
        className={`${styles.label} ${styles.groupLabel}`}
        labelText={FieldLabels.FIDO}
      />
      <div className={`${styles.helperText} ${styles.subtle}`}>
        {Placeholders.FIDO_HELPER_TEXT}
      </div>

      <CardTextAreaField
        name="substanceUseFrequency"
        field={fields.frequency}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.FREQUENCY}`}
        tooltipText=""
        placeholder={Placeholders.FREQUENCY}
        readOnly={readOnly}
      />

      <FieldLabel
        className={`${styles.label} ${styles.subtle}`}
        labelText={FieldLabels.INTENSITY}
      />
      <CardTextAreaField
        name="substanceUseIntensity"
        field={fields.intensity}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.INTENSITY}`}
        tooltipText=""
        placeholder={Placeholders.INTENSITY_SUBSTANCE_USE}
        readOnly={readOnly}
      />

      <FieldLabel
        className={`${styles.label} ${styles.subtle}`}
        labelText={FieldLabels.DURATION}
      />
      <CardTextAreaField
        name="substanceUseDuration"
        field={fields.duration}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.DURATION}`}
        tooltipText=""
        placeholder={Placeholders.DURATION}
        readOnly={readOnly}
      />

      <FieldLabel
        className={`${styles.label} ${styles.subtle}`}
        labelText={FieldLabels.ONSET}
      />
      <CardTextAreaField
        name="substanceUseOnSet"
        field={fields.onset}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.ONSET}`}
        tooltipText=""
        placeholder={Placeholders.ONSET}
        readOnly={readOnly}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.SUBSTANCES_USED}
      />
      <div className={styles.helperText}>{Placeholders.SUBSTANCES_USED}</div>
      <CardTextAreaField
        name="substanceUseSubstancesUsed"
        field={fields.substancesUsed}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.SUBSTANCES_USED}`}
        tooltipText=""
        placeholder={Placeholders.SUBSTANCES_USED}
        readOnly={readOnly}
      />

      <FieldLabel className={styles.label} labelText={FieldLabels.IMPACT} />
      <div className={styles.helperText}>{Placeholders.IMPACT_HELPER_TEXT}</div>
      <CardTextAreaField
        name="substanceUseDegreeOfImpact"
        field={fields.degreeOfImpact}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.IMPACT}`}
        tooltipText=""
        placeholder={Placeholders.IMPACT}
        readOnly={readOnly}
      />

      <div className={styles.helperText}>
        {Placeholders.IS_CONCERNED_HELPER_TEXT}
      </div>
      <BooleanRadioField
        disabled={readOnly}
        name="substanceUseRiskIsConcerned"
        value={fields.isConcerned.value}
        onChange={fields.isConcerned.setValue}
      />

      <FieldLabel className={styles.label} labelText={FieldLabels.DISCLOSURE} />
      <BooleanRadioField
        disabled={readOnly}
        name="substanceUseRiskDisclosure"
        value={fields.disclosure.value}
        onChange={fields.disclosure.setValue}
      />

      {fields.disclosure.value === BooleanOption.yes && (
        <>
          <FieldLabel
            className={styles.label}
            labelText={FieldLabels.DISCLOSED_TO}
          />
          <CardTextAreaField
            name="substanceUseDisclosedTo"
            field={fields.disclosedTo}
            tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.DISCLOSED_TO}`}
            tooltipText=""
            placeholder={Placeholders.DISCLOSED_TO}
            readOnly={readOnly}
          />
        </>
      )}

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.OTHER_RELEVANT_INFO}
      />
      <CardTextAreaField
        name="substanceUseOtherRelevantInfo"
        field={fields.otherRelevantInfo}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.OTHER_RELEVANT_INFO}`}
        tooltipText=""
        placeholder={Placeholders.OTHER_RELEVANT_INFO}
        readOnly={readOnly}
      />
    </div>
  );
};
