import {
  booleanField,
  field,
  FieldDefinitions,
  nonEmptyArrayField,
  stringField,
  useForm,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import {
  MentalStatusExam,
  MentalStatusExam_Association,
  MentalStatusExam_Orientation,
  MentalStatusExam_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/MentalStatusExam';
import { Affect_Scope } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Affect';
import { Insight } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Insight';
import { Judgment } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Judgment';
import { Mood } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Mood';
import { Speech } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Speech';
import { ThoughtContent } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/ThoughtContent';
import { ThoughtProcess } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/ThoughtProcess';
import { BooleanWithComments } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanWithComments';
import {
  MentalStatusExamSection,
  PsychiatrySectionName,
} from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import { EnumCheckboxesField } from 'app/notes-ui/forms/fields/enumCheckboxesField';
import { EnumRadioField } from 'app/notes-ui/forms/fields/enumRadioField';
import { TextBoxField } from 'app/notes-ui/forms/fields/textBoxField';
import { YesOrNoField } from 'app/notes-ui/forms/fields/yesOrNoField';
import { NoteFormControlLabel } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { SaveButton } from 'app/notes-ui/forms/form-controls/SaveButton';
import { isEmpty, optionalField } from 'app/notes-ui/shared/ValidationRules';
import { AUTO_SAVE_DRAFT_DELAY } from 'app/notes-ui/utils';
import React from 'react';
import { Size } from 'types/StyleTypes';

type Props = {
  appointmentId: string;
  onSubmit: (data: MentalStatusExamSection) => void;
  updateDraftNoteState: (data: MentalStatusExamSection) => void;
  disabled?: boolean;
  savingNote?: boolean;
  initialValue?: MentalStatusExam;
};

export const fieldDefs: FieldDefinitions<MentalStatusExam> = {
  appointmentId: stringField(),
  associations: nonEmptyArrayField(),
  attentionAndConcentration: {
    description: stringField({
      rules: [
        (value, state) =>
          requiredIfNo(value, state, 'attentionAndConcentration'),
      ],
    }),
    isPresent: booleanField({ default: true }),
  },
  appearance: {
    effectWithinNormalLimits: booleanField({ default: false, rules: [] }),
    other: {
      description: stringField({ rules: [optionalField] }),
      isPresent: booleanField({ rules: [] }),
    },
  },
  fundOfKnowledge: {
    description: stringField({
      rules: [(value, state) => requiredIfNo(value, state, 'fundOfKnowledge')],
    }),
    isPresent: booleanField({ default: true }),
  },
  insight: field({
    rules: [
      (value) =>
        isEmpty(value) || value === Insight.undefined_insight
          ? 'This field is required'
          : undefined,
    ],
  }),
  insightStatus: {
    effectWithinNormalLimits: booleanField({ default: false, rules: [] }),
    other: {
      isPresent: booleanField({ rules: [] }),
      description: stringField({ rules: [optionalField] }),
    },
  },
  language: {
    description: stringField({
      rules: [(value, state) => requiredIfYes(value, state, 'language')],
    }),
    isPresent: booleanField(),
  },
  judgement: {
    effectWithinNormalLimits: booleanField({ default: false, rules: [] }),
    other: {
      isPresent: booleanField({ rules: [] }),
      description: stringField({ rules: [optionalField] }),
    },
  },
  moodAndAffect: {
    affect: {
      congruentWithContent: booleanField({ default: true }),
      congruentWithMood: booleanField({ default: true }),
      scope: field(),
    },
    affectDescription: nonEmptyArrayField(),
    moodInPatientWord: stringField(),
  },
  cognitive: {
    effectWithinNormalLimits: booleanField({ default: false, rules: [] }),
    other: {
      isPresent: booleanField({ rules: [] }),
      description: stringField({ rules: [optionalField] }),
    },
  },
  orientation: nonEmptyArrayField(),
  affect: {
    effectWithinNormalLimits: booleanField({ default: false, rules: [] }),
    other: {
      description: stringField({ rules: [optionalField] }),
      isPresent: booleanField({ rules: [] }),
    },
  },
  judgment: field({
    rules: [
      (value) =>
        isEmpty(value) || value === Judgment.undefined_judgment
          ? 'This field is required'
          : undefined,
    ],
  }),
  orientationStatus: {
    effectWithinNormalLimits: booleanField({ default: false, rules: [] }),
    other: {
      description: stringField({ rules: [optionalField] }),
      isPresent: booleanField({ rules: [] }),
    },
  },
  recentAndRemoteMemory: {
    description: stringField({
      rules: [
        (value, state) => requiredIfNo(value, state, 'recentAndRemoteMemory'),
      ],
    }),
    isPresent: booleanField({ default: true }),
  },
  speech: nonEmptyArrayField(),
  speechStatus: {
    effectWithinNormalLimits: booleanField({ default: false, rules: [] }),
    other: {
      description: stringField({ rules: [optionalField] }),
      isPresent: booleanField({ rules: [] }),
    },
  },
  thoughtContent: nonEmptyArrayField(),
  thoughtContentStatus: {
    effectWithinNormalLimits: booleanField({ default: false, rules: [] }),
    other: {
      description: stringField({ rules: [optionalField] }),
      isPresent: booleanField({ rules: [] }),
    },
  },
  thoughtProcess: nonEmptyArrayField(),
  thoughtProcessStatus: {
    effectWithinNormalLimits: booleanField({ default: false, rules: [] }),
    other: {
      description: stringField({ rules: [optionalField] }),
      isPresent: booleanField({ rules: [] }),
    },
  },
  version: field<MentalStatusExam_Version>({
    default: MentalStatusExam_Version.undefined_version,
  }),
};

export function MentalStatusExamForm(props: Props) {
  const { savingNote, disabled, appointmentId, initialValue } = props;

  const { fields, validate, getValue } = useForm<MentalStatusExam>(
    fieldDefs,
    { ...initialValue, appointmentId } as MentalStatusExam,
    {
      delay: AUTO_SAVE_DRAFT_DELAY,
      onStateChange: (data) =>
        props.updateDraftNoteState({
          data,
          name: PsychiatrySectionName.MENTAL_STATUS_EXAM,
        }),
    },
  );

  const onSubmit = async () => {
    if (await validate()) {
      props.onSubmit({
        data: getValue(),
        name: PsychiatrySectionName.MENTAL_STATUS_EXAM,
      });
    }
  };

  return (
    <>
      <EnumCheckboxesField
        labelSize={Size.MD}
        disabled={disabled}
        testId="speech"
        label="Speech*"
        options={Speech}
        field={fields.speech}
      />
      <NoteFormControlLabel size={Size.MD} label="Mood and Affect*">
        <NoteFormControlLabel label="Mood (in the patient’s own words)">
          <TextBoxField
            testId="moodInPatientWord"
            label=""
            field={fields.moodAndAffect.moodInPatientWord}
          />
        </NoteFormControlLabel>
        <EnumCheckboxesField
          disabled={disabled}
          testId="affectDescription"
          label="Description of Affect"
          options={Mood}
          field={fields.moodAndAffect.affectDescription}
        />
        <EnumRadioField
          disabled={disabled}
          testId="affectRange"
          label="Range of Affect"
          options={Affect_Scope}
          field={fields.moodAndAffect.affect.scope}
        />

        <YesOrNoField
          disabled={disabled}
          testId="congruentWithMood"
          label="Affect congruent with mood?"
          field={fields.moodAndAffect.affect.congruentWithMood}
        />

        <YesOrNoField
          disabled={disabled}
          testId="congruentWithContent"
          label="Affect congruent with content?"
          field={fields.moodAndAffect.affect.congruentWithContent}
        />
      </NoteFormControlLabel>

      <EnumCheckboxesField
        labelSize={Size.MD}
        disabled={disabled}
        testId="thoughtContent"
        label="Thought Content*"
        options={ThoughtContent}
        field={fields.thoughtContent}
      />

      <EnumCheckboxesField
        disabled={disabled}
        labelSize={Size.MD}
        testId="thoughtProcess"
        label="Thought Process*"
        options={ThoughtProcess}
        field={fields.thoughtProcess}
      />

      <EnumCheckboxesField
        disabled={disabled}
        testId="associations"
        labelSize={Size.MD}
        label="Associations*"
        options={MentalStatusExam_Association}
        field={fields.associations}
      />

      <EnumCheckboxesField
        disabled={disabled}
        testId="orientation"
        labelSize={Size.MD}
        label="Orientation*"
        options={MentalStatusExam_Orientation}
        field={fields.orientation}
      />

      <NoteFormControlLabel size={Size.MD} label="Recent and Remote Memory*">
        <YesOrNoField
          disabled={disabled}
          testId="recentAndRemoteMemory"
          field={fields.recentAndRemoteMemory.isPresent}
          label="Intact"
        />
        <TextBoxField
          disabled={disabled}
          testId="recentAndRemoteMemoryDescription"
          label=""
          field={fields.recentAndRemoteMemory.description}
        />
      </NoteFormControlLabel>

      <NoteFormControlLabel size={Size.MD} label="Attention And Concentration*">
        <YesOrNoField
          disabled={disabled}
          testId="attentionAndConcentration"
          field={fields.attentionAndConcentration.isPresent}
          label="Attends to questioning without difficulty?"
        />
        <TextBoxField
          disabled={disabled}
          testId="attentionAndConcentrationDescription"
          label=""
          field={fields.attentionAndConcentration.description}
        />
      </NoteFormControlLabel>

      <NoteFormControlLabel size={Size.MD} label="Language*">
        <YesOrNoField
          disabled={disabled}
          testId="language"
          field={fields.language.isPresent}
          label="Expressive and/or receptive language deficits?"
        />
        <TextBoxField
          disabled={disabled}
          testId="languageDescription"
          label=""
          field={fields.language.description}
        />
      </NoteFormControlLabel>

      <NoteFormControlLabel size={Size.MD} label="Fund of Knowledge*">
        <YesOrNoField
          disabled={disabled}
          testId="fundOfKnowledge"
          field={fields.fundOfKnowledge.isPresent}
          label="Expected knowledge level given educational attainment?"
        />
        <TextBoxField
          disabled={disabled}
          testId="fundOfKnowledgeDescription"
          label=""
          field={fields.fundOfKnowledge.description}
        />
      </NoteFormControlLabel>

      <NoteFormControlLabel size={Size.MD} label="Insight and Judgement*">
        <EnumRadioField
          disabled={disabled}
          testId="insight"
          label="Insight"
          options={Insight}
          field={fields.insight}
        />

        <EnumRadioField
          disabled={disabled}
          testId="judgment"
          label="Judgment"
          options={Judgment}
          field={fields.judgment}
        />
      </NoteFormControlLabel>
      <SaveButton
        isLoading={savingNote}
        disabled={disabled}
        onSubmit={onSubmit}
      />
    </>
  );
}

function requiredIfNo(
  value: string,
  state: MentalStatusExam,
  key: keyof MentalStatusExam,
): string | undefined {
  const field = state[key] as BooleanWithComments;
  const isPresent = field?.isPresent;
  if (isPresent !== undefined && !isPresent && isEmpty(value)) {
    return 'This field is required';
  }
  return undefined;
}

function requiredIfYes(
  value: string,
  state: MentalStatusExam,
  key: keyof MentalStatusExam,
): string | undefined {
  const field = state[key] as BooleanWithComments;
  const isPresent = field?.isPresent;
  if (isPresent !== undefined && isPresent && isEmpty(value)) {
    return 'This field is required';
  }
  return undefined;
}

export const validate = (data: MentalStatusExam | null) =>
  data !== null && validateForm(data, fieldDefs);
