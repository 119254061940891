import { BooleanOption } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/BooleanOption';
import { AbuseOfVulnerablePopulationsRisk } from '@ginger.io/vault-coach-notes/src/generated/protobuf-schemas/vault-coach-notes/AbuseOfVulnerablePopulationsRisk';
import DeleteIcon from '@mui/icons-material/HighlightOffRounded';
import { Typography } from '@mui/material';
import {
  RiskFormComponent,
  RiskFormProps,
  RiskType,
} from 'app/coach/coach-notes/CoachNotesTypes';
import { CardTextAreaField } from 'app/coach/coach-notes/note-detail-view/note-fields';
import { abuseOfVulnerablePopulationsRisk } from 'app/coach/coach-notes/note-detail-view/note-templates/fieldDefs';
import styles from 'app/coach/coach-notes/note-detail-view/NoteDetailView.module.scss';
import { useRiskForm } from 'app/coach/coach-notes/useRiskForm';
import { FieldLabel } from 'app/member-chart-cards/card-field/FieldLabel';

import { YesOrNoField } from 'app/notes-ui/forms/fields/yesOrNoField';
import { BooleanRadioField } from 'app/notes-ui/forms/form-controls/BooleanRadioField';
import React from 'react';
import { classNameCombiner } from 'utils';

enum FieldLabels {
  REPORT_NECESSARY = 'Mandated Report by us determined necessary? *',
  MANDATED_REPORT = 'Why or why not? *',
  DATE_OF_INCIDENT = 'Date of incident',
  NATURE_OF_ABUSE = 'Nature and extent of the suspected abuse',
  LOCATION_OF_ABUSE = 'Where the suspected abuse occurred',
  NAME_OF_PERSON_RESPONSIBLE = 'The name and relationship of the persons responsible for causing the suspected abuse',
  VICTIM_AGE_AND_GENDER = 'The age(s) and gender(s) of those involved in the incident',
  VICTIM_PERSONAL_INFO = 'The names, addresses and phone numbers of the abused and the parents/family or other person responsible for the care of the abused, if known.',
  WHO_DISCLOSED_INCIDENT = 'Has a formal report to authorities occurred? *',
  NAME_OF_AUTHORITY = 'Name of the authority who handled the report',
  DISCLOSURE = 'Disclosure to anyone, such as a friend, family member, PCP, or therapist?',
  DISCLOSED_TO = 'Who? *',
  OTHER_RELEVANT_INFO = 'Other relevant information',
}

enum Placeholders {
  MANDATED_REPORT = 'Type here...',
  DATE_OF_INCIDENT = 'If exact date is unknown, provide best estimate...',
  NATURE_OF_ABUSE = 'If known, any evidence of prior abuse to the abused...',
  LOCATION_OF_ABUSE = 'Type here...',
  NAME_OF_PERSON_RESPONSIBLE = 'If known, any evidence of prior abuse by those persons...',
  VICTIM_AGE_AND_GENDER = 'Type here...',
  VICTIM_PERSONAL_INFO = 'Type here...',
  NAME_OF_AUTHORITY = 'Type here...',
  DISCLOSED_TO = 'Type here...',
  OTHER_RELEVANT_INFO = 'Location, if imminent...',
}

const TOOLTIP_EVENT_LABEL =
  'Coach Notes > Abuse of Vulnerable Populations Risk';

export const AbuseOfVulnerablePopulationsRiskForm: RiskFormComponent<AbuseOfVulnerablePopulationsRisk> = (
  props: RiskFormProps<AbuseOfVulnerablePopulationsRisk>,
) => {
  const {
    readOnly,
    inline,
    isRemovable,
    initialValue,
    onChange,
    removeRisk,
  } = props;
  const { fields, onRemoveRisk } = useRiskForm<
    AbuseOfVulnerablePopulationsRisk
  >({
    initialValue,
    onChange,
    removeRisk,
    riskType: RiskType.ABUSE_OF_VULNERABLE_POPULATIONS_RISK,
    validationSchema: abuseOfVulnerablePopulationsRisk,
  });

  return (
    <div className={inline ? styles.inlineRiskSection : styles.cardSection}>
      <div
        className={classNameCombiner([
          styles.riskTitleField,
          isRemovable ? styles.withButton : '',
        ])}
      >
        <Typography className={styles.riskAssessmentTitle}>
          Abuse of Vulnerable
        </Typography>
        <span className={styles.inlineBorder} />
        <Typography className={styles.riskAssessmentSubtitle}>
          Populations
        </Typography>
        <Typography className={styles.titleDescription}>
          (child(ren), dependent adults, elderly)
        </Typography>
        {isRemovable && (
          <DeleteIcon
            data-testid="removeBtn"
            className={styles.removeRiskBtn}
            onClick={onRemoveRisk}
          />
        )}
      </div>

      <YesOrNoField
        testId="mandatedReport"
        label={FieldLabels.REPORT_NECESSARY}
        field={fields.reportNecessary}
        disabled={readOnly}
        fullWidth={true}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.MANDATED_REPORT}
      />
      <CardTextAreaField
        name="abuseOfVulnerablePopulationsMandatedReport"
        field={fields.mandatedReport}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.MANDATED_REPORT}`}
        tooltipText=""
        placeholder={Placeholders.MANDATED_REPORT}
        readOnly={readOnly}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.DATE_OF_INCIDENT}
      />
      <CardTextAreaField
        field={fields.dateOfIncident}
        name="abuseOfVulnerablePopulationsDateOfIncident"
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.DATE_OF_INCIDENT}`}
        tooltipText=""
        placeholder={Placeholders.DATE_OF_INCIDENT}
        readOnly={readOnly}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.NATURE_OF_ABUSE}
      />
      <CardTextAreaField
        field={fields.natureOfAbuse}
        name="abuseOfVulnerablePopulationsNatureOfAbuse"
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.NATURE_OF_ABUSE}`}
        tooltipText=""
        placeholder={Placeholders.NATURE_OF_ABUSE}
        readOnly={readOnly}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.LOCATION_OF_ABUSE}
      />
      <CardTextAreaField
        field={fields.locationOfAbuse}
        name="abuseOfVulnerablePopulationLocationOfAbuse"
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.LOCATION_OF_ABUSE}`}
        tooltipText=""
        placeholder={Placeholders.LOCATION_OF_ABUSE}
        readOnly={readOnly}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.NAME_OF_PERSON_RESPONSIBLE}
      />
      <CardTextAreaField
        field={fields.nameOfPersonResponsible}
        name="abuseOfVulnerablePopulationsNameOfPersonResponsible"
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.NAME_OF_PERSON_RESPONSIBLE}`}
        tooltipText=""
        placeholder={Placeholders.NAME_OF_PERSON_RESPONSIBLE}
        readOnly={readOnly}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.VICTIM_AGE_AND_GENDER}
      />
      <CardTextAreaField
        field={fields.victimAgeAndGender}
        name="abuseOfVulnerablePopulationsVictimAgeAndGender"
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.VICTIM_AGE_AND_GENDER}`}
        tooltipText=""
        placeholder={Placeholders.VICTIM_AGE_AND_GENDER}
        readOnly={readOnly}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.VICTIM_PERSONAL_INFO}
      />
      <CardTextAreaField
        field={fields.victimPersonalInfo}
        name="abuseOfVulnerablePopulationsVictimPersonalInfo"
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.VICTIM_PERSONAL_INFO}`}
        tooltipText=""
        placeholder={Placeholders.VICTIM_PERSONAL_INFO}
        readOnly={readOnly}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.WHO_DISCLOSED_INCIDENT}
      />
      <BooleanRadioField
        disabled={readOnly}
        name="authoritiesNotified"
        value={fields.authoritiesNotified.value}
        onChange={fields.authoritiesNotified.setValue}
      />

      {fields.authoritiesNotified.value === BooleanOption.yes && (
        <>
          <FieldLabel
            className={styles.label}
            labelText={FieldLabels.NAME_OF_AUTHORITY}
          />
          <CardTextAreaField
            field={fields.nameOfAuthority}
            name="abuseOfVulnerablePopulationsNameOfAuthority"
            tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.NAME_OF_AUTHORITY}`}
            tooltipText=""
            placeholder={Placeholders.NAME_OF_AUTHORITY}
            readOnly={readOnly}
          />
        </>
      )}

      <FieldLabel className={styles.label} labelText={FieldLabels.DISCLOSURE} />
      <BooleanRadioField
        disabled={readOnly}
        name="abuseOfVulnerablePopulationsDisclosure"
        value={fields.disclosure.value}
        onChange={fields.disclosure.setValue}
      />

      {fields.disclosure.value === BooleanOption.yes && (
        <>
          <FieldLabel
            className={styles.label}
            labelText={FieldLabels.DISCLOSED_TO}
          />
          <CardTextAreaField
            field={fields.disclosedTo}
            name="abuseOfVulnerablePopulationsDisclosedTo"
            tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.DISCLOSED_TO}`}
            tooltipText=""
            placeholder={Placeholders.DISCLOSED_TO}
            readOnly={readOnly}
          />
        </>
      )}

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.OTHER_RELEVANT_INFO}
      />
      <CardTextAreaField
        field={fields.otherRelevantInfo}
        name="abuseOfVulnerablePopulations"
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.OTHER_RELEVANT_INFO}`}
        tooltipText=""
        placeholder={Placeholders.OTHER_RELEVANT_INFO}
        readOnly={readOnly}
      />
    </div>
  );
};
