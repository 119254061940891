import { InboxSectionHeaderColor } from 'app/inbox/components/InboxSection';
import { InboxSections } from 'app/inbox/types';
import {
  fetchMockData,
  transformedGetTasksResponse,
} from 'app/inboxV2/__mocks__/taskApi.mocks';
import { DEFAULT_INBOX_SECTION_ITEM_LIMIT } from 'app/inboxV2/constants';
import { SectionProps, TaskSummary } from 'app/inboxV2/types';
import { useAppState } from 'app/state';
import { useOnMount } from 'hooks/useOnMount';
import { FC, useCallback, useMemo, useState } from 'react';
import { formatDateToHumanReadable } from 'utils/dateTime';

import { InboxSectionItem } from '../InboxSectionItem';
import { InboxSectionWrapper } from '../InboxSectionWrapper';
import {
  CountBubble,
  MainContentText,
  SubContentText,
  SubContentTextColorEnum,
} from '../SectionContentItems';

export const ConversationSection: FC<SectionProps> = ({ onItemClick }) => {
  const [conversations, setConversations] = useState<TaskSummary[]>(
    transformedGetTasksResponse.taskSummaries.filter((_, index) => index % 3), // TODO: remove once api returns data
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingItems, setLoadingItems] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(
    DEFAULT_INBOX_SECTION_ITEM_LIMIT,
  );

  const timezone = useAppState(({ user }) => user.timezone ?? 'utc');

  useOnMount(() => {
    loadData();
  });

  const loadData = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const result = await fetchMockData();
      setConversations(result.filter((_, index) => index % 3)); // TODO: remove once api returns data
      setError(false);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleLoadMoreClick = useCallback(() => {
    setLoadingItems(true);

    setTimeout(() => {
      setOffset((prevOffset) => prevOffset + DEFAULT_INBOX_SECTION_ITEM_LIMIT);
      setLoadingItems(false);
    }, 500); // TODO: remove the delay
  }, []);

  const handleRetryClick = useCallback(() => {
    loadData();
  }, [loadData]);

  const showLoadMore = useMemo(
    () => transformedGetTasksResponse.metadata.total > offset,
    [offset],
  );

  const paginatedTasks = useMemo(() => conversations.slice(0, offset), [
    conversations,
    offset,
  ]);

  return (
    <InboxSectionWrapper
      headerColor={InboxSectionHeaderColor.LIGHT_CLOUD}
      sectionTitle="Conversations"
      section={InboxSections.CONVERSATIONS}
      sectionItemCount={conversations.length}
      error={error}
      loading={loading}
      onRetryClick={handleRetryClick}
      onLoadMoreClick={handleLoadMoreClick}
      loadingItems={loadingItems}
      showLoadMore={showLoadMore}
    >
      {paginatedTasks.map(({ firstName, lastName, topMatchingTask, id }) => (
        <InboxSectionItem
          key={`${firstName}-${lastName}-${id}`}
          topLeft={<MainContentText label={`${firstName} ${lastName}`} />}
          topRight={
            <SubContentText
              label={formatDateToHumanReadable(
                topMatchingTask.visibleAt!,
                timezone,
                true,
              )}
            />
          }
          bottomLeft={
            <SubContentText
              label="additionalContent"
              textColor={SubContentTextColorEnum.primary}
            />
          }
          bottomRight={<CountBubble total={1} />}
          onClick={() =>
            onItemClick(
              { id: String(id), name: '' },
              InboxSections.CONVERSATIONS,
            )
          }
        />
      ))}
    </InboxSectionWrapper>
  );
};
