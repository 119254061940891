import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectIsBlueskyFeatureFlagEnabled } from '../state/featureFlags/selectors';
import {
  MuiLinkBlueSky,
  MuiLinkDark,
  MuiLinkDefault,
} from './MuiComponentsOverrides/MuiLink';
import {
  MuiPaperBlueSky,
  MuiPaperDark,
  MuiPaperDefault,
} from './MuiComponentsOverrides/MuiPaper';
import {
  MuiTabBlueSky,
  MuiTabDark,
  MuiTabDefault,
} from './MuiComponentsOverrides/MuiTab';
import {
  MuiTooltipBlueSky,
  MuiTooltipDark,
  MuiTooltipDefault,
} from './MuiComponentsOverrides/MuiTooltip';

export const SkyColor = '#9EC3EC';
export const StatusCriticalColor = '#D64218';
export const appThemeBase = createTheme({
  typography: {
    fontFamily: ['Headspace Apercu'].join(','),
  },
});

export const appThemeDefault = createTheme(appThemeBase, {
  components: {
    ...MuiLinkDefault,
    ...MuiTabDefault,
    ...MuiPaperDefault,
    ...MuiTooltipDefault,
  },
  palette: {
    primary: {
      contrastText: '#ffffff',
      main: '#2875cc',
    },
    secondary: {
      contrastText: '#272727',
      main: '#f4f4f4',
    },
  },
  typography: {
    body1: {
      color: '#272727',
    },
  },
});

export const appThemeBlueSky = createTheme(appThemeBase, {
  components: {
    ...MuiLinkBlueSky,
    ...MuiTabBlueSky,
    ...MuiPaperBlueSky,
    ...MuiTooltipBlueSky,
  },
  palette: {
    primary: {
      contrastText: '#ffffff',
      main: '#2875cc',
    },
    secondary: {
      contrastText: '#272727',
      main: '#f4f4f4',
    },
  },
  typography: {
    body1: {
      color: '#2d2c2b',
    },
  },
});

export const appThemeDark = createTheme(appThemeBase, {
  components: {
    ...MuiLinkDark,
    ...MuiTabDark,
    ...MuiPaperDark,
    ...MuiTooltipDark,
  },
  palette: {
    primary: {
      contrastText: '#ffffff',
      main: '#2875cc',
    },
    secondary: {
      contrastText: '#272727',
      main: '#f4f4f4',
    },
  },
  typography: {
    body1: {
      color: '#e5e7ec',
    },
  },
});
interface AppThemeProps {
  children: ReactNode;
}

enum AppThemeHtmlClasses {
  LIGHT = 'theme-light',
  BLUESKY = 'theme-bluesky',
  DARK = 'theme-dark',
}

const themeModeMap = {
  [AppThemeHtmlClasses.LIGHT]: appThemeDefault,
  [AppThemeHtmlClasses.BLUESKY]: appThemeBlueSky,
  [AppThemeHtmlClasses.DARK]: appThemeDark,
};

export const AppTheme = ({ children }: AppThemeProps) => {
  const elementRef = useRef(null);
  const [theme, setTheme] = useState(appThemeDefault);

  const isBlueSkyFeatureEnabled = useSelector(
    selectIsBlueskyFeatureFlagEnabled,
  );

  const appThemeClass = useMemo(
    () =>
      isBlueSkyFeatureEnabled
        ? AppThemeHtmlClasses.BLUESKY
        : AppThemeHtmlClasses.LIGHT,
    [isBlueSkyFeatureEnabled],
  );

  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      // Observer watches for changes in the class attribute of the element. When updated we can change any component using MUI theme.
      mutationsList.forEach((mutation) => {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'class'
        ) {
          const themeClassName = (mutation.target as Element).className;
          const updatedTheme = createTheme(
            appThemeDefault,
            themeModeMap[themeClassName as AppThemeHtmlClasses],
          );
          setTheme(updatedTheme);
        }
      });
    });

    const targetElement = elementRef.current;

    if (targetElement) {
      observer.observe(targetElement, { attributes: true });
    }

    // Clean up the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div ref={elementRef} className={appThemeClass}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </div>
  );
};
