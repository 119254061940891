import { useLogger } from 'app/state/log/useLogger';
import { useCallback } from 'react';

export type LocalStorageItemKey = keyof LocalStorageData;

export interface NotesLocalStorageData {
  [appointmentId: string]: {
    isAiNotetakerAssisted: boolean; // Indicates whether AI notetaker was used for this appointment note
  };
}

export interface LocalStorageData {
  notes: Partial<NotesLocalStorageData>;
}

export function useLocalStorage() {
  const logger = useLogger();

  const getLocalStorageItem = useCallback(
    <T extends LocalStorageItemKey>(
      key: T,
      defaultValue: LocalStorageData[T] = {},
    ): LocalStorageData[T] => {
      try {
        const item = localStorage.getItem(key);

        return item === null ? defaultValue : JSON.parse(item);
      } catch (error) {
        logger.error(error, { key });
        return defaultValue;
      }
    },
    [logger],
  );

  const setLocalStorageItem = useCallback(
    <T extends LocalStorageItemKey>(
      key: T,
      value: LocalStorageData[T],
    ): void => {
      try {
        localStorage.setItem(key, JSON.stringify(value));
      } catch (error) {
        logger.error(error, { key });
      }
    },
    [logger],
  );

  return { getLocalStorageItem, setLocalStorageItem };
}
