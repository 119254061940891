import { InboxSectionHeaderColor } from 'app/inbox/components/InboxSection';
import { InboxSections } from 'app/inbox/types';
import { transformedGetTasksResponse } from 'app/inboxV2/__mocks__/taskApi.mocks';
import { DEFAULT_INBOX_SECTION_ITEM_LIMIT } from 'app/inboxV2/constants';
import { useGetTasksQuery } from 'app/inboxV2/endpoints/tasksApi';
import { SectionProps, TaskSummary } from 'app/inboxV2/types';
import { useAppState } from 'app/state';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { formatDateToHumanReadable } from 'utils/dateTime';

import { InboxSectionItem } from '../InboxSectionItem';
import { InboxSectionWrapper } from '../InboxSectionWrapper';
import { MainContentText, SubContentText } from '../SectionContentItems';

export const TaskSection: FC<SectionProps> = ({ onItemClick }) => {
  const [tasks, setTasks] = useState<TaskSummary[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingItems, setLoadingItems] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(
    DEFAULT_INBOX_SECTION_ITEM_LIMIT,
  );

  const timezone = useAppState(({ user }) => user.timezone ?? 'utc');

  const { data: tasksData, refetch: refetchTasks } = useGetTasksQuery({});

  useEffect(() => {
    setLoading(true);
    setError(false);
    try {
      setTasks(tasksData?.taskSummaries ?? []);
      setError(false);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [tasksData]);

  const handleRetryClick = useCallback(() => {
    refetchTasks();
  }, [refetchTasks]);

  const handleLoadMoreClick = useCallback(() => {
    setLoadingItems(true);

    setTimeout(() => {
      setOffset((prevOffset) => prevOffset + DEFAULT_INBOX_SECTION_ITEM_LIMIT);
      setLoadingItems(false);
    }, 500); // TODO: remove the delay
  }, []);

  const showLoadMore = useMemo(
    () => transformedGetTasksResponse.metadata.total > offset,
    [offset],
  );

  const paginatedTasks = useMemo(() => tasks.slice(0, offset), [tasks, offset]);

  return (
    <InboxSectionWrapper
      headerColor={InboxSectionHeaderColor.LIGHT_CLOUD}
      sectionTitle="Tasks"
      section={InboxSections.TASKS}
      sectionItemCount={tasks.length}
      error={error}
      loading={loading}
      onRetryClick={handleRetryClick}
      onLoadMoreClick={handleLoadMoreClick}
      loadingItems={loadingItems}
      showLoadMore={showLoadMore}
    >
      {paginatedTasks.map(({ firstName, lastName, topMatchingTask, id }) => (
        <InboxSectionItem
          key={`${firstName}-${lastName}-${id}`}
          topLeft={<MainContentText label={`${firstName} ${lastName}`} />}
          topRight={
            <SubContentText
              label={formatDateToHumanReadable(
                topMatchingTask.visibleAt!,
                timezone,
                true,
              )}
            />
          }
          bottomLeft={<SubContentText label="additionalContent" />}
          onClick={() =>
            onItemClick({ id: String(id), name: '' }, InboxSections.TASKS)
          }
        />
      ))}
    </InboxSectionWrapper>
  );
};
