import { Field } from '@ginger.io/react-use-form/dist/types';
import { NoteFormControlLabelStyles } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { TextAreaWithLabel } from 'app/notes-ui/forms/form-controls/TextArea';
import { AiMagicIconState } from 'app/sortable-table/note-header/AiMagicIcon';
import React, { ReactNode } from 'react';
import { Width } from 'types/StyleTypes';

type Props = {
  aiIconState?: AiMagicIconState;
  autoExpand?: boolean;
  button?: ReactNode;
  className?: string;
  disabled?: boolean;
  feedbackComponent?: ReactNode;
  field: Field<string>;
  formControlStyles?: NoteFormControlLabelStyles;
  label?: string;
  labelDescription?: string;
  optional?: boolean;
  placeholder?: string;
  /** number of rows to display (minimal number of rows if auto expandable) */
  rows?: number;
  showAiIcon?: boolean;
  subtext?: string;
  testId?: string;
  tooltip?: string;
  width?: Width;
};

export function TextAreaField(props: Readonly<Props>) {
  const { field, testId, ...rest } = props;
  return (
    <TextAreaWithLabel
      {...rest}
      name={testId}
      value={field.value}
      error={field.error}
      onChange={field.setValue}
    />
  );
}
