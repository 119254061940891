import { useForm } from '@ginger.io/react-use-form';
import { TreatmentPlan } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/progress/TreatmentPlan';
import {
  TherapyProgressSectionName,
  TreatmentPlanSection,
} from '@ginger.io/vault-clinical-notes/dist/TherapyProgressSection';

import { NumberField } from 'app/notes-ui/forms/fields/numberField';
import { TextAreaField } from 'app/notes-ui/forms/fields/textAreaField';
import { YesOrNoField } from 'app/notes-ui/forms/fields/yesOrNoField';
import { AnticipatedNumberOfSessionsField } from 'app/notes-ui/forms/form-controls/AnticipatedNumberOfSessionsField';
import { BooleanRadioField } from 'app/notes-ui/forms/form-controls/BooleanRadioField';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import { SaveButton } from 'app/notes-ui/forms/form-controls/SaveButton';
import Labels from 'app/notes-ui/strings/en.json';
import {
  AUTO_SAVE_DRAFT_DELAY,
  getDefaultEnumValue,
  showComplianceFields,
} from 'app/notes-ui/utils';
import React from 'react';

import { fieldDefs } from './schema';

export type Props = {
  appointmentId: string;
  onSubmit: (checklist: TreatmentPlanSection) => void;
  updateDraftNoteState: (data: TreatmentPlanSection) => void;
  initialValue?: TreatmentPlan; // used for read-only
  disabled?: boolean;
  savingNote?: boolean;
  noteLastUpdatedAt?: string;
};

export function TreatmentPlanForm(props: Props) {
  const {
    initialValue,
    disabled,
    appointmentId,
    savingNote,
    noteLastUpdatedAt,
  } = props;

  const { fields, validate, getValue } = useForm<TreatmentPlan>(
    fieldDefs,
    { ...initialValue, appointmentId } as TreatmentPlan,
    {
      delay: AUTO_SAVE_DRAFT_DELAY,
      onStateChange: (data) =>
        props.updateDraftNoteState({
          data,
          name: TherapyProgressSectionName.TREATMENT_PLAN,
        }),
    },
  );

  const onSubmit = async () => {
    if (await validate()) {
      props.onSubmit({
        data: getValue(),
        name: TherapyProgressSectionName.TREATMENT_PLAN,
      });
    }
  };
  return (
    <>
      <div className={styles.formGroup}>
        {disabled && fields.additionalSessionsRequired.value ? (
          <NumberField
            disabled={disabled}
            testId="numberOfSessionsAnticipated"
            label="Number of sessions anticipated*"
            field={fields.additionalSessionsRequired}
          />
        ) : (
          <AnticipatedNumberOfSessionsField
            value={{
              frequency: fields.anticipatedSession.frequency.value,
              numberOfSessionsAnticipated:
                fields.anticipatedSession.numberOfSessionsAnticipated.value,
            }}
            label="Additional sessions required*"
            onChange={(value) => {
              fields.anticipatedSession.frequency.setValue(value.frequency);
              fields.anticipatedSession.numberOfSessionsAnticipated.setValue(
                value.numberOfSessionsAnticipated,
              );
            }}
            name="anticipatedSessionsRequired"
            error={
              fields.anticipatedSession.frequency.error ||
              fields.anticipatedSession.numberOfSessionsAnticipated.error
            }
          />
        )}
      </div>

      {showComplianceFields(noteLastUpdatedAt) && (
        <div>
          <div className={styles.formGroup}>
            <YesOrNoField
              disabled={disabled}
              testId="waitTimeEffectProgress"
              label={`${Labels.WAIT_TIME_EFFECT}*`}
              field={fields.waitTimeDetrimentalEffect}
            />
          </div>
          <div className={styles.formGroup}>
            <BooleanRadioField
              disabled={disabled}
              name="appointmentOffered"
              label={Labels.OFFER_AN_APPOINTMENT}
              value={getDefaultEnumValue(fields.appointmentOffered)}
              onChange={fields.appointmentOffered.setValue}
            />
          </div>
          <div className={styles.formGroup}>
            <BooleanRadioField
              disabled={disabled}
              name="offeredAppointmentAccepted"
              label={Labels.APPOINTMENT_OFFER_ACCEPTED}
              value={getDefaultEnumValue(fields.offeredAppointmentAccepted)}
              onChange={fields.offeredAppointmentAccepted.setValue}
            />
          </div>

          <TextAreaField
            disabled={disabled}
            testId="referralsToCareOutsideGingerNecessaryProgress"
            label={Labels.REFERRALS_TO_CARE_OUTSIDE}
            field={fields.referralsToCareOutsideGingerNecessary}
          />
        </div>
      )}
      <TextAreaField
        disabled={disabled}
        testId="changesToTreatmentPlan"
        label="Review/update treatment plan/cultural considerations*"
        field={fields.changesToTreatmentPlan}
      />

      <SaveButton
        isLoading={savingNote}
        disabled={disabled}
        onSubmit={onSubmit}
      />
    </>
  );
}
