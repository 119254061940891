import { isInboxV2Enabled } from 'app/inboxV2/utils';
import { State } from 'app/state/schema';
import { useSelector } from 'react-redux';

import { selectTransientFeatureFlags } from '../featureFlags/selectors';
import { RootState } from '../hooks/baseTypedHooks';

export const selectUserRole = () =>
  useSelector((state: State) => state.user.role);

export const selectUserIdAndRole = ({ user }: State) => ({
  role: user.role,
  userId: user.userId,
});

export const selectShouldDisplayInboxV2 = (state: RootState) => {
  const { role } = state.user;
  const flagEnabled = selectTransientFeatureFlags(state)
    .carehub_enable_coach_inbox_v2;

  return isInboxV2Enabled({
    flagEnabled,
    userRole: role,
  });
};

export const selectWildCardPattern = (state: RootState) => {
  const { listenerId } = state.user;
  return listenerId ? `chat.L${state.user.listenerId}.*` : undefined;
};

export const selectRPCToCoachChannelId = (state: RootState) => {
  return state.user.rpcToCoachChannelId;
};
