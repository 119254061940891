import {
  field,
  FieldDefinitions,
  stringField,
  useForm,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import {
  SocialAndDevelopmental,
  SocialAndDevelopmental_Employment as EmploymentStatus,
  SocialAndDevelopmental_EmploymentStatus_Status,
  SocialAndDevelopmental_LivingArrangement as LivingArrangement,
  SocialAndDevelopmental_LivingSituation_Status,
  SocialAndDevelopmental_MaritalStatus_Status,
  SocialAndDevelopmental_RelationShipStatus as RelationshipStatus,
  SocialAndDevelopmental_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/intake/SocialAndDevelopmental';
import {
  SocialAndDevelopmentalSection,
  TherapyIntakeSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';

import { EnumRadioField } from 'app/notes-ui/forms/fields/enumRadioField';
import { TextAreaField } from 'app/notes-ui/forms/fields/textAreaField';
import { SaveButton } from 'app/notes-ui/forms/form-controls/SaveButton';
import { AUTO_SAVE_DRAFT_DELAY } from 'app/notes-ui/utils';
import React from 'react';

type Props = {
  appointmentId: string;
  initialValue?: SocialAndDevelopmental;
  onSubmit: (data: SocialAndDevelopmentalSection) => void;
  updateDraftNoteState: (data: SocialAndDevelopmentalSection) => void;
  disabled?: boolean;
  savingNote?: boolean;
};

export const fieldDefs: FieldDefinitions<SocialAndDevelopmental> = {
  appointmentId: stringField(),
  culturalConsiderations: stringField({ rules: [] }),
  employment: field<EmploymentStatus[]>({ default: [], rules: [] }),

  employmentStatus: {
    status: field(),
  },
  livingArrangement: field<LivingArrangement[]>({ default: [], rules: [] }),
  livingSituation: {
    comment: stringField({ rules: [] }),
    status: field(),
  },
  maritalStatus: {
    comment: stringField({ rules: [] }),
    status: field(),
  },
  otherComments: stringField({ rules: [] }),
  otherEmploymentComments: stringField({ rules: [] }),
  otherLivingArrangementComments: stringField({ rules: [] }),
  otherRelationshipStatusComments: stringField({ rules: [] }),
  relationshipStatus: field<RelationshipStatus[]>({ default: [], rules: [] }),
  relevantSocialOrFamilyHistory: stringField(),
  version: field<SocialAndDevelopmental_Version>({
    default: SocialAndDevelopmental_Version.undefined_version,
  }),
};

export function SocialDevelopmentForm(props: Props) {
  const { initialValue, appointmentId, disabled, savingNote } = props;

  const { fields, getValue, validate } = useForm<SocialAndDevelopmental>(
    fieldDefs,
    { ...initialValue, appointmentId } as SocialAndDevelopmental,
    {
      delay: AUTO_SAVE_DRAFT_DELAY,
      onStateChange: (data) =>
        props.updateDraftNoteState({
          data,
          name: TherapyIntakeSectionName.SOCIAL_DEVELOPMENTAL,
        }),
    },
  );

  const onSubmit = async () => {
    if (await validate()) {
      props.onSubmit({
        data: getValue(),
        name: TherapyIntakeSectionName.SOCIAL_DEVELOPMENTAL,
      });
    }
  };

  return (
    <>
      <EnumRadioField
        disabled={disabled}
        testId="maritalStatus"
        label="Marital status*"
        options={SocialAndDevelopmental_MaritalStatus_Status}
        field={fields.maritalStatus.status}
      />

      <TextAreaField
        disabled={disabled}
        testId="maritalStatusComment"
        label="Comment"
        field={fields.maritalStatus.comment}
      />

      <EnumRadioField
        disabled={disabled}
        testId="livingSituation"
        label="Living arrangement*"
        options={SocialAndDevelopmental_LivingSituation_Status}
        field={fields.livingSituation.status}
      />

      <TextAreaField
        disabled={disabled}
        testId="livingSituationComment"
        label="Comment"
        field={fields.livingSituation.comment}
      />

      <EnumRadioField
        disabled={disabled}
        testId="employmentStatus"
        label="Employment*"
        options={SocialAndDevelopmental_EmploymentStatus_Status}
        field={fields.employmentStatus.status}
      />

      <TextAreaField
        disabled={disabled}
        testId="relevantSocialOrFamilyHistory"
        label="Relevant social/family history*"
        field={fields.relevantSocialOrFamilyHistory}
        tooltip="Including family history of mental illness"
      />

      <TextAreaField
        disabled={disabled}
        testId="otherComments"
        label="Additional social/developmental status & functioning comments"
        field={fields.otherComments}
      />
      <TextAreaField
        disabled={disabled}
        testId="culturalConsiderations"
        label="Cultural Considerations"
        field={fields.culturalConsiderations}
        tooltip="Are there cultural considerations you would like to share with me that we have not yet discussed today?"
      />
      <SaveButton
        isLoading={savingNote}
        disabled={disabled}
        onSubmit={onSubmit}
      />
    </>
  );
}

export const validate = (data: SocialAndDevelopmental | null) =>
  data !== null && validateForm(data, fieldDefs);
