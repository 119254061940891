import {
  AppointmentStatus,
  ClinicalAppointmentNoteStatus,
  ClinicalAppointmentNoteType,
  ClinicalAppointmentStatus,
  ClinicalNoteStatus,
  ClinicianAppointmentStatus,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { GetClinicalAppointmentsForMember_getClinicalAppointmentsForMember_appointments } from '@headspace/carehub-graphql/dist/patients/generated/GetClinicalAppointmentsForMember';
import { Link, SvgIcon, Typography } from '@mui/material';
import { getNoteAction } from 'app/appointments/utils';
import { useAppState } from 'app/state';
import { ReactComponent as AiDraftReady } from 'assets/appointments/table/GreenCheck.svg';
import moment from 'moment';
import React from 'react';
import { classNameCombiner } from 'utils';
import { getTimezone } from 'utils/dateTime';
import { formatClinicalNoteStatus } from 'utils/notes';

import {
  AppointmentsAndNotesCursor,
  useAppointmentsAndNotesAPI,
} from '../AppointmentsAndNotesAPIContext';
import { NoteAction } from '../types';
import { ReactComponent as AddNewIcon } from './icons/FilePlus.svg';
import { ReactComponent as LockedIcon } from './icons/Lock.svg';
import { ReactComponent as EditIcon } from './icons/Pencil.svg';
import { ReactComponent as OverdueIcon } from './icons/WarningCircle.svg';
import styles from './NoteCard.module.scss';

export type AppointmentNoteStatusProps = {
  appointment: GetClinicalAppointmentsForMember_getClinicalAppointmentsForMember_appointments;
  allowedNoteActions: NoteAction[];
  signedLockedAction: NoteAction.VIEW | NoteAction.DOWNLOAD;
  onClick?: (note: AppointmentsAndNotesCursor) => Promise<void>;
  showAiDraftLabel?: boolean;
};

const isInCancellationStatus = (
  appointmentStatus:
    | ClinicalAppointmentStatus
    | ClinicianAppointmentStatus
    | AppointmentStatus
    | null,
) => {
  return [
    ClinicianAppointmentStatus.NoShow,
    ClinicianAppointmentStatus.ProviderNoShow,
    ClinicianAppointmentStatus.Cancelled,
    ClinicianAppointmentStatus.CancelledClinician,
    ClinicianAppointmentStatus.LateCancelled,
    ClinicalAppointmentStatus.NoShow,
    ClinicalAppointmentStatus.ProviderNoShow,
    ClinicalAppointmentStatus.Cancelled,
    ClinicalAppointmentStatus.CancelledClinician,
    ClinicalAppointmentStatus.LateCancelled,
    ClinicalAppointmentStatus.Rescheduled,
    AppointmentStatus.NO_SHOW,
    AppointmentStatus.PROVIDER_NO_SHOW,
    AppointmentStatus.CANCELLED,
    AppointmentStatus.CANCELLED_CLINICIAN,
    AppointmentStatus.LATE_CANCELLED,
    AppointmentStatus.RESCHEDULED,
    null,
  ].includes(appointmentStatus);
};

function noteStatusIcon(
  appointmentStatus:
    | ClinicianAppointmentStatus
    | ClinicalAppointmentStatus
    | AppointmentStatus
    | null,
  clinicalNoteStatus: ClinicalNoteStatus | ClinicalAppointmentNoteStatus | null,
) {
  if (isInCancellationStatus(appointmentStatus)) {
    return null;
  }

  switch (clinicalNoteStatus) {
    case ClinicalNoteStatus.DRAFT:
      return <EditIcon />;
    case ClinicalNoteStatus.NOT_STARTED:
      return <AddNewIcon />;
    case ClinicalNoteStatus.SIGNED_AND_LOCKED:
      return <LockedIcon />;
    default:
      return null;
  }
}

/** *
 * An appointment is overdue if:
 * 1. It is 48 hours after the appointment start time
 * 2. The note is not signed and locked
 * 3. The appointment is not in a cancellation status
 * 4. The user owns the appointment
 */
export const isAppointmentOverdue = (
  isAppointmentOwner: boolean,
  appointmentNoteStatus: ClinicalAppointmentNoteStatus | null | undefined,
  appointmentStatus: ClinicalAppointmentStatus | null,
  appointmentDate: moment.Moment,
  now?: moment.Moment, // this is for testing purposes, so we can avoid mocking moment
) => {
  const fortyEightHoursLater = moment(appointmentDate).add(48, 'hours');
  const isAfter48Hours = (now || moment()).isAfter(fortyEightHoursLater);
  const isNoteNotSignedAndLocked =
    appointmentNoteStatus !== ClinicalAppointmentNoteStatus.SIGNED_AND_LOCKED;
  const isNotInCancellationStatus = !isInCancellationStatus(appointmentStatus);

  return (
    isAfter48Hours &&
    isNoteNotSignedAndLocked &&
    isNotInCancellationStatus &&
    isAppointmentOwner
  );
};

export const AppointmentNoteStatusCard = (
  props: AppointmentNoteStatusProps,
) => {
  const localTimezone = useAppState((_) => getTimezone(_.user.timezone));
  const {
    appointment,
    allowedNoteActions,
    signedLockedAction,
    showAiDraftLabel,
    onClick,
  } = props;
  const {
    noteDetails: { setCursor },
  } = useAppointmentsAndNotesAPI();

  const userId = useAppState((_) => _.user.userId!);
  const ownsAppointment = appointment.clinician?.user?.id === userId;
  const noteStatus =
    appointment.clinicalNote?.status ?? ClinicalNoteStatus.NOT_STARTED;
  const appointmentDate = moment.tz(appointment.start, localTimezone);
  const isOverdue = isAppointmentOverdue(
    ownsAppointment,
    appointment.clinicalNote?.status,
    appointment.appointmentStatus,
    appointmentDate,
  );
  const isSignedAndLocked =
    noteStatus === ClinicalAppointmentNoteStatus.SIGNED_AND_LOCKED;
  // Appointments created via Dr Chrono webhook should be redirected to dr chrono
  const clinicalNoteUrl = appointment.drchronoId
    ? `https://gingerio.drchrono.com/appointments/${appointment.drchronoId}/`
    : '';

  const noteAction = getNoteAction(
    noteStatus,
    allowedNoteActions,
    signedLockedAction,
    ownsAppointment,
    appointment.start,
  );

  const handleClick = async () => {
    // Appointment with noteType UNSPECIFIED, indicates that it was created via Dr Chrono webhook, therefore we redirect to dr chrono
    if (
      appointment.clinicalNote?.noteType ===
        ClinicalAppointmentNoteType.UNSPECIFIED &&
      noteAction === NoteAction.CREATE
    ) {
      window.open(clinicalNoteUrl, '_blank');
    } else {
      await onClick?.(appointment);
      setCursor(appointment);
    }
  };

  const actionLink = (
    appointmentStatus:
      | ClinicianAppointmentStatus
      | ClinicalAppointmentStatus
      | AppointmentStatus
      | null,
    action: NoteAction,
  ) => {
    if (
      isInCancellationStatus(appointmentStatus) ||
      [NoteAction.DOWNLOAD, NoteAction.REVIEW_DRAFT, NoteAction.NONE].includes(
        action,
      )
    ) {
      return null;
    }

    return (
      <Link
        onClick={handleClick}
        data-testid={`appt-note-status-action-link-${appointment.id}`}
        className={classNameCombiner([styles.capitalize, styles.link])}
      >
        {`${action} note`}
      </Link>
    );
  };

  const actionElement = actionLink(appointment.appointmentStatus, noteAction);

  let stateLabel = formatClinicalNoteStatus(
    appointment.appointmentStatus,
    noteStatus,
    true,
  );
  let statusIcon = noteStatusIcon(appointment.appointmentStatus, noteStatus);
  if (isOverdue) {
    stateLabel = 'Overdue';
    statusIcon = <OverdueIcon />;
  } else if (showAiDraftLabel && ownsAppointment && !isSignedAndLocked) {
    stateLabel = 'AI Note Ready';
    statusIcon = <AiDraftReady />;
  }

  return (
    <article className={styles.statusColumn}>
      <Typography
        data-testid="appt-note-status"
        component="div"
        className={classNameCombiner([
          styles.status,
          isOverdue ? styles.overdue : '',
        ])}
      >
        <span className={styles.icon}>
          {statusIcon && (
            <SvgIcon viewBox="0 0 32 32" fontSize="small">
              {statusIcon}
            </SvgIcon>
          )}
        </span>
        <span
          className={styles.iconLabel}
          data-testid="appt-note-status-formatted"
        >
          {stateLabel}
        </span>
      </Typography>
      <Typography
        component="div"
        className={actionElement ? styles.link : styles.emptyLink}
      >
        {actionElement}
      </Typography>
    </article>
  );
};
