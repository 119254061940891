import { TimeRangeInput } from 'app/notes-ui/forms/form-controls/TimeRangeInput';
import {
  getTimeError,
  getTimeValue,
  setTimeValue,
  TimeField,
} from 'app/notes-ui/forms/utils';
import React from 'react';

type Props = {
  disabled?: boolean;
  field: { startTime: TimeField; endTime: TimeField };
  label: string;
  testId: string;
};

export function TimeRangeField(props: Readonly<Props>) {
  const {
    field: { endTime, startTime },
    disabled,
    testId,
    label,
  } = props;
  return (
    <TimeRangeInput
      disabled={disabled}
      testId={testId}
      label={label}
      startTime={getTimeValue({
        hour: startTime.hour.value,
        minute: startTime.minute.value,
        second: startTime.second.value,
      })}
      endTime={getTimeValue({
        hour: endTime.hour.value,
        minute: endTime.minute.value,
        second: endTime.second.value,
      })}
      error={getTimeError(startTime) ?? getTimeError(endTime)}
      onChange={(data) => {
        setTimeValue(data.startTime, startTime);
        setTimeValue(data.endTime, endTime);
      }}
    />
  );
}
