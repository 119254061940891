import {
  GetTasksResponse,
  TaskSummary,
  TransformedGetTasksResponse,
} from '../types';

export const transformTaskResponseFn = () => {
  return (response: GetTasksResponse): TransformedGetTasksResponse => {
    const { taskSummaries, members } = response;
    const updatedTaskSummaries = taskSummaries.map((taskSummary) => {
      const member = members.find((x) => x.id === taskSummary.memberId);
      return {
        ...member,
        ...taskSummary,
      };
    });
    return {
      metadata: response.metadata,
      taskSummaries: updatedTaskSummaries as TaskSummary[],
    };
  };
};
