import { useForm } from '@ginger.io/react-use-form';
import { ClinicianChecklist } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/progress/ClinicianChecklist';
import {
  ClinicianChecklistSection,
  TherapyProgressSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyProgressSection';
import { CheckboxField } from 'app/notes-ui/forms/fields/checkboxField';
import { TextAreaField } from 'app/notes-ui/forms/fields/textAreaField';
import { YesOrNoField } from 'app/notes-ui/forms/fields/yesOrNoField';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import { SaveButton } from 'app/notes-ui/forms/form-controls/SaveButton';
import {
  consentText,
  gingerIntro,
  showConsentCheckbox,
} from 'app/notes-ui/shared/clinician-checklist/ClinicianChecklistForm';
import { AUTO_SAVE_DRAFT_DELAY } from 'app/notes-ui/utils';
import React from 'react';

import { fieldDefs } from './schema';

export type Props = {
  appointmentId: string;
  onSubmit: (checklist: ClinicianChecklistSection) => void;
  updateDraftNoteState: (data: ClinicianChecklistSection) => void;
  initialValue?: ClinicianChecklist; // used for read-only
  disabled?: boolean;
  savingNote?: boolean;
};

export function ClinicianChecklistForm(props: Props) {
  const { initialValue, appointmentId, disabled, savingNote } = props;

  const { fields, getValue, validate } = useForm<ClinicianChecklist>(
    fieldDefs,
    { ...initialValue, appointmentId } as ClinicianChecklist,
    {
      delay: AUTO_SAVE_DRAFT_DELAY,
      onStateChange: (data) =>
        props.updateDraftNoteState({
          data,
          name: TherapyProgressSectionName.CLINICIAN_CHECKLIST,
        }),
    },
  );

  const onSubmit = async () => {
    const isValid = await validate();
    if (isValid) {
      props.onSubmit({
        data: getValue(),
        name: TherapyProgressSectionName.CLINICIAN_CHECKLIST,
      });
    }
  };

  return (
    <div>
      <div className={styles.formGroup}>
        <YesOrNoField
          disabled={disabled}
          label="Chart entries since last session, including coach collaboration notes, were reviewed by clinician?*"
          field={fields.reviewedLastSessionEntries}
        />
      </div>

      <div className={styles.formGroup}>
        <TextAreaField
          testId="remarkableEntries"
          disabled={disabled}
          label="Entries were remarkable/notable due to*"
          tooltip="Describe change in tx plan, meds, goals, functioning, etc as noted by others on team"
          field={fields.remarkableEntries}
        />
      </div>

      <div className={styles.formGroup}>
        <YesOrNoField
          disabled={disabled}
          label="Clinician educated the member about Ginger coaching?*"
          field={fields.introducedCoachingToMember}
          tooltip={gingerIntro}
        />
      </div>
      <div className={styles.formGroup}>
        <YesOrNoField
          disabled={disabled}
          testId="releaseOfInformationRequested"
          label="Was release of information requested?*"
          field={fields.releaseOfInformationRequested}
        />
      </div>
      {showConsentCheckbox(disabled, initialValue) && (
        <div className={styles.formGroup}>
          <CheckboxField
            testId="memberGaveConsentForTelehealthAppointment"
            disabled={disabled}
            label={consentText}
            field={fields.memberGaveConsentForTelehealthAppointment}
          />
        </div>
      )}

      <SaveButton
        isLoading={savingNote}
        disabled={disabled}
        onSubmit={onSubmit}
      />
    </div>
  );
}
